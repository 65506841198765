import React, { useEffect, useState } from "react";
import { IconMaisFidelidade, IconPaperClip, IconSellers } from "../../assets";

import Button from "../Button";
import ModalBackground from "../ModalBackground";
import StatusModal from "./StatusModal";

import { Label, WrapperLinks, WrapperSellersModal } from "./styled";

import "../../img/pdf/Material_de_Vendas_+FidelidadeVendedor_B&S_1911.pdf";
import "../../img/pdf/REGULAMENTO_CAMPANHAS_FIDELIDADE_B&S.pdf";

const SellersModal = ({
  open,
  onClickResponse,
  onClickClose,
  onClickExit,
  loading
}) => {
  const [typePopup, setTypePopup] = useState("initial");

  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (!loading && status) {
      setTypePopup("status");
    }
  }, [loading]);

  return (
    <>
      {typePopup === "initial" ? (
        <ModalBackground open={open} onClickClose={onClickClose}>
          <WrapperSellersModal>
            <img src={IconSellers} alt="Ícone de vendedores" />
            <h1>
              Novidade no <img src={IconMaisFidelidade} alt="+Fidelidade" />
            </h1>

            <Label>
              Você conhece o +Fidelidade Vendedor? No programa você poderá
              acompanhar o desempenho dos seus vendedores associados. Saiba mais
              aqui:
            </Label>

            <WrapperLinks>
              <img src={IconPaperClip} alt="ícone anexo" />
              <a
                href="/REGULAMENTO_CAMPANHAS_FIDELIDADE_B&S.pdf"
                target="_blank"
              >
                Visualizar regulamento
              </a>
            </WrapperLinks>
            <WrapperLinks>
              <img src={IconPaperClip} alt="ícone anexo" />
              <a
                href="/Material_de_Vendas_+FidelidadeVendedor_B&S_1911.pdf"
                target="_blank"
              >
                Visualizar material explicativo
              </a>
            </WrapperLinks>
            <Label fontSize="1.6rem" fontWeight="600" margin="3rem 0">
              Você autoriza seu(s) vendedor(es) para participar da campanha?
            </Label>

            <WrapperLinks>
              <Button
                isLoading={loading && status === "error"}
                disabled={loading && status !== "error"}
                width="130px"
                onClick={() => {
                  onClickResponse(false);
                  setStatus("error");
                }}
                theme="border"
              >
                Não autorizo
              </Button>
              <Button
                isLoading={loading && status === "success"}
                disabled={loading && status !== "success"}
                width="130px"
                onClick={() => {
                  onClickResponse(true);
                  setStatus("success");
                }}
              >
                Sim, autorizo
              </Button>
            </WrapperLinks>
          </WrapperSellersModal>
        </ModalBackground>
      ) : (
        <StatusModal onClickClose={onClickExit} status={status} open={open} />
      )}
    </>
  );
};

export default SellersModal;
