import React from "react";

import ModalBackground from "../ModalBackground";
import Button from "../Button";

import { IconPlatinum, IconQuestion, LogoClubePlatinum } from "../../assets";
import { ContainerSteps, Wrapper, WrapperModal } from "./styled";

import "../../img/pdf/Material_de_Vendas_Clube_Platinum_B&S_1911.pdf";

const ClubePlatinumModal = ({ open, onClickStart }) => {
  return (
    <ModalBackground open={open}>
      <WrapperModal>
        <img src={LogoClubePlatinum} alt="Clube Platinum" className="logo" />
        <p>
          Bem-vindo! O +fidelidade com mais uma novidade para você:{" "}
          <b>O Clube Platinum!</b>
        </p>
        <p>
          Com ele você garante os prêmios e benefícios exclusivos.{" "}
          <b>Quer saber como participar?</b>
        </p>
        <Wrapper margin="2.5rem 0 0">
          <ContainerSteps>1</ContainerSteps>
          <p>
            Você precisa atingir o 1º mês na a classificação Platinum{" "}
            <img src={IconPlatinum} alt="Platinum" /> para estar a caminho do
            Clube Platinum.
          </p>
        </Wrapper>
        <Wrapper margin="2.5rem 0 0">
          <ContainerSteps>2</ContainerSteps>
          <p>
            Mantenha-se na classificação Platinum nos próximos meses para estar
            participando.
          </p>
        </Wrapper>
        <Wrapper margin="2.5rem 0 2.5rem">
          <ContainerSteps>3</ContainerSteps>
          <p>Parabéns! Basta trocar seus pontos por diversos prêmios.</p>
        </Wrapper>

        <p>
          Quanto mais vezes seguidas você permanecer no Platinum, mais prêmios
          você garante!
        </p>
        <b>Clique em Conheça o Clube para ficar por dentro dessa novidade.</b>
        <Wrapper margin="2.5rem 0 0" justifyContent="center">
          <Button className="button-anchor" theme="green">
            <a
              href="/Material_de_Vendas_Clube_Platinum_B&S_1911.pdf"
              target="_blank"
            >
              Conheça o Clube Platinum{" "}
              <img
                src={IconQuestion}
                alt="Icon interogação"
                style={{ marginLeft: "7px" }}
              />
            </a>
          </Button>
          <Button onClick={onClickStart}>Começar</Button>
        </Wrapper>
      </WrapperModal>
    </ModalBackground>
  );
};

export default ClubePlatinumModal;
