import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import SVG from "webmotors-svg";
import { connect } from "react-redux";
import { MainContext } from "../App/context";
import { decodedToken, verifyToken } from "../utils/utils";

class MenuLateral extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.urls = [
      { id: 1, path: "home", icon: "icon-home", name: "Home" },
      {
        id: 8,
        path: "painel-oportunidades",
        icon: "icon-phone",
        name: "Painel de oportunidades"
      },
      {
        id: 2,
        path: "beneficios?filter=posicaoAtual",
        icon: "icon-santander",
        name: "Benefícios"
      },
      { id: 3, path: "pontos", icon: "icon-points", name: "Pontos" },
      {
        id: 7,
        path: "simulador",
        icon: "icon-simulator",
        name: "Simulador"
      },
      { id: 4, path: "mensagens", icon: "icon-message", name: "Mensagem" },
      { id: 5, path: "historico", icon: "icon-historic", name: "Histórico" },
      { id: 6, path: "ajuda", icon: "icon-help", name: "Ajuda" }
    ];
  }

  componentDidMount() {
    this.setPathName();
  }

  setPathName() {
    setTimeout(() => {
      this.props.dispatch({
        type: "CHANGE_ROUTE",
        payload: {
          route:
            this.props.location.pathname === "/"
              ? "/home"
              : this.props.location.pathname
        }
      });
    });
  }

  render() {
    const { constractsTotal } = this.props;

    const { classificacoesAnteriores, campanhas, beneficiosResgate } =
      this.context.state;
    const EhLojista =
      decodedToken(verifyToken()) &&
      decodedToken(verifyToken()).EhLojista &&
      decodedToken(verifyToken()).EhLojista === "true";
    let news,
      notRead = 0;
    news =
      this.props.news.result.length != 0
        ? this.props.news.result.novidades
        : this.context.state.novidades;
    news &&
      news.map((novidade) => {
        if (!novidade.lida) notRead++;
      });

    return (
      <div className="wrapper__lateral-menu">
        <div className="menu-lateral__list">
          {this.urls &&
            this.urls !== [] &&
            this.urls.map((url, index) => {
              if (
                (url.id === 5 &&
                  classificacoesAnteriores != undefined &&
                  classificacoesAnteriores.length === 0 &&
                  campanhas != undefined &&
                  campanhas.filter(
                    (c) =>
                      c.resgatado && c.dtReferencia !== "0001-01-01T00:00:00"
                  ).length === 0 &&
                  beneficiosResgate != undefined &&
                  beneficiosResgate.filter((b) => b.resgatado).length === 0) ||
                (url.id === 4 && EhLojista === false)
              ) {
                return (
                  <Link
                    key={index}
                    to={"#"}
                    className={`menu-lateral__link ${
                      this.props.changeRoutesReducer.result.route ===
                      `/${url.path.split("?")[0]}`
                        ? "menu-lateral__link-active disable"
                        : "disable"
                    } ${url.id === 2 ? "beneficios" : ""}`}
                  >
                    <SVG
                      src={require(`../../src/assets/icons/${url.icon}.svg`)}
                    />
                    {url.name}
                    {url.id === 4 && notRead > 0 && (
                      <span className="news-page__badge_lateral">
                        <span className="news-page__badge">{notRead}</span>
                      </span>
                    )}
                    {url.id === 8 && (
                      <span className="oportunity-news">
                        <span className="oportunity-news-badge">
                          {constractsTotal}
                        </span>
                      </span>
                    )}
                  </Link>
                );
              }

              return (
                <Link
                  key={index}
                  to={url.path}
                  onClick={() => this.setPathName()}
                  className={`menu-lateral__link ${
                    this.props.changeRoutesReducer.result.route ===
                    `/${url.path.split("?")[0]}`
                      ? "menu-lateral__link-active"
                      : ""
                  }`}
                >
                  <SVG
                    src={require(`../../src/assets/icons/${url.icon}.svg`)}
                  />
                  {url.name}
                  {url.id === 4 && notRead > 0 && (
                    <span className="news-page__badge_lateral">
                      <span className="news-page__badge">{notRead}</span>
                    </span>
                  )}
                  {url.id === 8 && (
                    <span className="oportunity-news">
                      <span className="oportunity-news-badge">
                        {constractsTotal}
                      </span>
                    </span>
                  )}
                </Link>
              );
            })}
        </div>
      </div>
    );
  }
}

MenuLateral.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  changeRoutesReducer: PropTypes.object,
  dispatch: PropTypes.func,
  news: PropTypes.object
};

function mapStateToProps(state) {
  return {
    changeRoutesReducer: state.changeRoutesReducer,
    news: state.fetchNewsReducer,
    constractsTotal: state.oportunityPanel.totalContratosAtrasados
  };
}

MenuLateral.contextType = MainContext;

export default withRouter(hot(module)(connect(mapStateToProps)(MenuLateral)));
