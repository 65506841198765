import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import CategoryIcon from "./CategoryIcon";
import ScoreBar from "./ScoreBar";
import { Card } from "../components";
import { Link } from "react-router-dom";
import { verifyNextCategory } from "../utils/helper";
import { MainContext } from "../App/context";
import { connect } from "react-redux";
import Button from "./Button";

class CurrentCategory extends Component {
  changeRoute(route) {
    this.props.dispatch({
      type: "CHANGE_ROUTE",
      payload: { route }
    });
  }

  render() {
    return (
      <div className="current-category">
        {this.props.currentCategory && (
          <Card>
            <div className="current-category__icon ">
              {this.props.currentCategory.categoria && (
                <CategoryIcon
                  category={this.props.currentCategory.categoria}
                  text="CATEGORIA ATUAL"
                  lateral={true}
                  size="big"
                  active={true}
                  activePlatinum={this.props.activePlatinum}
                />
              )}
            </div>

            <ScoreBar
              currentPoints={
                this.props.platinum.status
                  ? this.props.currentCategory.pontuacaoMaxima
                  : this.props.currentCategory.pontuacao
              }
              maxPoints={this.props.currentCategory.pontuacaoMaxima}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              {this.props.currentCategory.categoria !== "Platinum" &&
              this.props.platinum.status === false ? (
                <div className="current-category__message">
                  Faltam{" "}
                  <span>
                    {this.props.currentCategory.pontuacaoMaxima -
                      this.props.currentCategory.pontuacao +
                      1}{" "}
                  </span>
                  pontos para subir pra categoria{" "}
                  <span>
                    {verifyNextCategory(this.props.currentCategory.pontuacao)}
                  </span>
                </div>
              ) : (
                <div className="current-category__message">
                  <span>Parabéns! Você atingiu a categoria máxima.</span>
                </div>
              )}
              {this.props.showLinks && (
                <div className="current-category__buttons">
                  <Link
                    to={"pontos"}
                    onClick={() => this.changeRoute("/pontos")}
                  >
                    <Button>Acesse seus pontos</Button>
                  </Link>
                </div>
              )}
            </div>
          </Card>
        )}
      </div>
    );
  }
}

CurrentCategory.propTypes = {
  currentCategory: PropTypes.object,
  dispatch: PropTypes.func,
  showLinks: PropTypes.bool,
  activePlatinum: PropTypes.bool
};

CurrentCategory.contextType = MainContext;

function mapStateToProps(state) {
  return {
    changeRoutesReducer: state.changeRoutesReducer,
    platinum: state.bePlatinum
  };
}

export default hot(module)(connect(mapStateToProps)(CurrentCategory));
