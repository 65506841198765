import React from "react";
import { ContainerBackground } from "./styled";

const Container = ({ margin, padding, children }) => {
  return (
    <ContainerBackground margin={margin} padding={padding}>
      {children}
    </ContainerBackground>
  );
};

export default Container;
