import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import SVG from "webmotors-svg";
import { connect } from "react-redux";
import { decodedToken, verifyToken } from "../utils/utils";
import { MainContext } from "../App/context";

class MenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.urls = [
      { id: 1, path: "home", icon: "icon-home", name: "Home" },
      {
        id: 8,
        path: "painel-oportunidades",
        icon: "icon-phone",
        name: "Painel de oportunidades"
      },
      {
        id: 2,
        path: "beneficios?filter=posicaoAtual",
        icon: "icon-santander",
        name: "Benefícios"
      },
      { id: 3, path: "pontos", icon: "icon-points", name: "Pontos" },
      {
        id: 7,
        path: "simulador",
        icon: "icon-simulator",
        name: "Simulador"
      },
      { id: 4, path: "mensagens", icon: "icon-message", name: "Mensagem" },
      { id: 5, path: "historico", icon: "icon-historic", name: "Histórico" },
      { id: 6, path: "ajuda", icon: "icon-help", name: "Ajuda" }
    ];
  }

  componentDidMount() {
    this.setPathName();
  }

  setPathName() {
    setTimeout(() => {
      this.props.dispatch({
        type: "CHANGE_ROUTE",
        payload: {
          route:
            this.props.location.pathname === "/"
              ? "/home"
              : this.props.location.pathname
        }
      });
    });
  }

  render() {
    let news,
      notRead = 0;
    news =
      this.props.news.result.length != 0
        ? this.props.news.result.novidades
        : this.context.state.novidades;
    news &&
      news.map((novidade) => {
        if (!novidade.lida) notRead++;
      });
    return (
      <div>
        <div className="menu-mobile">
          {this.state.allowedURLs &&
            this.state.allowedURLs !== [] &&
            this.state.allowedURLs.map((url, index) => {
              return (
                <Link
                  key={index}
                  to={url.path}
                  onClick={() => this.setPathName()}
                  className={`menu-mobile__link ${
                    this.props.changeRoutesReducer.result.route ===
                    `/${url.path}`
                      ? "menu-mobile__link-active"
                      : ""
                  }`}
                >
                  {url.id === 4 && notRead > 0 && (
                    <span className="news-page__badge_mobile">
                      <span className="news-page__badge">{notRead}</span>
                    </span>
                  )}
                  <SVG
                    src={require(`../../src/assets/icons/${url.icon}.svg`)}
                  />
                  {url.name}
                </Link>
              );
            })}
        </div>
      </div>
    );
  }
}

MenuMobile.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
  changeRoutesReducer: PropTypes.object,
  dispatch: PropTypes.func,
  news: PropTypes.object
};

function mapStateToProps(state) {
  return {
    changeRoutesReducer: state.changeRoutesReducer,
    news: state.fetchNewsReducer
  };
}

MenuMobile.contextType = MainContext;

export default withRouter(hot(module)(connect(mapStateToProps)(MenuMobile)));
