import React from "react";
import PropTypes from "prop-types";
import Tipfy from "../Tipfy";
import { Container, Bullet } from "./styles";

function RiskIndicator({ farol }) {
  let index;
  switch (farol) {
    case "R":
      index = "Alta";
      break;
    case "Y":
      index = "Média";
      break;
    case "G":
      index = "Baixa";
      break;
    default:
      index = null;
  }

  return index !== null ? (
    <Container role="risk-indicator" className="RiskIndicator">
      <Bullet role="bullet" farol={farol} /> {index}
    </Container>
  ) : null;
}

export default RiskIndicator;
RiskIndicator.propTypes = {
  farol: PropTypes.string,
};
