import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { routes, publicRoutes } from "./routes";
import NotFound from "../pages/publicPages/NotFound";
import Home from "../pages/privatePages/Home";
import CreateAccount from "../pages/publicPages/CreateAccount";

export function Routes(props) {
  const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
  };

  const PropsRoute = ({ component, ...rest }) => {
    return (
      <>
        <Route
          {...rest}
          render={(routeProps) => {
            return renderMergedProps(component, routeProps, rest);
          }}
        />
        <Route path="/" exact render={() => <Redirect to="/home" />} />
      </>
    );
  };

  return (
    <Switch>
      {routes.map((route, i) => (
        <PropsRoute key={i} {...route} {...props} />
      ))}
      <Route component={Home} />
      <Route path="/" render={() => <Redirect to="/home" />} />
    </Switch>
  );
}

export function PublicRoutes(props) {
  const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return React.createElement(component, finalProps);
  };

  const PropsRoute = ({ component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          return renderMergedProps(component, routeProps, rest);
        }}
      />
    );
  };

  return (
    <Switch>
      {publicRoutes.map((route, i) => (
        <PropsRoute key={i} {...route} {...props} />
      ))}
      <Route component={CreateAccount} />
    </Switch>
  );
}
