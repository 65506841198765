import React from "react";

import Category from "../Category";
import { Container, Label } from "./styled";

const CategoryInformation = ({ category, minPoints, maxPoints, active }) => {
  return (
    <Container>
      <Category category={category} width="60px" selected={active} />

      <Label fontWeight="bold" margin="5px 0 0">
        {category}
      </Label>
      <Label fontSize="1.1rem">
        {minPoints}-{maxPoints} pts
      </Label>
    </Container>
  );
};

export default CategoryInformation;
