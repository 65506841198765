import React, { Component } from "react";
import SVG from "webmotors-svg";
import GRMessages from "./GRMessages";
import News from "./News";
import { CategoryIcon, Card, ScoreBar } from "../../components";
import { verifyNextCategory } from "../../utils/helper";
import PropTypes from "prop-types";
import { MainContext } from "../../App/context";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs, Tab } from "../../components/Tabs";
import { decodedToken, verifyToken } from "../../utils/utils";
import PageTitle from "../../components/PageTitle";

class Messages extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { categoriaAtual } = this.context.state;
    const EhLojista =
      decodedToken(verifyToken()) &&
      decodedToken(verifyToken()).EhLojista &&
      decodedToken(verifyToken()).EhLojista === "true";
    return (
      <>
        {!this.props.hideMessages && (
          <PageTitle>
            <SVG
              className={`page__title--icon`}
              src="../../../img/svg/str-icon-message.svg"
            />
            Mensagem
          </PageTitle>
        )}
        <div className="wrapper__container">
          <div className="messages-page">
            <div
              className={`messages-page__body${
                !this.props.hideMessages ? "--home" : ""
              } container__first-child`}
            >
              <Card>
                <Tabs className="container__first-child" type="messages">
                  <Tab key={1} label={"Fale com o seu consultor"}>
                    <GRMessages />
                  </Tab>
                  <Tab
                    key={2}
                    className={!EhLojista ? "disable-message" : ""}
                    label={"Novidades"}
                  >
                    <News />
                  </Tab>
                </Tabs>
              </Card>
            </div>
            {!this.props.hideMessages && (
              <div className="container__second-child">
                <div className="container__second-child">
                  <div
                    className={`${
                      this.props.platinum.status === true
                        ? "rescue__current-category rescue__current-category--platinum"
                        : "rescue__current-category"
                    }`}
                  >
                    {categoriaAtual && (
                      <Card>
                        <CategoryIcon
                          category={categoriaAtual.categoria}
                          text="CATEGORIA ATUAL"
                          lateral={true}
                          size="big"
                          active={true}
                        />
                        <ScoreBar
                          currentPoints={categoriaAtual.pontuacao}
                          maxPoints={categoriaAtual.pontuacaoMaxima}
                        />
                        {categoriaAtual.categoria !== "Platinum" ? (
                          <div className="current-category__message">
                            Faltam{" "}
                            <span>
                              {categoriaAtual.pontuacaoMaxima -
                                categoriaAtual.pontuacao +
                                1}{" "}
                            </span>
                            pontos para subir pra categoria{" "}
                            <span>
                              {verifyNextCategory(categoriaAtual.pontuacao)}
                            </span>
                          </div>
                        ) : (
                          <div className="current-category__message">
                            <span>
                              Parabéns! Você atingiu a categoria máxima.
                            </span>
                          </div>
                        )}
                      </Card>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

Messages.propTypes = {
  children: PropTypes.array,
  hideMessages: PropTypes.bool,
  colorTitle: PropTypes.string
};

Messages.contextType = MainContext;

const mapStateToProps = (state) => {
  return {
    platinum: state.bePlatinum
  };
};

export default hot(module)(connect(mapStateToProps)(Messages));
