import styled from "styled-components";

export const ModalBackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.85);
  z-index: 4;
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #fff;

  padding: ${({ padding }) => (padding ? padding : "3.7rem 6.7rem")};
  border-radius: 8px;

  position: relative;
`;

export const ButtonClose = styled.button`
  position: absolute;

  top: 15px;
  right: 17px;

  transition: 0.2s;
  outline: none;
  border-style: none;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    filter: brightness(0.2);
  }
`;
