import React, { Component } from 'react'
import Card from '../components/Card';
import SVG from 'webmotors-svg';
import PropTypes from 'prop-types';
import { MainContext } from '../App/context';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { hot } from 'react-hot-loader';
import moment from 'moment'
import { fetchRedeemBenefitsAction } from '../actions/fetchRedeemBenefitsAction'
import { fetchTokenAction } from '../actions/fetchTokenAction';
import { decodedToken, verifyToken } from '../utils/utils';
import Slider from 'infinite-react-carousel';

class Campaign extends Component {

  componentDidMount(){
    const { campanhas } = this.context.state;
    const date = moment().format('YYYY-MM-DD')
    const filtrarSomenteAlertas = campanhas !== undefined && campanhas.filter(x => x.tipoExibicao === 3 && moment(date).isBetween(moment(x.dtInicioVigencia).format('YYYY-MM-DD'), moment(x.dtFimVigencia).format('YYYY-MM-DD'), null, '[)')) || []
    const popUpBonusShow = localStorage.getItem('popUpBonusShow');

   if(popUpBonusShow === "true" && filtrarSomenteAlertas && filtrarSomenteAlertas.length > 0){
      localStorage.setItem('popUpBonusShow', false);
      this.props.dispatch({
        type: 'ALERT_HASH',
        payload: { message: 'Você se cadastrou em setembro e <b>ganhou</b> <span>50 pontos</span> com o benefício <br/>boas vindas',  showAlert: true, type: 'gift', title: 'Parabêns' }
      })
    }
  }

  getAllData() {
    this.props.dispatch(fetchTokenAction())
    .then((e) => {
      const objectEntries = Object.entries(e.payload);
      this.context.state.periodoResgateAtivo = e.payload.periodoResgateAtivo
      objectEntries.map(obj => {
          this.context.state[obj[0]] = obj[1];
      })
    });
  }

    renderImage(campanha){
      const { width } = this.context.state;
        return (
          width < 700 ?
          (<img src={campanha.linkImagemMobile} />)
          : (<img src={campanha.linkImagemDesktop} />)
      )
    }

  updateRescue(id, apuracao) {
    if(id && apuracao) {
      const result = decodedToken(verifyToken());

      const rescue = {
        idEmpresa: result.IdEmpresa,
        beneficios: [],
        campanhas: [{
          idCampanha: id,
          idApuracao: apuracao
        }]
      }

      this.props.dispatch(fetchRedeemBenefitsAction(rescue))
        .then(e => {
          if (!e.type.includes('FAILURE')) {
            this.getAllData();
          } else {
            this.props.dispatch({
              type: 'ALERT_HASH',
              payload: { message: 'Erro ao resgatar! Tente novamente ou consulte seu GR.',  showAlert: true, type: 'error', title: 'DESCULPE' }
            })
          }

          this.props.dispatch(fetchTokenAction());
      })
      .catch((error) => console.error("TCL: Benefits -> handlerRedeemBenefits -> error", error))
    }
  }


  renderBanner(campanha) {
    const linkVerified = campanha.linkVideo && campanha.linkVideo.length === 1 ? campanha.linkVideo.replace('0','') : campanha.linkVideo;
    const newLink = campanha.link || linkVerified;
    return (
      <div className='campaign__item'>
        <div className='campaign__banner'>
          {newLink ?
            (<a href={newLink} onClick={() => this.updateRescue(campanha.idCampanha, campanha.idApuracao)} target="_blank" rel="noopener noreferrer">
              {this.renderImage(campanha)}
            </a>)
          : this.renderImage(campanha)}
        </div>
      </div>
    )
  }

    renderCampainByType(campanha){
      switch (campanha.tipoExibicao) {
        case 1:
          if(campanha.linkImagemMobile && campanha.linkImagemDesktop)
            return this.renderBanner(campanha);
        break;
        case 2:
          return this.renderCards(campanha);
        default:
          break;
      }
    }

  renderCards(campanha) {
    const { width } = this.context.state;
    return (
      <div className='campaign__item'>
        <Card>
          <div className='campaign__item-content'>
            {width > 440 && <SVG src={`../../../img/svg/str-icon-info-blue.svg`} />}
            <div className="campaign__item-content-message">
                <div className='slider__title'>{campanha.nome}</div>
                <div className='slider__description'>{campanha.descricao}</div>
            </div>
          </div>
        </Card>
      </div>
    )
  }

  renderCampaigns(campanhas) {
    return (
      campanhas.map((campanha, index) => ( <div key={index}> { this.renderCampainByType(campanha) } </div>) )
    )
  }

  render() {
    const { campanhas } = this.context.state;
    const date = moment().format()
    const filtrarTiposValidos = campanhas && campanhas.filter(x => x.tipoExibicao !== 3 && date >= x.dtInicioVigencia && date < x.dtFimVigencia ) || [];

    const settings =  {
      adaptiveHeight: true,
      autoplay: true,
      dots: true,
      arrows: false,
      pauseOnHover: true,
      // infiniteScroll: false,
      wheel: false,
      className: 'carousel-campaing'
    };

    return (
      filtrarTiposValidos && Array.isArray(filtrarTiposValidos) && filtrarTiposValidos.length > 0 && (
        <>
          <div className='card__title'>
            <SVG className='card__title__icon' src='../../../img/svg/str-icon-campaign.svg' />
            CAMPANHA
          </div>
          <Slider { ...settings }>
            {this.renderCampaigns(filtrarTiposValidos)}
          </Slider>
        </>
      )
    );
  }
}

Campaign.propTypes = {
    dispatch: PropTypes.func,
}

Campaign.contextType = MainContext;
export default hot(module)(connect(bindActionCreators)(Campaign));

