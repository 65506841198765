import React, { Component } from "react";
import InputForm from "../components/InputForm";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
// import { fetchCreateUserValidationAction } from "../actions/fetchCreateUserValidationUser";
import { fetchCreateAccountAction } from "../actions/fetchCreateAccountAction";
import { connect } from "react-redux";
import SVG from "webmotors-svg";

class CreateAccountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisabled: true,
      disableSubmitButton: true,
      successRegister: false,
      emailMasked: "",
      email: "",
      emailInvalid: false,
      messageError: "",
      requestError: false
    };
    this.createAccount = {};
    this.validateEmail("");
  }

  componentDidMount() {
    const input = document.getElementById("input__email");
    if (input) input.focus();
  }

  previousSlide(previous) {
    this.previous = previous;
  }

  getValueEmail() {
    let input = document.getElementById("input__email").value;
    this.setState({
      email: input || ""
    });
    this.validateEmail(input);
  }

  handlerCreateAccount(e) {
    e.preventDefault();
    if (this.state.email.length > 0 || this.state.emailInvalid) {
      this.getValueEmail();
      this.props
        .dispatch(fetchCreateAccountAction(this.state))
        .then((resp) => {
          if (!resp.type.includes("FAILURE")) {
            this.setState({
              successRegister: true,
              emailMasked: resp.payload.objeto.email,
              requestError: false
            });
          } else {
            this.setState({
              successRegister: false,
              requestError: true,
              messageError: resp.payload.objeto || "E-mail incorreto"
            });
          }
        })
        .catch(() => {
          this.setState({
            successRegister: false
          });
        });
    }
  }

  validateEmail(email) {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    email && email.trim() && re.test(email)
      ? this.setState({ emailInvalid: false, messageError: "" })
      : this.setState({
          emailInvalid: true,
          messageError: "E-mail incorreto"
        });
  }

  showAlert(message, type = "error", title = "DESCULPE", url) {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { message: message, showAlert: true, type, title, url }
    });
  }

  render() {
    return (
      <div className="create-account__slider">
        {!this.state.successRegister ? (
          <>
            <p className="create-account__slider__title">CADASTRE-SE</p>
            <form onSubmit={(e) => this.handlerCreateAccount(e)}>
              <div className="create-account__form">
                <div className="wrap-input">
                  <InputForm
                    class="create-account__input"
                    id="input__email"
                    type="email"
                    placeholder="Insira aqui seu e-mail"
                    change={() => {
                      this.getValueEmail();
                    }}
                  />
                  {this.state.email.length &&
                  (this.state.emailInvalid || this.state.requestError) ? (
                    <small className="error">{this.state.messageError}</small>
                  ) : null}
                </div>

                <button
                  type="submit"
                  disabled={!this.state.email.length || this.state.emailInvalid}
                  className={`btn btn__rounded ${
                    !this.state.email.length || this.state.emailInvalid
                      ? "btn__default disabled"
                      : "btn__red"
                  }`}
                >
                  {" "}
                  <div className="btn__text__next">AVANÇAR</div>
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="create-account__form">
            <SVG
              className="create-account__icon-check-bordered"
              src={require(`../../src/img/svg/check-circle-bordered.svg`)}
            />

            <p className="create-account__title-success-register">
              Usuário cadastrado com sucesso!
            </p>

            <p className="create-account__description-success-register">
              Enviamos um e-mail para {this.state.emailMasked} para finalização
              do cadastro.
            </p>

            <button
              type="button"
              className={`btn btn__green btn__rounded`}
              onClick={() => {
                this.setState({
                  successRegister: false,
                  email: ""
                });
                this.validateEmail("");
              }}
            >
              {" "}
              <div className="btn__text__next">VOLTAR</div>
            </button>
          </div>
        )}
      </div>
    );
  }
}

CreateAccountForm.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    sendMessageAlertAction: state.sendMessageAlert
  };
}

export default hot(module)(connect(mapStateToProps)(CreateAccountForm));
