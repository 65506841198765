import React, { useState, useEffect } from "react";
import Container from "../../../components/Container";
import Table from "../../../components/Table";
import OportunityPainelDetails from "../../../components/OportunityPainelDetails";
import { IconDetails } from "../../../assets";

import { getListOportunity } from "../../../requests/oportunityPanel";
import { decodedToken, verifyToken } from "../../../utils/utils";
import PageTitle from "../../../components/PageTitle";

const EnumPriority = {
  1: "Alta",
  2: "Média",
  3: "Baixa"
};

const OportunityPainel = () => {
  const columns = [
    {
      title: "Cód. Aprovação",
      key: "codProposta"
    },
    {
      title: "Núm. do contrato",
      key: "numeroContrato"
    },

    {
      title: "CPF",
      key: "cpfCliente"
    },
    {
      title: "Nome cliente",
      key: "nomeCliente",
      type: "name"
    },
    {
      title: "Valor contrato",
      key: "valorContrato",
      type: "value"
    },
    {
      title: "Qtd. Parcelas",
      key: "qtdParcelas"
    },
    {
      title: "Dias em atraso",
      key: "qtdDiasAtraso",
      className: (item) => EnumPriority[item.idPrioridade]
    },
    {
      title: "Telefone",
      key: "telefoneCliente",
      type: "whatsapp",
      profile: "Lojista"
    }
  ];

  const options = [
    {
      title: "Detalhes",
      content: () => <img src={IconDetails} alt="Detalhes" />,
      props: {
        onClick: (item) => {
          setId(item.idCobranca);
          setPage("details");
        }
      }
    }
  ];

  const [page, setPage] = useState("table");
  const [data, setData] = useState([]);
  const [pagesTotal, setPagesTotal] = useState(null);
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const userLojista = decodedToken(verifyToken()).EhLojista;

  useEffect(() => {
    getListOportunity(currentPage, 20).then((response) => {
      if (response && response.data && response.data.lista) {
        const { lista, totalDeRegistros } = response.data;

        const total = Math.round(totalDeRegistros / 20);

        setData(lista);
        setPagesTotal(total);
      }
    });
  }, [currentPage]);

  return (
    <div>
      <PageTitle>Painel de oportunidades</PageTitle>
      {page === "table" ? (
        <Container margin="19px 0 0">
          <Table
            columns={
              userLojista === "true"
                ? columns
                : columns.filter((item) => item.title !== "Telefone")
            }
            data={data}
            options={options}
            pagesTotal={pagesTotal}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Container>
      ) : (
        <OportunityPainelDetails id={id} onClickBack={() => setPage("table")} />
      )}
    </div>
  );
};

export default OportunityPainel;
