import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SVG from "webmotors-svg";
import Tipfy from "../components/Tipfy";
import { MainContext } from "../App/context";
import Tooltip from "./Tooltip";

const imgCheck = require("../../src/img/svg/str-icon-ok.svg");
const imgLock = require("../../src/img/svg/str-icon-lock.svg");

class ScoreBar extends Component {
  constructor(props) {
    super(props);
  }

  renderBar(points) {
    const { metaDesafio } = this.context.state.categoriaAtual;
    return (
      <div>
        {points &&
          points.map((item) => {
            let percentual =
              item.PontuacaoAtual === 0
                ? 6
                : (item.PontuacaoAtual / item.PontuacaoMaxima) * 100;
            let showPerc = item.Criterio && item.Criterio.IdCriterio !== 3;

            if (this.props.platinum && this.props.platinum.status) {
              percentual = 100;
            }

            let porcentagem = 0;

            if (metaDesafio && item.Realizado) {
              porcentagem =
                (item.Realizado * 100) / parseInt(metaDesafio.replace(".", ""));
            }

            if (percentual > 100) percentual = 100;
            return (
              <div key={Math.random() % 1000} className="criteria-item">
                <div
                  className="criteria-item__title"
                  style={{
                    marginTop:
                      item.Criterio && item.Criterio.IdCriterio === 1 ? -7 : 0
                  }}
                >
                  <div>
                    <span>
                      {item.Criterio && item.Criterio.Nome}
                      <span>
                        {item.Criterio.Nome === item.Criterio.Nome &&
                        item.Criterio.IdCriterio === 1 ? (
                          <strong>
                            -{" "}
                            {`R$ ${this.props.metaDesafio || metaDesafio || 0}`}
                          </strong>
                        ) : (
                          ""
                        )}
                      </span>
                    </span>
                    {item.Criterio && item.Criterio.ToolTip && (
                      <Tooltip
                        size="13"
                        marginLeft="5px"
                        data={item.Criterio.ToolTip}
                      />
                    )}
                  </div>
                  {item.Criterio &&
                    item.Criterio.IdCriterio === 1 &&
                    item.Realizado && (
                      <h4 className="scorebar__subheader_criteria">
                        Realizado
                        <div className="scorebar__realizado">
                          -{" "}
                          {`R$ ${
                            (item.Realizado &&
                              item.Realizado.toLocaleString("pt-BR")) ||
                            0
                          }`}
                        </div>
                      </h4>
                    )}
                </div>

                <div>
                  <div
                    className={`scorebar__maxpercent ${item ? "points" : ""} ${
                      item.Criterio && item.Criterio.IdCriterio === 1
                        ? "dashed"
                        : ""
                    }`}
                    key={item.Criterio && item.Criterio.IdCriterio}
                  >
                    <div
                      className="scorebar__percent"
                      style={{
                        width: `${parseInt(percentual <= 6 ? 0 : percentual)}%`
                      }}
                    >
                      {item.Criterio && item.Criterio.IdCriterio === 1 && (
                        <div
                          aria-label={`${parseInt(porcentagem || 0)}%`}
                          data-balloon-pos="up"
                          style={{
                            left: `calc(${parseInt(percentual)}% - 20px)`,
                            width: 20,
                            height: 20,
                            background: "transparent",
                            position: "absolute",
                            borderRadius: "50%",
                            zIndex: 2
                          }}
                        />
                      )}
                      <span
                        className="scorebar__percent--dot"
                        style={{ left: `${parseInt(percentual)}%` }}
                      >
                        <span className="scorebar__percent--dot--icon">
                          <SVG
                            src={require("../../src/img/svg/str-icon-ok.svg")}
                          />
                        </span>
                        {item.Criterio && item.Criterio.IdCriterio === 1 && (
                          <span
                            className="scorebar__percent--dot--valor"
                            style={{ left: `${parseInt(percentual)}%` }}
                          >
                            {percentual &&
                              `${percentual} ${showPerc ? "%" : ""}`}
                          </span>
                        )}
                      </span>
                      {item.Criterio && item.Criterio.TipoExibicao < 4 ? (
                        this.renderTypeExibition(
                          item,
                          item.PontuacaoMaxima,
                          0,
                          showPerc
                        )
                      ) : (
                        <span
                          className="scorebar__goal--actual-percentage"
                          style={{ left: `${parseInt(percentual)}%` }}
                        >
                          {parseInt(item.PontuacaoAtual).toFixed(0)} %
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="category-rescue__indicators">
                  <strong className="category-rescue__indicators--current">
                    {item.PontuacaoAtual}
                  </strong>
                  <span className="category-rescue__indicators--max">
                    / {item.PontuacaoMaxima}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  renderTypeExibition(points, pontuacaoMaxima, offset, showPerc) {
    return (
      <div className="scorebar__goal">
        {points.Metas.map((goal, index) => {
          let perc =
            goal.Ponto === 0
              ? 6
              : parseInt((goal.Ponto / pontuacaoMaxima) * 100).toFixed(0);

          if (perc > 100) perc = 100;
          const img =
            points.Criterio &&
            points.Criterio.IdCriterio === 1 &&
            goal.Ponto >= points.PontuacaoAtual
              ? imgLock
              : imgCheck;
          return (
            <div key={index} className="scorebar__goal--dots">
              {goal.Ponto <= points.pontuacaoMaxima ? (
                <span>
                  <span
                    className="scorebar__percent--dot--lock"
                    style={{ left: `${perc}%` }}
                  >
                    <span className="scorebar__percent--dot--icon">
                      <SVG src={require("../../src/img/svg/str-icon-ok.svg")} />
                    </span>
                  </span>
                  <span
                    className="scorebar__goal--points"
                    style={{ left: `${perc}%` }}
                  >
                    {" "}
                    <b> {goal.Ponto}</b>
                  </span>
                  <span
                    className="scorebar__goal--percent"
                    style={{ left: `${perc}%` }}
                  >
                    <b>
                      {goal.Valor}
                      {showPerc ? "%" : ""}
                    </b>
                  </span>
                </span>
              ) : (
                <span>
                  <span
                    className="scorebar__percent--dot--lock"
                    style={{ left: `${perc}%` }}
                  >
                    <span className="scorebar__percent--dot--lock--icon">
                      <SVG src={img} />
                    </span>
                  </span>
                  <span
                    className="scorebar__goal--points"
                    style={{ left: `${perc}%` }}
                  >
                    {" "}
                    {goal.Ponto}
                  </span>
                  <span
                    className="scorebar__goal--percent"
                    style={{ left: `${perc}%` }}
                  >
                    {goal.Valor}
                    {showPerc ? "%" : ""}
                  </span>
                </span>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let percent = (this.props.currentPoints * 100) / this.props.maxPoints;
    const { points } = this.props;
    let currentPoints = this.props.currentPoints;
    let maxPoints = this.props.maxPoints;
    if (points) {
      percent = (points.pontuacaoAtual * 100) / points.pontuacaoMaxima;
      currentPoints = points.pontuacaoAtual;
      maxPoints = points.pontuacaoMaxima;
    }

    return (
      <>
        {points ? (
          this.renderBar(points)
        ) : (
          <>
            <div className={points ? "scorebar percent" : "scorebar"}>
              <div className="scorebar__maxpercent">
                <div
                  className="scorebar__percent"
                  style={{ width: `${percent ? percent : 0}%` }}
                >
                  <div className="scorebar__percent--text">
                    {currentPoints}
                    <div className="scorebar__percent--text__normal">{`pontos`}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nameDados">
              <span className="point">{currentPoints}</span>
              <span className="max">/{maxPoints}</span>
            </div>
          </>
        )}
      </>
    );
  }
}

ScoreBar.propTypes = {
  currentPoints: PropTypes.number,
  maxPoints: PropTypes.number,
  name: PropTypes.string,
  points: PropTypes.object
};

ScoreBar.contextType = MainContext;

const mapStateToProps = (state) => ({
  platinum: state.bePlatinum
});

export default connect(mapStateToProps)(ScoreBar);
