import api from ".";

export const getContractsOportunity = async () => {
  const response = await api.get(`/PainelCobranca`);

  return response;
};

export const getListOportunity = async (page, quantity) => {
  const response = await api.get(`/PainelCobranca/${page}/${quantity}`);

  return response;
};

export const getDetailsOportunity = async (id) => {
  const response = await api.get(`/PainelCobranca/${id}`);

  return response;
};
