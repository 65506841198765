import api from ".";

export const setPopupsVisualized = async (idPopup, Aceito) => {
  const data = {
    idPopup,
    Aceito
  };

  const response = await api.post(`/Popups/MarcarLeituraPopup`, data);

  return response;
};

export const getPopups = async () => {
  const response = await api.get(`/Popups`);

  return response;
};
