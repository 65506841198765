import axios from 'axios';
import { config } from '../config/config';
import { authHeader } from '../helpers/authHeader';
import { GET_INFO_COMPLEMENT, 
         GET_INFO_COMPLEMENT_FAILURE, 
         SEND_COMPLEMENT_INFO, 
         SEND_COMPLEMENT_INFO_FAILURE,
         UPDATE_COMPLEMENT_INFO } from '../constants/actionTypes';

export const fetchGetComplementAction = () => {
    return (dispatch) => (
        axios.get(`${config.urlApi}/empresa`, { headers: authHeader() })
            .then(res => (
                dispatch({
                    type: GET_INFO_COMPLEMENT,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: GET_INFO_COMPLEMENT_FAILURE,
                    payload: err
                })
            ))
    )
}

export const fetchUpdateComplementAction = (complement) => {
    return (dispatch) => (
        dispatch({
            type: UPDATE_COMPLEMENT_INFO,
            payload: complement
        }) 
    ) 
}

export const fetchSendComplementAction = (dados) => {

    dados.faturamento = dados.faturamento.replace(/\D/g,"");
    return (dispatch) => (
        axios.post(`${config.urlApi}/empresa`, dados,  { headers: authHeader() })
            .then(res => (
                dispatch({
                    type: SEND_COMPLEMENT_INFO,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: SEND_COMPLEMENT_INFO_FAILURE,
                    payload: err
                })
            ))
    )
}