import axios from "axios";
import Dexie from "dexie";
import { config } from "../config/config";
import { authHeader } from "../helpers/authHeader";

let jwt = require("jsonwebtoken");

const db = new Dexie("bensServicos");
export const INITIAL_STATE = {
  fetched: false,
  result: [],
  error: null,
  status: false
};

export function checkAjaxCallPending(loader) {
  let numberOfAjaxCAllPending = 0;
  axios.interceptors.request.use(
    (config) => {
      loader.setState({
        loaded: true
      });
      return config;
    },
    (error) => {
      loader.setState({
        loaded: true
      });
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      if (numberOfAjaxCAllPending == 0) {
        loader.setState({
          loaded: false
        });
      }
      return response;
    },
    (error) => {
      loader.setState({
        loaded: false
      });
      return Promise.reject(error);
    }
  );
}

export function setupInterceptors(store, history) {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        store.dispatch();
      }

      if (error.response.status === 404) {
        history.push("/not-found");
      }

      return Promise.reject(error);
    }
  );
}

export function verifyToken() {
  const userToken = localStorage.getItem("UserToken");
  return userToken ? userToken : false;
}

export function verifyAcesso() {
  const status = localStorage.getItem("AcessoToken");

  return status && status !== "undefined" ? JSON.parse(status) : false;
}

export function verifyDataBase(name, callback) {
  let dbExists = true;
  let request = window.indexedDB.open(name);
  request.onupgradeneeded = (e) => {
    if (request.result.version === 1) {
      dbExists = false;
      window.indexedDB.deleteDatabase(name);
      if (callback) {
        callback(dbExists);
      }
    }
  };
  request.onsuccess = (e) => {
    if (dbExists) {
      if (callback) {
        callback(dbExists);
      }
    }
  };
}

export function setToken(token) {
  return localStorage.setItem("UserToken", token);
}

export function setTokenPassword(token) {
  return localStorage.setItem("UserTokenPassword", token);
}

export function setUpdateTokenPass() {
  const token = localStorage.getItem("UserTokenPassword");
  return localStorage.setItem("UserToken", token);
}

export function setAcesso(status) {
  return localStorage.setItem("AcessoToken", status);
}

export function removeToken() {
  return localStorage.clear();
}

export function clearDb() {
  return db.delete();
}

export function decodedToken(token) {
  return jwt.decode(token);
}

export const INITIAL_STATE_NEWS = { novidades: [] };

export const AdobeLaunch = (id, type, name, value) => {
  var sendEvent = {};
  sendEvent.ID = id; //código do evento disparado, necessário nos encaminharem a listagem com todos os eventos que vão disparar e com o id do evento respectivo
  sendEvent.parameters = []; //caso o evento tenha parâmetros os mesmos devem ser colocados neste array, os itens do array devem ser objetos, deve também estar contido dentro do mapeamento de eventos quais as informações são passadas nos parâmetros
  var param = {};
  param.type = type || "MaisFidelidade"; //tipo do evento (ex.: ProductInfo / FormValues / etc)
  param.value = value || ""; //[OPCIONAL]propriedades adicionais ao type devem ser inclusas conforme tipo do evento
  param.name = name || ""; //[OPCIONAL]propriedades adicionais ao type devem ser inclusas conforme tipo do evento

  sendEvent.parameters.push(param);

  /* Polyfill CustomEvent IE */
  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent("CustomEvent");
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }
  CustomEvent.prototype = window.Event.prototype;
  window.CustomEvent = CustomEvent;

  document.dispatchEvent(
    new CustomEvent("maisFidelidadeLogin", { detail: sendEvent })
  );
};
