import React, { Component } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import * as validation from "../utils/regex";
import SVG from "webmotors-svg";

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlert: "",
      alertMessage: "",
      inputMask: "",
      maxLength: 100,
      inputType: this.props.type === "CNPJ" ? "text" : this.props.type
    };
  }

  validateInput() {
    let inputEqual;
    const input = document.getElementById(this.props.id).value;
    if (this.props.equal) {
      inputEqual = document.getElementById(this.props.equal).value;
    }
    if (input) {
      switch (this.props.type) {
        case "login":
          if (isNaN(input)) {
            this.setState({ maxLength: 100 });
          } else {
            this.setState({ maxLength: 14 });
          }
          break;
        case "email":
          this.setState({ inputMask: input, maxLength: 80 });
          validation.validateEmail(input)
            ? this.setCustomState(
                "__success",
                "Muito bem! Esse e-mail é válido"
              )
            : this.setCustomState("__error", "Muito bem! Esse e-mail é válido");
          break;
        case "CNPJ":
          if (input.match(/^[0-9]||.||-||\/\*$/)) {
            let validateInput = input
              .replace(/[^\d.-]/g, "")
              .replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"
              );
            this.setState({ inputMask: validateInput, maxLength: 18 });
            if (input.length >= 14) {
              this.setCustomState("__success", "Cnpj válido!");
            } else {
              this.setCustomState(
                "__error",
                "CNPJ não cadastrado. Fale com seu GR."
              );
            }
          } else {
            this.setState({ inputMask: input });
          }
          break;
        case "password":
          this.setState({ inputMask: input });
          validation.validatePassword(input)
            ? this.setCustomState("__success", "Muito bem! Senha válida!")
            : this.setCustomState(
                "__error",
                "Por favor, a senha deve ter mais de 8 dígitos!"
              );
          break;
        case "text":
          this.setState({ inputMask: input });
          this.setCustomState("__success", "Muito bem! Senha válida!");
          break;
        default:
          this.setState({ inputMask: input });
          break;
      }
    } else {
      this.setState({ inputMask: input });
    }
    if (inputEqual) {
      validation.validateEqualValues(input, inputEqual)
        ? this.setCustomState(
            "__success",
            "Muito bem! Senha confirmada com sucesso!!"
          )
        : this.setCustomState(
            "__error",
            "As senhas digitadas devem ser iguais!"
          );
    }
    setTimeout(() => {
      if (this.props.change) this.props.change(this.state.showAlert);
    });
    if (this.props.verifyDependency) {
      this.props.verifyDependency();
    }
  }

  setCustomState(showAlert, alertMessage = "") {
    this.setState({
      showAlert: showAlert,
      alertMessage: alertMessage
    });
  }

  verifyDependency(e) {
    this.validateInput();
    if (this.props.equal) {
      const isEqual =
        document.getElementById(this.props.id).value ===
        document.getElementById(this.props.equal).value;
      this.props.isEqual(isEqual);
    }
  }

  changeInputType() {
    this.state.inputType === "password"
      ? this.setState({ inputType: "text" })
      : this.setState({ inputType: "password" });
  }

  render() {
    return (
      <div className="input">
        <label
          className={`form-control ${this.props.class ? this.props.class : ""}`}
        >
          <label className="input__name">{this.props.label}</label>
          <input
            disabled={this.props.disabled}
            className={` ${this.props.disabled ? "disabled" : ""}`}
            id={this.props.id}
            type={this.state.inputType}
            placeholder={this.props.placeholder}
            value={this.state.inputMask || this.props.value}
            maxLength={this.state.maxLength}
            onChange={(e) => this.verifyDependency(e)}
          />
          <span className={`border ${this.state.showAlert}`} />
          {this.props.type === "password" && (
            <span
              onClick={() => this.changeInputType()}
              className={`input__icon input__icon__eye ${this.state.inputType}`}
            >
              <SVG
                src={require("../../src/img/svg/str-colored-icon-eye.svg")}
              />
            </span>
          )}
        </label>
        {/* <div className={`input__message${this.state.showAlert}`} id={`${this.props.id}${this.state.showAlert}`}>{this.state.alertMessage}</div> */}
      </div>
    );
  }
}

InputForm.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  class: PropTypes.string,
  equal: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  verifyDependency: PropTypes.func,
  isEqual: PropTypes.func,
  change: PropTypes.func
};

export default hot(module)(InputForm);
