import styled from "styled-components";

export const Container = styled.div`
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: ${({ size }) => size || 16}px;
  min-height: ${({ size }) => size || 16}px;
  max-width: ${({ size }) => size || 16}px;
  max-height: ${({ size }) => size || 16}px;
  width: ${({ size }) => size || 16}px;
  height: ${({ size }) => size || 16}px;
  border-radius: 100%;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  background-color: ${({ backgroundColor }) => backgroundColor || "#43bccd"};
  cursor: help !important;
  text-align: center;

  &::before {
    display: none;
  }

  [data-tooltip] {
    position: relative;
    z-index: 2;
    cursor: pointer;
  }

  /* Hide the tooltip content by default */
  [data-tooltip]:before,
  [data-tooltip]:after {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  /* Position tooltip above the element */
  [data-tooltip]:before {
    font-family: Poppins, sans-serif;
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-bottom: 5px;
    margin-left: -80px;
    width: 200px;
    padding: 7px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    background-color: #2e2d37;
    color: #fff;
    border-radius: 8px;
    content: attr(data-tooltip);
    text-align: center;
  }

  /* Triangle hack to make tooltip look like a speech bubble */
  [data-tooltip]:after {
    position: absolute;
    bottom: 150%;
    left: 50%;
    margin-left: -5px;
    width: 0;
    border-top: 5px solid #2e2d37;
    border-top: 5px solid #2e2d37;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: " ";
    font-size: 0;
    line-height: 0;
  }

  /* Show tooltip content on hover */
  [data-tooltip]:hover:before,
  [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
  }
`;

export const Information = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
  padding-bottom: 2px;
  color: ${({ color }) => color || "#FFF"};
  font-family: PT Serif Caption;
  font-size: ${({ size }) => size - 3 || 16}px;
  line-height: 1.1;
  width: ${({ size }) => size || 16}px;
  height: ${({ size }) => size || 16}px;
`;
