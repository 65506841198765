import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin: 1rem 0px;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageError = styled.small`
  color: #ec0000;
`;

export const WrapperCard = styled.div`
  margin: 8% 25%;
`;

export const Card = styled.div`
  width: 680px;
  padding: 6rem;
  margin: 0px auto;
  border-radius: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #ffffff;
  color: #4d4c59;
`;

export const Logo = styled.img`
  width: 240px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  text-transform: uppercase;
  color: #ec0000;
  font-size: 20px;
  font-weight: bolder;
`;

export const Description = styled.p`
  color: #000;
  font-size: 14px;
  margin-bottom: 16px;
`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 16px;
  gap: 43px;
`;

export const List = styled.ul`
  li {
    list-style: disc;
  }
`;

export const WrapItens = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
`;

export const Button = styled.button`
  text-align: center;
  width: 281px;
  max-width: 100%;
  display: block;
  border-radius: 20rem;
  text-transform: uppercase;
  background: #ec0000;
  color: #ffffff;
  border: 0px;
  min-height: 44px;
  margin: 0 auto;
  font-size: 1.6rem;
  cursor: pointer;
  padding: 1.8rem;
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  margin-top: ${(props) => props.marginTop || "0px"};

  :disabled {
    background: #cbc9c9;
    color: #ffffff;
  }
`;

export const ButtonText = styled.div`
  font-weight: bold;
  &:after {
    content: url("/img/svg/str-white-arrow-right.svg");
    margin-left: 10px;
  }
`;
