import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import Card from './Card';

export default class Modal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className='modal'>
                    <div className='modal__container'>
                        <Card className={this.props.className}>
                            <span onClick={this.props.onClose} className='modal__close'>x</span>
                            <div className='modal__body'>
                                {this.props.children}
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='modal__overlay' />
            </div>
        )
    }
}

Modal.propTypes = {
    children: PropTypes.element,
    onClose: PropTypes.func,
    className: PropTypes.string,
};

hot(module)(Modal);