import React from "react";

import CookieConsent from "react-cookie-consent";

export const UserCookieConsent = () => (
    <CookieConsent
    cookieSecurity={window.location.href.startsWith('https')}
    contentStyle={{flex: 'none !important'}}
    location="bottom"
    buttonText="Entendi"
    style={{ background: "rgba(0,0,0,0.5)", alignItems: 'center', justifyContent: "center"  }}
    buttonStyle={
      { 
        background: "rgb(225, 17, 56)", 
        borderRadius: '1rem', 
        color: "white", 
        fontSize: "12px", 
        fontWeight: 'bold' 
      }
    }
  >
    <div style={{display: 'flex',  justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
    <p style={{marginBottom: "4px", fontSize: '12px'}}>
    O Santander usa Cookies, pequenos arquivos para aprimorar e proteger a sua experiência :)
    </p>
   <p style={{ fontSize: '12px'}}>
    Saiba mais na nossa 
   
    <strong style={{margin: '0 3px'}}>
      <a style={{textDecoration: 'none', color: 'rgb(255, 133, 156)'}} href="https://www.santander.com.br/hotsite/santanderfinanciamentos/politica-de-privacidade.html" target="_blank" rel="noopener noreferrer">
        Política de Privacidade
      </a>
    </strong> 
    
      e 
    <strong style={{margin: '0 3px'}}>
      <a style={{textDecoration: 'none', color: 'rgb(255, 133, 156)'}} href="https://www.santander.com.br/institucional-santander/seguranca/sobre-o-uso-de-cookies" target="_blank" rel="noopener noreferrer">
        Política de Cookies
      </a>
    </strong>
    .
    </p>
    </div>
   
  </CookieConsent>
  );