import axios from 'axios';
import { config } from '../config/config';
import { UPLOAD_FILE_HASH, UPLOAD_FILE_HASH_FAILURE } from '../constants/actionTypes';

export const fetchUploadFileAction = (file) => {
    var fileUpload = new FormData();
    fileUpload.append('file', file)
    return (dispatch) => (
        axios.post(`${config.UploadFile}`, fileUpload, { headers: { 'Content-Type': 'multipart/form-data; boundary=--------------------------287034646819310115309895', 
            'Accept': '*/*',
            'Accept-Encoding': 'gzip, deflate',
             } })
            .then(res =>
                (
                    dispatch({
                        type: UPLOAD_FILE_HASH,
                        payload: res.data
                    })
                ))
            .catch(err =>
                dispatch({
                    type: UPLOAD_FILE_HASH_FAILURE,
                    payload: err
                })
            )
    )
}