import Immutable from "immutee";
import { INITIAL_STATE } from "../utils/utils";

export default function fetchPopupReducer(state = INITIAL_STATE, action) {
  const immutable = Immutable(state);
  switch (action.type) {
    case "@POPUPS/FetchPopUps": {
      return immutable
        .set("result", action.payload)
        .set("fetched", true)
        .set("error", null)
        .done();
    }
    case "@POPUPS/FetchPopUpsError": {
      return immutable
        .set("error", action.payload)
        .set("result", [])
        .set("fetched", false)
        .done();
    }
    default: {
      return state;
    }
  }
}
