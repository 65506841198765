import styled from "styled-components";

export const ContainerInformations = styled.div`
  width: 100%;
  display: flex;

  padding: 14px 36px;

  &:nth-child(even) {
    background-color: #f3f5fb;
  }

  &:nth-child(1) {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    padding-top: 24px;
    padding-bottom: 30px;
  }

  &:last-child {
    padding-bottom: 39px;
  }

  h1 {
    font-size: 18px;
    color: #2e2d37;
    line-height: 24.5px;
  }

  img {
    margin-right: 11px;
    cursor: pointer;
  }
`;

export const WrapperInformations = styled.div`
  display: grid;

  margin-right: 100px;
  color: #2e2d37;

  b {
    margin-bottom: 16px !important;
    font-weight: bold;
  }

  .Alta,
  .Média,
  .Baixa {
    font-weight: bold;
  }

  .Alta {
    color: #ec0000;
  }

  .Média {
    color: #1cb0b9;
  }

  .Baixa {
    color: #aeaeba;
  }

  p,
  b {
    margin: 0;
    font-size: 18px;

    line-height: 24.51px;

    display: flex;
    align-items: center;

    img {
      margin-left: 1.7rem;
    }
  }
`;

export const ContainerBackground = styled.div`
  background-color: #fff;

  border-radius: 10px;

  margin: ${({ margin }) => margin};
`;
