import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import SVG from "webmotors-svg";
import { MainContext } from "../App/context";
import { connect } from "react-redux";
import { clearDb, removeToken } from "../utils/utils";
import { Link } from "react-router-dom";
import moment from "moment";

class HeaderMobile extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, urlPath: "" };
  }

  componentDidMount() {
    this.setPathName();
  }

  setPathName() {
    setTimeout(() =>
      this.setState({
        urlPath: window.location.pathname
      })
    );
  }
  handlerLogout() {
    const token = localStorage.getItem("UserToken");
    token &&
      setCancelToken(token)
        .then((response) => {
          console.log(response);
          if (response.data.sucesso) {
            removeToken();
            clearDb();
            return (location.href = "/login");
          } else {
            this.props.dispatch({
              type: "ALERT_HASH",
              payload: {
                showAlert: true,
                type: "warning",
                title: "Erro",
                message: "Falha ao realizar a ação. Tente novamente mais tarde"
              }
            });
          }
        })
        .catch((err) =>
          this.props.dispatch({
            type: "ALERT_HASH",
            payload: {
              showAlert: true,
              type: "warning",
              title: "Erro",
              message: "Falha ao realizar a ação. Tente novamente mais tarde"
            }
          })
        );
  }

  changeRoute(route) {
    this.props.dispatch({
      type: "CHANGE_ROUTE",
      payload: { route }
    });
  }

  render() {
    const { dataUltimaAtualizacao } = this.context.state;
    return (
      <div>
        {this.props.location.pathname !== "/home" &&
        this.props.location.pathname !== "/" ? (
          <div className="header-mobile">
            <div className="header-mobile__circle background">
              <div className="header-mobile__content">
                <Link
                  to={"home"}
                  className="header-mobile__content__return"
                  onClick={() => this.changeRoute("/home")}
                >
                  <SVG
                    src={require("./../../src/img/svg/str-white-arrow-right.svg")}
                  />
                </Link>
                <SVG
                  className="header-mobile__content__logo__middle"
                  onClick={() => this.changeRoute("/home")}
                  src={require("../../src/img/svg/str-icon-+fidelidade.svg")}
                />
                <Link to={"login"} onClick={() => this.handlerLogout()}>
                  <SVG
                    className="header-mobile__content__logo__right"
                    src={require("../../src/img/svg/str-icon-logout.svg")}
                  />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <div className="header-mobile--home">
            {dataUltimaAtualizacao &&
              dataUltimaAtualizacao !== "0001-01-01T00:00:00" && (
                <div className="header-mobile__updateLastDate">{`Atualização: ${moment(
                  dataUltimaAtualizacao
                ).format("DD/MM/YYYY")}`}</div>
              )}
            <div className="header-mobile--home__content">
              <SVG
                className="header-mobile--home__content__logo__middle"
                src={require("../../src/img/svg/str-icon-+fidelidade.svg")}
              />
              <Link to={"login"} onClick={() => this.handlerLogout()}>
                <SVG
                  className="header-mobile--home__content__logo__right"
                  src={require("../../src/img/svg/str-icon-logout.svg")}
                />
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

HeaderMobile.propTypes = {
  children: PropTypes.array,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  dispatch: PropTypes.func
};
HeaderMobile.contextType = MainContext;

function mapStateToProps(state) {
  return {
    changeRoutesReducer: state.changeRoutesReducer,
    sendMessageAlertAction: state.sendMessageAlert
  };
}
export default hot(module)(connect(mapStateToProps)(HeaderMobile));
