import React from 'react';
import { hot } from 'react-hot-loader';
import SVG from 'webmotors-svg';

const SplashScreen = () => {
    return (
        <div className='splash-screen'>
            <div className='splash-screen__spinner'><SVG src='../../../img/svg/str-icon-spinner.svg' /></div>
            <div className='splash-screen__fidelidade'><SVG src='../../../img/svg/str-icon-+fidelidade.svg' /></div>
            <div className='splash-screen__santander'><SVG src='../../../img/svg/str-santander.svg' /></div>
        </div>
    )
}


export default hot(module)(SplashScreen);