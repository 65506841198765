import React from "react";
import { CategoryContainer, ImageCorrect } from "./styled";

import { IconCorrect, IconSantanderFlame } from "../../assets";

const EnumCategoryColors = {
  Bronze: "#C68C50",
  Silver: "#AEAEBA",
  Gold: "#FECE35",
  Platinum: "#696977"
};

const Category = ({ category, width, selected, before }) => (
  <CategoryContainer
    color={EnumCategoryColors[category]}
    width={width}
    before={before}
  >
    <img src={IconSantanderFlame} alt="Icon santander" />
    {selected ? <ImageCorrect src={IconCorrect} alt="Correto" /> : null}
  </CategoryContainer>
);

export default Category;
