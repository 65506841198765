import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  padding: 2.3rem;
  border-radius: 0.8rem;

  h3 {
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    color: #4d4c59;
    margin-bottom: 8px;
  }

  .tooltip {
    margin-left: 5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;
