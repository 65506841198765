import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetchHelpAction } from "../../actions/fetchHelpAction";
import SVG from "webmotors-svg";
import { Card, ScoreBar, CategoryIcon } from "../../components";
import { verifyNextCategory } from "../../utils/helper";
import { MainContext } from "../../App/context";
import PageTitle from "../../components/PageTitle";

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0 };
  }

  UNSAFE_componentWillMount() {
    this.getFaq();
  }

  getFaq() {
    this.props.dispatch(fetchHelpAction()).then((e) => {
      this.setState({
        descricao: JSON.parse(e.payload.descricao)
      });
    });
  }

  handlerContent(id) {
    if (document.getElementById(id).style.display === "block") {
      document.getElementById(id).style.display = "none";
      document.getElementById(`collapsible--${id}`).classList.remove("active");
      document.getElementsByClassName(`arrow--${id}`)[0].style.transform =
        "rotate(90deg)";
    } else {
      document.getElementById(id).style.display = "block";
      document.getElementById(`collapsible--${id}`).classList.add("active");
      document.getElementsByClassName(`arrow--${id}`)[0].style.transform =
        "rotate(0deg)";
    }
  }

  createMarkup(element) {
    return { __html: element };
  }

  render() {
    const { categoriaAtual, width } = this.context.state;
    const { descricao } = this.state;

    return (
      <>
        <PageTitle>
          <SVG
            className="page__title--icon"
            src="../../../img/svg/str-icon-title-help.svg"
          />
          Ajuda
        </PageTitle>
        <div className="d-fx help">
          <div className="container__first-child">
            <div className="help__container">
              {descricao &&
                descricao.map((description, index) => {
                  return (
                    <div key={index} className="help__card">
                      <button
                        className="collapsible"
                        id={`collapsible--${index}`}
                        onClick={() => this.handlerContent(index)}
                      >
                        {" "}
                        {description.title}{" "}
                        <SVG
                          className={`help__arrow arrow--${index}`}
                          src={`../../../img/svg/str-icon-arrow.svg`}
                        />
                      </button>
                      <div id={index} className="content">
                        <hr />
                        <div
                          dangerouslySetInnerHTML={this.createMarkup(
                            description.content
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            {/* {width <= 768 && <ContactUs />} */}
          </div>
          <div className="container__second-child">
            <div
              className={`${
                this.props.platinum.status === true
                  ? "rescue__current-category rescue__current-category--platinum"
                  : "rescue__current-category"
              }`}
            >
              {categoriaAtual && (
                <Card>
                  <CategoryIcon
                    category={categoriaAtual.categoria}
                    text="CATEGORIA ATUAL"
                    lateral={true}
                    size="big"
                    active={true}
                  />
                  <ScoreBar
                    currentPoints={categoriaAtual.pontuacao}
                    maxPoints={categoriaAtual.pontuacaoMaxima}
                  />
                  {categoriaAtual.categoria !== "Platinum" ? (
                    <div className="current-category__message">
                      Faltam{" "}
                      <span>
                        {categoriaAtual.pontuacaoMaxima -
                          categoriaAtual.pontuacao +
                          1}{" "}
                      </span>
                      pontos para subir pra categoria{" "}
                      <span>
                        {verifyNextCategory(categoriaAtual.pontuacao)}
                      </span>
                    </div>
                  ) : (
                    <div className="current-category__message">
                      <span>Parabéns! Você atingiu a categoria máxima.</span>
                    </div>
                  )}
                </Card>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Help.contextType = MainContext;
Help.propTypes = {
  children: PropTypes.array,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    platinum: state.bePlatinum
  };
};

export default hot(module)(connect(mapStateToProps)(Help));
