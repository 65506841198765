import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import Card from '../../components/Card';
import InputForm from '../../components/InputForm';
import { Link } from 'react-router-dom';
import SVG from 'webmotors-svg';
import { fetchResetPasswordAction } from '../../actions/fetchResetPasswordAction';
import { connect } from 'react-redux';
import { MainContext } from '../../App/context';

class RecoveryPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleRecoveryPassword(e) {
        e.preventDefault();
        const value = document.getElementById('input__cnpj').value;
        this.props.dispatch(
            fetchResetPasswordAction(value))
            .then((resp) => {
                if (resp.payload.sucesso) {
                    return this.showAlert('Uma senha temporária foi enviada para seu e-mail: <br><br> <p style="color: #ec0000"><b>' + resp.payload.objeto.email + '</b></p>', 'success', 'MUITO BEM!', '/login')
                } else {
                    if(resp.payload.response !== undefined ){
                       this.showAlert('<p>CNPJ ou E-mail não cadastrado. <br/> <b>Fale com o seu GR</br></p>', 'error', 'DESCULPE');
                    }else{
                        this.showAlert('<p>Problemas no sistema.<br/> <b>Em instantes estará normalizado.</br></p>', 'error', 'DESCULPE');
                    }

                }
            })
            .catch((err) => {
                console.err(err)
            })
    }

    showAlert(message, type, title, url) {
        this.props.dispatch({
            type: 'ALERT_HASH',
            payload: { message: message, showAlert: true, type, title, url }
        });
    }

    render() {
        const { width } = this.context.state;
        return (
            <div>
                <div className='container bg__full-page v-align'>
                    <div className='login__card'>
                        <Card color={'clean'} className='unlogged'>
                            <div className='login__container'>
                                <div className='responsive__form v-align' style={{ height: this.state.formSignIn && '100vh', bottom: this.state.formSignIn && '0' }}>
                                    <div className='login__form'>
                                        <div className='login__form__body'>
                                            <SVG className='login__svg' src='../../../img/svg/str-colored-fidelidade-white.svg' />
                                            <form onSubmit={(e) => this.handleRecoveryPassword(e)}>
                                                <div>
                                                    <span>Esqueceu sua senha? Insira o CNPJ ou e-mail cadastrado para receber sua senha por e-mail.</span>
                                                    <InputForm value={this.state.cnpj} id='input__cnpj' type='login' placeholder='Informe seu cnpj ou e-mail' />
                                                </div>
                                                <button type='submit' className='btn btn__red btn__rounded'>Enviar</button>
                                            </form>
                                            <span className='login__link'>Você não possui login? <br /> Faça seu <Link to={'criar-conta'}>Cadastre-se!  <SVG src={require('../../../src/img/svg/str-white-arrow-right.svg')} /></Link></span>
                                            {width <= 768 && <SVG className='login__santander-logo' src={require('../../../src/img/svg/Logo_Santander-financiamentos.svg')} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {width >= 768 && <SVG className='login__santander-logo' src={require('../../../src/img/svg/Logo_Santander-financiamentos.svg')} />}
            </div>
        )
    }
}

RecoveryPassword.propTypes = {
    children: PropTypes.element,
    dispatch: PropTypes.func
};

function mapStateToProps(state) {
    return {
        sendMessageAlertAction: state.sendMessageAlert
    }
}

RecoveryPassword.contextType = MainContext;

export default hot(module)(connect(mapStateToProps)(RecoveryPassword));
