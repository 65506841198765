export function authHeader() {
  const token = localStorage.getItem("UserToken");
  if (token) {
    return {
      Authorization: "Bearer " + localStorage.getItem("UserToken"),
      Pragma: "no-cache",
      "Content-Type": "application/json"
    };
  }
  console.error("Access Token Inválido");
  return {};
}
