import React, { Component } from "react";
import { hot } from "react-hot-loader";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { config } from "../../config/config";
import { authHeader } from "../../helpers/authHeader";

import {
  CurrentCategory,
  PointingCategory,
  LastRating,
  Campaign,
  Card,
  BePlatinum,
  TermsPopUp
} from "../../components";
import Messages from "./Messages";
import { MainContext } from "../../App/context";
import { decodedToken, verifyToken } from "../../utils/utils";
import VoucherManager from "../../components/BirthDay/VoucherManager";
import SVG from "webmotors-svg";
import moment from "moment";
import terms from "../../img/banner_codigo_de_conduta-v2.png";
import plus from "../../img/bg-mais-detalhe.png";
import lavagem from "../../img/banner/popup_lavagem.png";
import LavagemPopUp from "../../components/LavagemPopUp";

moment.locale("pt-br");

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      termsPopUpOpen: true,
      termsAccepted: false,
      popUpList: []
    };
  }

  componentDidMount() {
    this.getCompanyName();
  }

  getCompanyName() {
    const result = decodedToken(verifyToken());
    this.setState({
      companyName: result.NomeEmpresa,
      actionPlatinum: result.AcaoPlatinum
    });
  }

  handleTermsAccepted() {
    this.setState({
      termsPopUpOpen: false
    });
    this.setTermsAccepted();
  }

  async setTermsAccepted() {
    const url = `${config.urlApi}/Popups/MarcarLeituraPopup`;
    await axios.post(`${url}`, 7, {
      headers: authHeader()
    });
    window.location.reload();
  }

  handleLavagemAccepted() {
    this.setState({
      termsPopUpOpen: false
    });
    this.setLavagemAccepted();
  }

  async setLavagemAccepted() {
    const url = `${config.urlApi}/Popups/MarcarLeituraPopup`;
    await axios.post(`${url}`, 8, {
      headers: authHeader()
    });
    window.location.reload();
  }

  createMarkup(element) {
    return { __html: element };
  }

  render() {
    const {
      categoriaAtual,
      classificacoesAnteriores,
      categorias,
      width,
      periodoResgateAtivo,
      beneficiosResgate,
      campanhas,
      popUpList
    } = this.context.state;

    const { companyName, actionPlatinum } = this.state;
    const popUp =
      popUpList &&
      Array.isArray(popUpList) &&
      popUpList.length > 0 &&
      popUpList.filter((p) => p.idPopup === 7 || p.idPopup === 8);

    let currentCategory = false;

    return (
      <>
        {this.state.termsPopUpOpen &&
          Array.isArray(popUp) &&
          popUp.length > 0 &&
          popUp[0].flAtivo &&
          popUp[0].idPopup === 7 && (
            <TermsPopUp>
              <div>
                <a
                  href={popUp[0].linkArquivo}
                  target="_BLANK"
                  rel="noopener noreferrer"
                  onClick={() => this.handleTermsAccepted()}
                >
                  <img
                    className="modal__bgDetalhe"
                    src={plus}
                    alt={"+ Fidelidade"}
                  />
                  <img
                    src={terms}
                    style={{ width: "960px", height: "auto" }}
                    alt={popUp[0].nome}
                  />
                </a>
                <div
                  className="modal__subTitle"
                  dangerouslySetInnerHTML={this.createMarkup(
                    popUp[0].descricao
                  )}
                />
              </div>
            </TermsPopUp>
          )}
        {this.state.termsPopUpOpen &&
          Array.isArray(popUp) &&
          popUp.length > 0 &&
          popUp[0].flAtivo &&
          popUp[0].idPopup === 8 && (
            <LavagemPopUp onClose={() => this.handleLavagemAccepted()}>
              <div>
                <img
                  className="modal__bgDetalhe"
                  src={plus}
                  alt={"+ Fidelidade"}
                />
                <img
                  src={lavagem}
                  style={{ width: "400px", height: "auto" }}
                  alt={popUp[0].nome}
                />
              </div>
            </LavagemPopUp>
          )}
        {categoriaAtual && companyName ? (
          <>
            <h2 className="page__title__message">
              Olá <b> {companyName}</b>, seja bem-vindo!
            </h2>
            <div className="wrapper__container home__currencyCategory template-grid-2-1">
              {actionPlatinum === "true" && categoriaAtual.idCategoria !== 4 ? (
                <div className="home__currencyCategory--be-platinum">
                  <BePlatinum />
                  <CurrentCategory
                    currentCategory={categoriaAtual}
                    activePlatinum={true}
                    showLinks={true}
                  />
                </div>
              ) : (
                <CurrentCategory
                  currentCategory={categoriaAtual}
                  showLinks={true}
                />
              )}
              <Card
                title="Pontuação por classificação"
                className="home__rankingPoint"
              >
                <PointingCategory
                  categories={categorias}
                  activeCateg={currentCategory}
                  showButton={true}
                />
              </Card>
            </div>

            {classificacoesAnteriores && classificacoesAnteriores.length > 0 ? (
              <div className="wrapper__container">
                <LastRating
                  atual={false}
                  category={classificacoesAnteriores[0].categoria}
                  position={classificacoesAnteriores[0].mesRef}
                  activeRedemptionPeriod={periodoResgateAtivo}
                  ransomBenefit={beneficiosResgate}
                  campaign={campanhas}
                />
              </div>
            ) : (
              <div className="wrapper__container">
                <LastRating
                  atual={true}
                  category={categoriaAtual.categoria}
                  position={moment()
                    .subtract(1, "months")
                    .format("MMMM")
                    .toUpperCase()}
                  activeRedemptionPeriod={periodoResgateAtivo}
                  ransomBenefit={[]}
                  campaign={[]}
                />
              </div>
            )}

            <div className="wrapper__container">
              <Campaign />
            </div>

            {width > 994 && (
              <>
                <div className="wrapper__container">
                  <div className="card__title">
                    <SVG
                      className="card__title__icon"
                      src="../../../img/svg/str-icon-message.svg"
                    />{" "}
                    MENSAGEM
                  </div>
                </div>
                <div className="wrapper__container message">
                  <Messages hideMessages={true} colorTitle="dark"></Messages>
                </div>
              </>
            )}

            {<VoucherManager />}
          </>
        ) : (
          <div className="loader__background v-align">
            <div className="loader" />
          </div>
        )}
      </>
    );
  }
}

Home.contextType = MainContext;
export default hot(module)(connect(bindActionCreators)(Home));
