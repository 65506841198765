import styled from "styled-components";

export const TableContainer = styled.table`
  border-radius: 8px;

  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  border: 1px solid #ecedf2;

  tr,
  td,
  th,
  tbody,
  thead {
    border: 0;
    border-spacing: 0;
  }

  th {
    padding: 24px 14px 14px;

    color: #2e2d37;
  }

  td {
    color: #2e2d37;
    padding: 10px 14px;

    text-align: center;

    text-transform: capitalize;
  }

  .Alta,
  .Média,
  .Baixa {
    font-weight: bold;
  }

  .Alta {
    color: #ec0000;
  }

  .Média {
    color: #1cb0b9;
  }

  .Baixa {
    color: #aeaeba;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: #f3f5fb;
      }
    }
  }

  .table-tooltip {
    width: 20px;
    height: 20px;

    background-color: #2e2d37;
    border-radius: 100px;

    margin: auto;
    color: #fff;
    font-family: "PT Serif Caption", serif;
    font-size: 16px;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  margin-top: 28px;
`;

export const PaginationTable = styled.div`
  display: flex;
  justify-content: flex-end;

  width: 100%;

  span {
    color: #2e2d37;
    font-weight: bold;
    font-size: 12px;

    align-self: flex-end;

    margin: 0 8px;
  }
`;

export const PaginationButtonArrow = styled.button`
  margin-right: 15px;
  display: flex;
  align-items: center;

  background-color: transparent !important;
  border: none;
  outline: none;

  cursor: pointer;

  &:hover {
    background-color: transparent !important;
  }

  &.arrow-right {
    img {
      transform: rotate(180deg);
    }

    margin-left: 15px;
    margin-right: 0;
  }
`;

export const PaginationButton = styled.button`
  color: ${({ active }) => (active ? "#FFF" : "#2e2d37")} !important;
  background-color: ${({ active }) =>
    active ? "#2e2d37" : "#E5E5E5"} !important;

  cursor: pointer;

  height: 29px;
  width: 29px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  text-align: center;
  font-size: 12px;
  border: none;

  & + button {
    margin-left: 18px;
  }
`;
