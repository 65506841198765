import api from ".";

export const getClubePlatinumRecurrence = async () => {
  const response = await api.get("/ClubePlatinum/recorrencia");

  return response;
};

export const getClubePlatinumPoints = async () => {
  const response = await api.get("/ClubePlatinum/incentivale/pontos");

  return response;
};

export const getClubePlatinumLink = async () => {
  const response = await api.get("/ClubePlatinum/incentivale/link");

  return response;
};

export const getClubePlatinumRecurrenceHistory = async () => {
  const response = await api.get("/ClubePlatinum/recorrencia/historico");

  return response;
};
