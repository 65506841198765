import React from 'react'
import Tipfy from './Tipfy'

const CampaignHistory = ({ campanhas }, ...props) => {
	// return campanhas && Array.isArray(campanhas) && campanhas.length ? (
	// 	<div className="campaignHistory">
	// 		<h5>Campanhas</h5>
	// 		<ul>
	// 			{campanhas.map(c => {
	// 				return (
	// 					<li>
	// 						<span className="title">
	// 							{c.nome}
	// 							{/* <Tipfy side="right" className="scorebar__tipfy" text={'teste'}><div className="tooltip"></div></Tipfy> */}
	// 						</span>
	// 						<span className="desc">{c.descricao}</span>
	// 					</li>
	// 				)
	// 			})}
	// 		</ul>
	// 	</div>
	// ) : null
	return null
}

export default CampaignHistory
