import Immutable from 'immutee';
import { INITIAL_STATE } from '../utils/utils';
import { SEND_EMAIL_TO_GR, SEND_EMAIL_TO_GR_FAILURE } from '../constants/actionTypes';

export default function fetchSendEmailToGRReducer(state = INITIAL_STATE, action){
    const immutable = Immutable(state);
    switch (action.type) {
        case SEND_EMAIL_TO_GR: {
            return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .set('error', null)
                .done();
        }
        case SEND_EMAIL_TO_GR_FAILURE: {
            return immutable
                .set('error', action.payload)
                .set('result', [])
                .set('fetched', false)
                .done();
        }
        default: {
            return state;
        }
    }
}