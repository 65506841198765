import Immutable from "immutee";
import { INITIAL_STATE } from "../utils/utils";
import { CHANGE_ROUTE, CHANGE_ROUTE_FAILURE } from "../constants/actionTypes";

export default function changeRoutes(state = INITIAL_STATE, action) {
  const immutable = Immutable(state);
  switch (action.type) {
    case CHANGE_ROUTE: {
      return {
        ...state,
        result: {
          route: action.payload.route
        }
      };
    }
    case CHANGE_ROUTE_FAILURE: {
      return immutable
        .set("error", action.payload)
        .set("result", [])
        .set("fetched", false)
        .done();
    }
    default: {
      return state;
    }
  }
}
