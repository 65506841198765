import styled from "styled-components";

export const WrapperModal = styled.div`
  font-size: 1.6rem;

  max-width: 436px;

  p,
  b {
    color: #2e2d37;
  }

  b {
    font-weight: 700;
  }

  .button-anchor {
    padding: 0;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;

    a {
      padding: 1.1rem 2rem;

      display: flex;
      align-items: center;
    }
  }

  .logo {
    margin-bottom: 2.5rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent};

  margin: ${({ margin }) => margin};
`;

export const ContainerSteps = styled.div`
  border-radius: 100px;

  width: 29px;
  height: 29px;

  min-height: 29px;
  min-width: 29px;

  max-width: 29px;
  max-height: 29px;

  border: 1px solid #2e2d37;
  color: #2e2d37;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.6rem;

  margin-right: 1.6rem;
`;
