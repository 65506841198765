import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { MainContext } from "../../../App/context";
import { ScoreBar, Card, CategoryIcon } from "../../../components";
import { verifyNextCategory } from "../../../utils/helper";

import RescueListItem from "../../../components/RescueListItem";
import CategoryRescue from "../../../components/categoryRescueHistory";
import ClubePlatinumBar from "../../../components/ClubePlatinumBar";
import FilterMonth from "../../../components/FilterMonth";
import PageTitle from "../../../components/PageTitle";
import clubePlatinumReducer from "../../../reducers/clubePlatinumReducer";

const History = () => {
  const context = useContext(MainContext);
  const {
    listaCriterios,
    classificacoesAnteriores,
    campanhas,
    beneficiosResgate,
    categoriaAtual,
    periodoResgateAtivo
  } = context.state;

  const [active, setActive] = useState(0);
  const [months, setMonths] = useState([]);
  const [topCampaigns, setTopCampaigns] = useState([]);
  const [listCriterios, setListCriterios] = useState([]);
  const [listBenefits, setListBenefits] = useState([]);
  const [listCampaign, setListCampaign] = useState([]);
  const [ratingItem, setRatingItem] = useState([]);
  const [lastClassifications, setLastClassifications] = useState({});
  const [ptMove, setPtMove] = useState(0);
  const [currentClubePlatinum, setCurrentClubePlatinum] = useState({});

  const platinum = useSelector((state) => state.bePlatinum);
  const { clubePlatinumPoints, clubePlatinumLinks, clubePlatinumHistory } =
    useSelector((state) => state.clubePlatinumReducer);

  const startMonth = () => {
    let monthsArr = [];

    if (classificacoesAnteriores && classificacoesAnteriores.length) {
      classificacoesAnteriores.map((item) => {
        let date = moment(item.dataReferencia).format("MMM/YY");
        if (!monthsArr.find((o) => o.dateDesc === date) && date !== "Jan/01") {
          monthsArr.push({
            dateDesc: moment(item.dataReferencia).format("MMM/YY"),
            dateFirst: moment(item.dataReferencia)
              .startOf("month")
              .format("YYYY-MM-DD"),
            dateLast: moment(item.dataReferencia)
              .endOf("month")
              .format("YYYY-MM-DD"),
            mesRef: moment(item.dataReferencia).format("MMMM")
          });
        }
      });
    }

    if (campanhas && campanhas.length) {
      campanhas.map((item) => {
        let date = moment(item.dtReferencia).format("MMM/YY");
        if (!monthsArr.find((o) => o.dateDesc === date) && date !== "Jan/01") {
          monthsArr.push({
            dateDesc: date,
            dateFirst: moment(item.dtReferencia)
              .startOf("month")
              .format("YYYY-MM-DD"),
            dateLast: moment(item.dtReferencia)
              .endOf("month")
              .format("YYYY-MM-DD"),
            mesRef: moment(item.dtReferencia).format("MMMM")
          });
        }
      });
    }

    if (beneficiosResgate && beneficiosResgate.length) {
      beneficiosResgate.map((item) => {
        let date = moment(item.dataReferencia).format("MMM/YY");
        if (!monthsArr.find((o) => o.dateDesc === date) && date !== "Jan/01") {
          monthsArr.push({
            dateDesc: date,
            dateFirst: moment(item.dataReferencia)
              .startOf("month")
              .format("YYYY-MM-DD"),
            dateLast: moment(item.dataReferencia)
              .endOf("month")
              .format("YYYY-MM-DD"),
            mesRef: moment(item.dataReferencia).format("MMMM")
          });
        }
      });
    }

    if (monthsArr.length) {
      setMonths(monthsArr.reverse());
      setActive(monthsArr.length - 1);

      setCurrentClubePlatinum(
        clubePlatinumHistory[clubePlatinumHistory.length - 1]
      );
    }
  };

  useEffect(() => {
    if (months.length) {
      move(months.length - 1);
    }
  }, [months]);

  const onRangeDateReference = (referenceDate, first, last) => {
    if (moment(referenceDate).isBetween(first, last, null, "[]")) return true;

    return false;
  };

  const onChangeSelectMounth = (index) => {
    let benefits = [];
    let campaigns = [];
    let topCampaign = [];
    let itemRating = [];
    let lastClassification = [];
    let criterios = [];

    const first = months[index].dateFirst;
    const last = months[index].dateLast;

    if (beneficiosResgate) {
      benefits = beneficiosResgate.filter(
        (x) =>
          x.resgatado &&
          onRangeDateReference(
            moment(x.dataReferencia).format("YYYY-MM-DD"),
            first,
            last
          )
      );
    }

    if (campanhas) {
      campaigns = campanhas.filter(
        (x) =>
          x.resgatado &&
          x.tipoExibicao !== 3 &&
          onRangeDateReference(
            moment(x.dtReferencia).format("YYYY-MM-DD"),
            first,
            last
          )
      );

      topCampaign = campanhas.filter(
        (x) =>
          x.atingiu &&
          x.tipoExibicao !== 3 &&
          onRangeDateReference(
            moment(x.dtReferencia).format("YYYY-MM-DD"),
            first,
            last
          )
      );
    }

    itemRating = classificacoesAnteriores;

    if (classificacoesAnteriores && classificacoesAnteriores.length) {
      lastClassification = classificacoesAnteriores.filter((x) =>
        onRangeDateReference(
          moment(x.dataReferencia).format("YYYY-MM-DD"),
          first,
          last
        )
      );

      if (lastClassification.length) {
        lastClassification = lastClassification[0];
      }
    }

    if (listaCriterios) {
      criterios = listaCriterios.filter((x) =>
        onRangeDateReference(
          moment(x.dataReferencia).format("YYYY-MM-DD"),
          first,
          last
        )
      );
    }

    setTopCampaigns(topCampaign);
    setListCriterios(criterios);
    setActive(index);
    setListBenefits(benefits);
    setListCampaign(campaigns);
    setRatingItem(itemRating);
    setLastClassifications(lastClassification);
    setCurrentClubePlatinum(clubePlatinumHistory[index]);
  };

  const move = (i) => {
    const element = document.getElementById("month");
    const a = document.getElementById(`m_${i}`);

    if (element && a) {
      element.scrollLeft = a.offsetLeft - element.offsetWidth / 2.8;
    }

    setActive(i);
    onChangeSelectMounth(i);
  };

  useEffect(() => {
    startMonth();
  }, [classificacoesAnteriores]);

  return (
    <>
      <PageTitle>Histórico de resgate</PageTitle>
      {clubePlatinumLinks &&
      clubePlatinumPoints &&
      clubePlatinumHistory.length > 0 ? (
        <ClubePlatinumBar
          recurrence={currentClubePlatinum}
          points={clubePlatinumPoints}
          link={clubePlatinumLinks}
          marginBottom="1.6rem"
        />
      ) : null}
      {classificacoesAnteriores ? (
        <div className="history history__container">
          <div className="container__first-child">
            <FilterMonth
              months={months}
              rating={ratingItem}
              listaCriterios={listCriterios}
              move={(Integer) => move(Integer)}
              valueSelected={(Integer) => onChangeSelectMounth(Integer)}
              ptMove={ptMove}
              activeSelect={active}
            />
            <CategoryRescue
              criteria={
                listCriterios && listCriterios.length ? listCriterios[0] : []
              }
              rescueCategory={lastClassifications}
              metaDesafio={
                lastClassifications && lastClassifications.metaDesafio
                  ? lastClassifications.metaDesafio
                  : null
              }
              mesRefInad={months && months[active] && months[active].mesRef}
              campanhas={topCampaigns}
              showLinks={true}
            />

            {listBenefits && listBenefits.length > 0 ? (
              <div className="history__benefits">
                <div className="history__title">Benefícios resgatados:</div>
                {listBenefits.map((item, index) => (
                  <div
                    className={
                      item.acaoPlatinum === true
                        ? "rescue-item rescue-item__platinum"
                        : "rescue-item"
                    }
                    key={item.idBeneficio}
                  >
                    <Card
                      key={item.idBeneficio}
                      className="rescue-item__title"
                      title={item.nome ? item.nome : ""}
                    >
                      {item.acaoPlatinum === true && (
                        <div className="rescue-item__platinum-identify">
                          Exclusivo Platinum
                        </div>
                      )}
                      <div className="rescue-item__body">
                        {item.descricao ? item.descricao : ""}
                      </div>
                      {item.resgatado && (
                        <>
                          {item.linkVideo ? (
                            <div className="rescue-item__download">
                              <a
                                className="btn btn__red btn__rounded"
                                href={item.linkVideo ? item.linkVideo : "#"}
                                download
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                download
                              </a>
                            </div>
                          ) : (
                            <div className="rescue-item__data">
                              Resgatado em:{" "}
                              <strong>
                                {moment(item.dataResgate).format("DD/MM/YYYY")}
                              </strong>
                            </div>
                          )}
                        </>
                      )}
                    </Card>
                  </div>
                ))}
              </div>
            ) : (
              <div className="history__campaign">
                <div className="history__title">Benefícios resgatados:</div>
                <div className="history__empty">
                  Resgate automático. Em caso de dúvida, fale com seu Consultor.{" "}
                </div>
              </div>
            )}
            {listCampaign && listCampaign.length > 0 ? (
              <>
                <div className="history__campaign">
                  <div className="history__title">Campanhas resgatadas:</div>
                  <RescueListItem
                    data={listCampaign}
                    resgateEnabled={periodoResgateAtivo}
                  />
                </div>
              </>
            ) : (
              <div className="history__campaign">
                <div className="history__title">Campanhas resgatadas:</div>
                <div className="history__empty">
                  Resgate automático. Em caso de dúvida, fale com seu Consultor.{" "}
                </div>
              </div>
            )}
          </div>

          <div className="container__second-child">
            <div
              className={`${
                platinum.status === true
                  ? "rescue__current-category rescue__current-category--platinum"
                  : "rescue__current-category"
              }`}
            >
              {categoriaAtual && (
                <Card>
                  <CategoryIcon
                    category={categoriaAtual.categoria}
                    text="CATEGORIA ATUAL"
                    lateral={true}
                    size="big"
                    active={true}
                  />
                  <ScoreBar
                    currentPoints={categoriaAtual.pontuacao}
                    maxPoints={categoriaAtual.pontuacaoMaxima}
                  />
                  {categoriaAtual.categoria !== "Platinum" ? (
                    <div className="current-category__message">
                      Faltam{" "}
                      <span>
                        {categoriaAtual.pontuacaoMaxima -
                          categoriaAtual.pontuacao +
                          1}{" "}
                      </span>
                      pontos para subir pra categoria{" "}
                      <span>
                        {verifyNextCategory(categoriaAtual.pontuacao)}
                      </span>
                    </div>
                  ) : (
                    <div className="current-category__message">
                      <span>Parabéns! Você atingiu a categoria máxima.</span>
                    </div>
                  )}
                </Card>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default History;
