import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { MainContext } from "../../App/context";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CreateAccountForm from "../../components/CreateAccountForm";
import SVG from "webmotors-svg";
import TouchSlider from "../../components/TouchSlider";
import PopupBloqueioBens from "../../img/popup-bloqueio-bens.png";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabledPassword: true,
      passwordValid: false,
      validation: {},
      showCreateAccountForm: false
    };
    this.slider = createRef();
  }

  componentDidMount() {
    const { params } = this.props.match;
    if (params) {
      params.criacao ? this.setState({ showCreateAccountForm: true }) : "";
    }
  }

  switchForm(form) {
    if (this.context.width < 994) {
      switch (form) {
        case "signup":
          this.setState({ showCreateAccountForm: true });
          break;
        default:
          break;
      }
    }
  }

  sliderInstructions() {
    return (
      <div>
        <SVG
          className="create-account__logo"
          src={require("../../../src/img/svg/str-colored-fidelidade-white.svg")}
        />
        <TouchSlider loop={true} showNav={true} selected={0}>
          <div>
            <p className="create-account__slider__title">O que é o Programa</p>
            <p className="create-account__slider__description">
              É um programa de fidelidade que concede a você parceiro lojista,
              benefícios exclusivos conforme seu nível de vinculação e
              relacionamento com a Santander Financiamentos
            </p>
          </div>
          <div>
            <p className="create-account__slider__title">Benefícios</p>
            <p className="create-account__slider__description">
              Tenha benefícios exclusivos de acordo com a sua classificação
              mensal
            </p>
          </div>
          <div>
            <p className="create-account__slider__title">Participe</p>
            <p className="create-account__slider__description">
              Cadastre-se agora mesmo utilizando seu e-mail da{" "}
              <b> Santander Financiamentos </b> e saiba mais
            </p>
          </div>
        </TouchSlider>
        {this.context.width > 994 && (
          <span className="create-account__link">
            {" "}
            Já possui conta? Faça seu <Link to={"login"}>Login!</Link>
          </span>
        )}
      </div>
    );
  }

  render() {
    const { width } = this.context.state;
    return (
      <div>
        <div className="container v-align">
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "999",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flex: "1",
              background: "#000000c2"
            }}
            className="popup-bloqueio-bens"
          >
            <img
              style={{
                width: "582px",
                height: "353px"
              }}
              src={PopupBloqueioBens}
            />
          </div>
          <div className="create-account__card">
            <Card color={"clean"} className="unlogged">
              <div className="flex-container create-account__container">
                {!this.state.showCreateAccountForm && (
                  <div className="create-account__slider">
                    {this.sliderInstructions()}
                  </div>
                )}
                <hr />
                <div className="create-account__options">
                  {this.context.width > 769 ||
                  this.state.showCreateAccountForm ? (
                    <div>
                      {this.context.width <= 994 && (
                        <SVG
                          src={require("../../../src/img/svg/str-colored-fidelidade-white.svg")}
                        />
                      )}
                      <CreateAccountForm />
                    </div>
                  ) : (
                    <div>
                      <Link
                        to={"login"}
                        className="btn btn__red btn__rounded btn__fullscreen"
                      >
                        {" "}
                        Entrar
                      </Link>
                      <span className="create-account__link">
                        Não possui Login?
                        <a onClick={() => this.switchForm("signup")}>
                          {" "}
                          CADASTRE-SE{" "}
                          <SVG
                            src={require("../../../src/img/svg/str-white-arrow-right.svg")}
                          />
                        </a>{" "}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              {width <= 768 && (
                <SVG
                  className="login__santander-logo"
                  src={require("../../../src/img/svg/Logo_Santander-financiamentos.svg")}
                />
              )}
            </Card>
          </div>
        </div>
        {width >= 768 && (
          <SVG
            className="login__santander-logo"
            src={require("../../../src/img/svg/Logo_Santander-financiamentos.svg")}
          />
        )}
      </div>
    );
  }
}

CreateAccount.propTypes = {
  children: PropTypes.element,
  _email: PropTypes.string,
  _password: PropTypes.string,
  _passwordConfirm: PropTypes.string,
  dispatch: PropTypes.func,
  match: PropTypes.object
};

CreateAccount.contextType = MainContext;

export default hot(module)(CreateAccount);
