import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { checkAjaxCallPending } from '../utils/utils';

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: this.props.loaded ? this.props.loaded : false
        }
    }

    domReady() {
        if (document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading') {
            this.setState({
                loaded: true
            })
        } else {
            document.addEventListener('DOMContentLoaded', this.setState({
                loaded: true
            }));
        }
    }

    componentDidMount() {
        window.Loader = this;
        checkAjaxCallPending(this);
    }

    render() {
        return (
            <div>
                {this.state.loaded &&
                    <div className='loader__background v-align'><div className='loader' /></div>
                }
            </div>
        );
    }
}

Loader.propTypes = {
    loaded: PropTypes.bool,
};

export default hot(module)(Loader);