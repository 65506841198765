import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import CategoryIcon from './CategoryIconBar';
import ScoreBar from './ScoreBarHistory';
import Card from './Card';
import SVG from 'webmotors-svg';
import ProgressBar from '../components/ProgressBar'
import Tipfy from '../components/Tipfy'
import CampaignHistory from '../components/CampaignHistory';

import moment from 'moment';

const CategoryRescue = ({ campanhas, rescueCategory, criteria, metaDesafio, mesRefInad, ...props }) => {
   const [toggleCard, setToggleCard] = useState(false);

   const handleToggleCard = () => {
    if (toggleCard) {
        document.getElementsByClassName(`arrow`)[0].style.transform = 'rotate(90deg)';
    } else {
        document.getElementsByClassName(`arrow`)[0].style.transform = 'rotate(-90deg)';
    }
    setToggleCard(!toggleCard)
   }

   return (
      <div className="category-rescue">
         <Card>
            <div className="category-rescue__header">
               <div className="category-rescue__icon category-rescue__icon__bar">
                  {rescueCategory.categoria ?
                     (<CategoryIcon category={rescueCategory.categoria} barrStatus={rescueCategory} activePlatinum={false} text='CLASSIFICAÇÃO DO MÊS' lateral={true} size='big' active={true} />)
                    :
                    (
                      <div className="category-rescue__empty">
                        Não existe classificação para esse mês.
                      </div>
                    )
                  }
               </div>
            </div>

            <div className="category-rescue__content--show">
              {criteria.criterios !== undefined ? (
                  <>
                    <div id="panel" className="category-rescue__content-info">Seus Pontos do Mês de {mesRefInad}</div>
                    <ScoreBar metaDesafio={metaDesafio} points={criteria.criterios[0]} />
                  </>
              ) : (
                <div className="category-rescue__empty">
                  Não existe histórico de pontuação referente esse mês
                </div>
              )}
            </div>

            <CampaignHistory campanhas={campanhas} />
         </Card>
      </div>
   )
}

const mapStateToProps = state => ({
  platinum: state.bePlatinum
});

CategoryRescue.propTypes = {
   rescueCategory: PropTypes.any,
   metaDesafio: PropTypes.any,
   mesRefInad: PropTypes.string,
   criteria: PropTypes.any,
   dispatch: PropTypes.func,
   showLinks: PropTypes.bool,
};

export default hot(module)(connect(mapStateToProps)(CategoryRescue));
