import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import SVG from "webmotors-svg";
import { MainContext } from "../App/context";
import ScoreBar from "./ScoreBarHistory";
import { IconSantanderFlame } from "../assets";

class CategoryIcon extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const categ = this.props.category.toLowerCase();
    let width = 0;
    if (categ === "bronze") {
      width = 100;
    } else if (categ === "silver") {
      width = 70;
    } else {
      width = 120;
    }

    return (
      <>
        {this.props.activePlatinum && this.props.platinum.status ? (
          <div
            className={`category-bar__container${
              this.props.lateral && this.context.width > 994 ? "__flex" : ""
            }`}
          >
            <div className="category-bar__container--platinum">
              <div
                className={`category-bar__icon category-bar__icon--${categ} category-bar__icon--inactive category-bar__icon--${
                  this.props.size ? this.props.size : ""
                }`}
              >
                <SVG className="category-bar__img" src={IconSantanderFlame} />
              </div>
              <div
                className={`category-bar__icon category-bar__icon--platinum category-bar__icon--active category-bar__icon--${
                  this.props.size ? this.props.size : ""
                }`}
              >
                <SVG className="category-bar__img" src={IconSantanderFlame} />
              </div>
            </div>
            <div className={`category-bar__description`}>
              {this.props.text && (
                <div
                  className={`category-bar__icon--text category-bar__icon--text__${this.props.size}`}
                >
                  {this.props.text}
                </div>
              )}
              {this.props.category &&
              this.props.activePlatinum &&
              this.props.platinum.status === true ? (
                <div
                  className={`category-bar__icon--name category-bar__icon--name__${this.props.size}`}
                >
                  <span className="category-bar__icon--text category-bar__icon--text-taxed">
                    <span className="category-bar__icon--text-opacity">
                      {this.props.category}
                    </span>
                  </span>
                  <span className="category-bar__icon--text category-bar__icon--text-platinum">
                    PLATINUM
                  </span>
                </div>
              ) : (
                <div
                  className={`category-bar__icon--name category-bar__icon--name__${this.props.size}`}
                >
                  {this.props.category}
                </div>
              )}
              {this.props.pontuacaoMinima >= 0 && (
                <div className="category-bar__icon--name category-bar__icon--points">
                  {`${
                    this.props.pontuacaoMaxima <= 999
                      ? `${this.props.pontuacaoMinima}-${this.props.pontuacaoMaxima}`
                      : `+${this.props.pontuacaoMinima}`
                  } pts`}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`category-bar__container${
              this.props.lateral && this.context.width > 994 ? "__flex" : ""
            }`}
          >
            <div
              className={`category-bar__icon category-bar__icon--${categ} category-bar__icon--${
                this.props.size ? this.props.size : ""
              }`}
            >
              <SVG className="category-bar__img" src={IconSantanderFlame} />
            </div>
            <div className={`category-bar__description`}>
              {this.props.text && (
                <div
                  className={`category-bar__icon--text category-bar__icon--text__${this.props.size}`}
                >
                  {this.props.text}
                </div>
              )}
              {this.props.category &&
              this.props.activePlatinum &&
              this.props.platinum.status === true ? (
                <div
                  className={`category-bar__icon--name category-bar__icon--name__${this.props.size}`}
                >
                  <span className="category-bar__icon--text category-bar__icon--text-taxed">
                    <span className="category-bar__icon--text-opacity">
                      {this.props.category}
                    </span>
                  </span>
                  <span className="category-bar__icon--text category-bar__icon--text-platinum">
                    PLATINUM
                  </span>
                </div>
              ) : (
                <div
                  className={`category-bar__icon--name category-bar__icon--name__${this.props.size}`}
                >
                  <div className="category" style={{ maxWidth: `${width}px` }}>
                    {this.props.category}
                  </div>
                  <div className="score">
                    <ScoreBar
                      currentPoints={this.props.barrStatus.pontuacao}
                      maxPoints={this.props.barrStatus.pontuacaoMaxima}
                    />
                  </div>
                </div>
              )}
              {this.props.pontuacaoMinima >= 0 && (
                <div className="category-bar__icon--name category-bar__icon--points">
                  {`${
                    this.props.pontuacaoMaxima <= 999
                      ? `${this.props.pontuacaoMinima}-${this.props.pontuacaoMaxima}`
                      : `+${this.props.pontuacaoMinima}`
                  } pts`}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

CategoryIcon.propTypes = {
  category: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  points: PropTypes.number,
  active: PropTypes.bool,
  nomeCategoria: PropTypes.string,
  lateral: PropTypes.bool,
  pontuacaoMinima: PropTypes.number,
  pontuacaoMaxima: PropTypes.number,
  barrStatus: PropTypes.object
};

CategoryIcon.contextType = MainContext;
const mapStateToProps = (state) => ({
  platinum: state.bePlatinum
});

export default connect(mapStateToProps, null)(CategoryIcon);
