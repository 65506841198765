export const ALERT_HASH = 'ALERT_HASH';
export const ALERT_HASH_FAILURE = 'ALERT_HASH_FAILURE';

export const APP_ALL_DATA_HASH = 'APP_ALL_DATA_HASH';
export const APP_ALL_DATA_HASH_FAILURE = 'APP_ALL_DATA_HASH_FAILURE';

export const APP_CREATE_ACCOUNT_HASH = 'APP_CREATE_ACCOUNT_HASH';
export const APP_CREATE_ACCOUNT_HASH_FAILURE = 'APP_CREATE_ACCOUNT_HASH_FAILURE';

export const APP_HELP_HASH = 'APP_HELP_HASH';
export const APP_HELP_HASH_FAILURE = 'APP_HELP_HASH_FAILURE';

export const APP_LOGIN_HASH = 'APP_LOGIN_HASH';
export const APP_LOGIN_HASH_FAILURE = 'APP_LOGIN_HASH_FAILURE';

export const APP_RECOVERY_PASSWORD_HASH = 'APP_RECOVERY_PASSWORD_HASH';
export const APP_RECOVERY_PASSWORD_HASH_FAILURE = 'APP_RECOVERY_PASSWORD_HASH_FAILURE';

export const APP_REDEEM_BENEFITS_HASH = 'APP_REDEEM_BENEFITS_HASH';
export const APP_REDEEM_BENEFITS_HASH_FAILURE = 'APP_REDEEM_BENEFITS_HASH_FAILURE';

export const APP_USER_HASH = 'APP_USER_HASH';
export const APP_USER_HASH_FAILURE = 'APP_USER_HASH_FAILURE';

export const APP_VALIDATION_HASH = 'APP_VALIDATION_HASH';
export const APP_VALIDATION_HASH_FAILURE = 'APP_VALIDATION_HASH_FAILURE';

export const APP_NEW_PASSWORD_HASH = 'APP_NEW_PASSWORD_HASH';
export const APP_NEW_PASSWORD_HASH_FAILURE = 'APP_NEW_PASSWORD_HASH_FAILURE';

export const DB_ADD_HASH = 'DB_ADD_HASH';
export const DB_ADD_HASH_FAILURE = 'DB_ADD_HASH_FAILURE';

export const UPLOAD_FILE_HASH = 'UPLOAD_FILE_HASH';
export const UPLOAD_FILE_HASH_FAILURE = 'UPLOAD_FILE_HASH_FAILURE';

export const WINDOW_FILE_HASH = 'WINDOW_FILE_HASH';
export const WINDOW_FILE_HASH_FAILURE = 'WINDOW_FILE_HASH_FAILURE';

export const SEND_EMAIL_TO_GR = 'SEND_EMAIL_TO_GR';
export const SEND_EMAIL_TO_GR_FAILURE = 'SEND_EMAIL_TO_GR_FAILURE';

export const CHANGE_ROUTE = 'CHANGE_ROUTE';
export const CHANGE_ROUTE_FAILURE = 'CHANGE_ROUTE_FAILURE';

export const APP_NEWS_HASH = 'APP_NEWS_HASH';
export const APP_NEWS_HASH_FAILURE = 'APP_NEWS_HASH_FAILURE';
export const APP_NEWS_READ_HASH = 'APP_NEWS_NOT_READ_HASH';
export const APP_NEWS_UPDATE_HASH = 'APP_NEWS_UPDATE_HASH';
export const GET_INFO_COMPLEMENT = 'GET_INFO_COMPLEMENT';
export const GET_INFO_COMPLEMENT_FAILURE = 'GET_INFO_COMPLEMENT_FAILURE';
export const SEND_COMPLEMENT_INFO = 'SEND_COMPLEMENT_INFO';
export const SEND_COMPLEMENT_INFO_FAILURE = 'SEND_COMPLEMENT_INFO_FAILURE';
export const UPDATE_COMPLEMENT_INFO = 'UPDATE_COMPLEMENT_INFO';

export const BE_PLATINUM = 'BE_PLATINUM'
