import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MainContext } from "../../App/context";
import { fetchSendEmailToGRAction } from "../../actions/fetchSendEmailToGRAction";
import { decodedToken, verifyToken } from "../../utils/utils";
import Button from "../../components/Button";

class GRMessages extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, textLength: "0/500" };
  }

  sendMessage() {
    const { gerenteRelacionamento } = this.context.state;
    const body = document.getElementById("message").value;
    let recipient;
    this.state.messageType === "customRecipient"
      ? (recipient = document.getElementById("gr").value)
      : (recipient = this.state.messageType);

    this.props
      .dispatch(
        fetchSendEmailToGRAction({
          idGerenteRelacionamento:
            gerenteRelacionamento.idGerenteRelacionamento,
          emailDestinatario: gerenteRelacionamento.email,
          assunto: "",
          corpo: body
        })
      )
      .then((resp) => {
        if (resp.payload.sucesso) {
          this.showAlert("Mensagem enviada com sucesso", "success");
          this.setState({ textLength: "0/500" });
          document.getElementById("message").value = "";
        } else {
          this.showAlert("E-mail não enviado consulte seu GR.");
        }
      })
      .catch((err) => {
        console.err(err);
      });
  }

  showAlert(message, type = "error") {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { message: message, showAlert: true, type: type }
    });
  }

  countCaracters(e) {
    this.setState({
      textLength: `${e.target.value.length}/500`
    });
  }

  render() {
    const { gerenteRelacionamento } = this.context.state;
    const EhLojista =
      decodedToken(verifyToken()) &&
      decodedToken(verifyToken()).EhLojista &&
      decodedToken(verifyToken()).EhLojista === "true";
    return (
      <>
        <div className="messages-page__gr">
          <img
            src={require("../../../src/img/png/str-colored-icon-santander.png")}
          />
          <div className="messages-page__details">
            <p className="messages-page__details name">
              {gerenteRelacionamento && gerenteRelacionamento.nome}
            </p>

            {gerenteRelacionamento &&
              gerenteRelacionamento.telefone &&
              gerenteRelacionamento.telefone !== "NULL" && (
                <p className="messages-page__details phone">
                  {gerenteRelacionamento.telefone}
                </p>
              )}

            <p className="messages-page__details email">
              {gerenteRelacionamento && gerenteRelacionamento.email}
            </p>
          </div>
        </div>
        <div className={`input__form ${!EhLojista ? "disable-message" : ""}`}>
          <textarea
            id="message"
            maxLength="500"
            onKeyUp={(e) => this.countCaracters(e)}
            placeholder="Sua mensagem"
          />
          <span style={{ color: "#e2e2e2" }}>{this.state.textLength}</span>
        </div>
        <div
          className={`input__form--rigth ${
            !EhLojista ? "disable-message" : ""
          }`}
        >
          <Button onClick={() => this.sendMessage()}>Enviar Mensagem</Button>
        </div>
      </>
    );
  }
}

GRMessages.propTypes = {
  children: PropTypes.array,
  hideMessages: PropTypes.bool,
  colorTitle: PropTypes.string,
  dispatch: PropTypes.func
};

GRMessages.contextType = MainContext;

export default hot(module)(connect(bindActionCreators)(GRMessages));
