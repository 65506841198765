import styled from "styled-components";

export const ContainerBackground = styled.div`
  background-color: #fff;

  padding: 20px 13px;
  border-radius: 0.8rem;

  display: flex;

  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`;
