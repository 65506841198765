import React, { Component } from 'react';
import { MainContext } from '../../App/context';
import Card from '../../components/Card';
import moment from 'moment';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { fetchNewsUpdateReadAction, updateNewsAction } from '../../actions/fetchNewsAction';
import { connect } from 'react-redux';
import iconClock from '../../img/svg/icon-clock.svg'

class News extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      showNews: false,
      showNewsIndex: -1,
      novidade: {},

    };
  }

  selectMessage(_novidade, showNewsIndex) {
    this.props.dispatch(fetchNewsUpdateReadAction(_novidade.idNovidade))
      .then((resp) => {
        this.setState({
          novidade: _novidade,
          showNewsIndex,
          showNews: true
        });
      })
  }

  createMarkup(element) {
    return { __html: element };
  }

  renderNews() {
    const { novidade } = this.state;
    return (
      <div className='news-page__detail'>
        <div onClick={() => this.setState({ showNews: false })} className='news-page__detail--button'>
          <div className='news-page__detail--button-X'>X</div>
        </div>
        <div className='news-page__detail--title'>{novidade.titulo}</div>
        <div className='news-page__detail--description'  dangerouslySetInnerHTML={this.createMarkup(novidade.descricao)} />
      </div>
    )
  }

  createMarkup(element) {
    return { __html: element };
  }

  render() {
    const novidades = this.props.news.result.length != 0 ? this.props.news.result.novidades : this.context.state.novidades
    const { showNews, showNewsIndex } = this.state;

    return (
      <div className='news-page'>
        <div className='news-page__body container__first-child'>
          {
            novidades && novidades.map((novidade, i) => {
              return (
                <div key={i}>
                  {showNews === true && showNewsIndex === i ?
                    this.renderNews() :
                    <div onClick={() => this.selectMessage(novidade, i)} className={`news-page__message news-page__message${novidade.lida == true ? '' : '__not-read'}`}>
                      <img className='news-page__message--img' src={require('../../../src/img/png/str-colored-icon-santander.png')} />
                      <div>
                        <div className={`news-page__title`}>{novidade.titulo}
                          <span className={`news-page__expira`}>
                            <div className="icon-clock">
                              <img src={iconClock} />
                            </div>
                            Expira dia {moment(novidade.dtExpiracao || undefined).format('L')}</span>
                        </div>
                        
                      </div>
                    </div>
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    );
  }
}

News.contextType = MainContext;

News.propTypes = {
  dispatch: PropTypes.func,
  notification: PropTypes.object
};

function mapStateToProps(state) {
  return {
    news: state.fetchNewsReducer
  }
}

export default hot(module)(connect(mapStateToProps)(News));
