import React, { Component, Fragment } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import MenuLateral from "../../components/MenuLateral";
import MenuMobile from "../../components/MenuMobile";
import HeaderMobile from "../../components/HeaderMobile";
import Header from "../../components/Header";
import { MainContext } from "../../App/context";
import { withRouter } from "react-router-dom";
import SVG from "webmotors-svg";
import { verifyAcesso } from "../../utils/utils";
import SidebarMenu from "../../components/SidebarMenu";

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = { urlPath: "" };

    if (
      this.props.location.pathname !== "/informacoes-complementares" &&
      verifyAcesso()
    ) {
      return (location.href = "/informacoes-complementares");
    }
  }

  render() {
    return (
      <>
        {this.props.location.pathname !== "/informacoes-complementares" ? (
          <Fragment>
            {this.context.width >= 994 && (
              <Header location={this.props.location} />
            )}
            <div className="wrapper">
              {this.context.width >= 994 ? (
                <SidebarMenu location={this.props.location} />
              ) : (
                <MenuMobile location={this.props.location} />
              )}
              <div
                className={`wrapper__body${
                  this.context.width >= 994 ? "" : "-mobile"
                }`}
              >
                {this.context.width <= 994 && (
                  <HeaderMobile location={this.props.location} />
                )}
                {this.props.children}
              </div>
            </div>
            {this.context.width >= 994 && (
              <div className="wrapper__footer">
                <SVG
                  className="wrapper__santander-logo"
                  src={require("../../../src/img/svg/Logo_Santander-financiamentos.svg")}
                />
              </div>
            )}
          </Fragment>
        ) : (
          <>{this.props.children}</>
        )}
      </>
    );
  }
}

Main.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object
};

Main.contextType = MainContext;
export default withRouter(hot(module)(Main));
