import React from 'react'
import PropTypes from 'prop-types';
import Card from '../Card'
import Checkbox from '../Checkbox'


const RescueListItemCampaign = ({ data, disabled, handleClick, readOnly}) => {

  return (
    <>
      {data && data.map(campaign => (
        <div className="rescue-item rescue-item__campaign" key={campaign.idCampanha}>
          <Card className="rescue-item__title " title={campaign.nome}>
            <div className="rescue-item__body rescue-item__campaign-body">{campaign.descricao}</div>
              <div className="rescue-item__option">
                {readOnly == false &&
                  <Checkbox
                    id={campaign.idCampanha}
                    disabled={disabled}
                    change={e => handleClick(e, campaign)}
                  />
                }
              </div>
          </Card>
        </div>
      ))}
    </>
  )
}

RescueListItemCampaign.propTypes = {
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
}



export default RescueListItemCampaign
