import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { Tabs, TabIcon } from "../../components/Tabs";
import {
  Card,
  ScoreBar,
  CategoryIcon,
  Checkbox,
  Modal
} from "../../components";
import { MainContext } from "../../App/context";
import { fetchRedeemBenefitsAction } from "../../actions/fetchRedeemBenefitsAction";
import { fetchTokenAction } from "../../actions/fetchTokenAction";
import { verifyNextCategory } from "../../utils/helper";
import { connect } from "react-redux";
import moment from "moment";
import queryString from "query-string";
import PageTitle from "../../components/PageTitle";

class Benefits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      showBenefitsModal: false,
      disableRedeemButton: true,
      benefitsList: []
    };
    [];
  }

  changeModalState() {
    this.setState({ showBenefitsModal: !this.state.showBenefitsModal });
  }

  showAlert(message, type = "error", title = "DESCULPE", url = "") {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { message: message, showAlert: true, type: type, title, url }
    });
  }

  getAllData() {
    this.props.dispatch(fetchTokenAction()).then((e) => {
      const objectEntries = Object.entries(e.payload);
      this.context.state.periodoResgateAtivo = e.payload.periodoResgateAtivo;
      objectEntries.map((obj) => {
        this.context.state[obj[0]] = obj[1];
      });
    });
  }

  handlerRedeemBenefits() {
    let benefitList = [];
    for (const iterator of this.itensForRedeem) {
      benefitList.push(iterator.idBeneficio);
    }
    this.props
      .dispatch(fetchRedeemBenefitsAction(benefitList))
      .then((e) => {
        if (!e.type.includes("FAILURE")) {
          this.setState({ showBenefitsModal: false });
          this.showAlert(
            "Seus benefícios foram resgatados com Sucesso!",
            "success",
            "PARABÉNS!"
          );
          this.getAllData();
        } else {
          this.showAlert(
            "Erro ao resgatar! Tente novamente ou consulte seu GR."
          );
        }

        this.props.dispatch(fetchTokenAction());
      })
      .catch((error) =>
        console.error("TCL: Benefits -> handlerRedeemBenefits -> error", error)
      );
  }

  renderBenefitsModal() {
    return (
      this.state.showBenefitsModal && (
        <Modal
          className="benefits__modal"
          onClose={() => this.changeModalState()}
        >
          <div>
            <h3>Confirmação de Resgate</h3>
            <span>
              Os benefícios abaixo estão disponíveis para resgate. Confirme e
              ganhe.
            </span>
            <div className="benefits-redeem__container">
              {this.itensForRedeem.map((benefits, index) =>
                this.renderBenefitsCard(benefits, index, true, true)
              )}
            </div>
            <button
              onClick={() => this.handlerRedeemBenefits()}
              className="btn btn__rounded btn__red"
            >
              Confirmar Resgate
            </button>
          </div>
        </Modal>
      )
    );
  }

  beneftisRescue(...e) {
    let curretBeneficiosResgate;
    if (this.itensForRedeem !== undefined) {
      curretBeneficiosResgate = this.itensForRedeem.filter(
        (element) => element.idBeneficio === e[1].idBeneficio
      );
    }
    if (!curretBeneficiosResgate || curretBeneficiosResgate.length === 0) {
      if (!this.itensForRedeem) {
        this.itensForRedeem = [];
      }
      this.itensForRedeem.push(e[1]);
    } else {
      let index = this.itensForRedeem.indexOf(e[1]);
      if (index > -1) {
        this.itensForRedeem.splice(index, 1);
      }
    }

    if (this.itensForRedeem.length >= 1) {
      this.setState({ disableRedeemButton: false });
    } else {
      this.setState({ disableRedeemButton: true });
    }
  }

  renderBenefitsCard(
    benefits,
    index,
    currentyCategory,
    redeem = false,
    showCheck = true,
    rescue
  ) {
    const { periodoResgateAtivo, width } = this.context.state;
    return (
      <Card className="tabs__container" key={index}>
        <div>
          <h5>
            {currentyCategory && showCheck ? benefits.nome : benefits.tipo}
          </h5>
          {width > 768 && (
            <p>
              {currentyCategory && showCheck
                ? benefits.descricao
                : benefits.beneficio}
            </p>
          )}
        </div>
        {rescue &&
          !redeem &&
          currentyCategory &&
          !benefits.resgatado &&
          showCheck && (
            <div className="benefits__checkbox">
              <Checkbox
                id={`${index}`}
                disabled={!periodoResgateAtivo && !redeem && currentyCategory}
                checked={
                  this.itensForRedeem &&
                  this.itensForRedeem.length > 0 &&
                  this.itensForRedeem.filter(
                    (element) => element.idBeneficio === benefits.idBeneficio
                  ).length !== 0
                }
                change={(...e) => this.beneftisRescue(...e, benefits)}
              />
            </div>
          )}
        {benefits.resgatado && (
          <div className="benefits__data-resgate">
            Data de Resgate:
            <div className="benefits__data-resgate--data">
              {moment(benefits.dataResgate).format("DD/MM/YYYY")}
            </div>
          </div>
        )}
      </Card>
    );
  }

  renderBenefitsListGeneric(category, currentyCategory, rescue) {
    let showButtonRedeem = false;
    let redeeemBenefits =
      (category && category.beneList.filter((benefit) => benefit.resgatado)) ||
      [];
    let benefits =
      (category && category.beneList.filter((benefit) => !benefit.resgatado)) ||
      [];

    const { disableRedeemButton } = this.state;
    if (
      rescue &&
      currentyCategory &&
      benefits &&
      benefits.filter((benefit) => !benefit.resgatado).length > 0
    ) {
      showButtonRedeem = true;
    }

    return (
      <div>
        {currentyCategory &&
          category.redeem &&
          benefits &&
          benefits.length > 0 && (
            <h5 className="benefits__title">
              Beneficios disponíveis para resgate
            </h5>
          )}
        {
          <div>
            {benefits.length > 0 &&
              benefits.map((benefits, j) => {
                return (
                  <div key={j}>
                    {this.renderBenefitsCard(
                      benefits,
                      j,
                      currentyCategory,
                      false,
                      category.reedem,
                      rescue
                    )}
                  </div>
                );
              })}
            {showButtonRedeem && (
              <button
                disabled={disableRedeemButton}
                onClick={() => this.changeModalState()}
                className="btn btn__rounded btn__red"
              >
                Fazer Resgate
              </button>
            )}
          </div>
        }
        {currentyCategory && redeeemBenefits && (
          <div>
            {redeeemBenefits.length > 0 && (
              <h5 className="benefits__title">Benefícios resgatados</h5>
            )}
            {redeeemBenefits.map((benefits, j) => {
              return (
                <div key={j}>
                  {this.renderBenefitsCard(
                    benefits,
                    j,
                    currentyCategory,
                    false,
                    category.reedem
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  getCategory() {
    const entryParam = queryString.parse(this.props.location.search);
    const { categoriaAtual, classificacoesAnteriores, periodoResgateAtivo } =
      this.context.state;

    if (entryParam.filter === "rescue" && periodoResgateAtivo) {
      return {
        categ: this.trataCategAtual(classificacoesAnteriores),
        rescue: true
      };
    }

    return { categ: this.trataCategAtual(categoriaAtual), rescue: false };
  }

  validDefaultActive(categ) {
    const { beneficios } = this.context.state;

    const category = beneficios.findIndex(
      (b) => b.categoria === (categ && categ.categoria)
    );

    if (category === -1) return 0;

    return category;
  }

  addBronzerToBenefits() {
    const { beneficios } = this.context.state;

    const silver =
      beneficios && beneficios.find((item) => item.categoria === "Silver");
    const existsBronze =
      beneficios && beneficios.filter((item) => item.categoria === "Bronze")[0];

    const value = silver ? silver.pontos.split("-")[0] - 1 : 299;

    if (!existsBronze && beneficios) {
      this.setState({
        benefitsList: [
          {
            categoria: "Bronze",
            pontos: `0-${value} pts`,
            listaBeneficios: [
              {
                tipo: "",
                beneficio:
                  "Melhore sua performance para aproveitar os benefícios do programa!"
              }
            ]
          },
          ...beneficios
        ]
      });
    } else if (beneficios) {
      this.setState({ benefitsList: beneficios });
    }
  }

  renderBenefits() {
    const { beneficios, beneficiosResgate, periodoResgateAtivo } =
      this.context.state;
    const { categ, rescue } = this.getCategory();
    const { benefitsList } = this.state;

    !benefitsList.length && this.addBronzerToBenefits();

    return beneficios ? (
      <Tabs
        defaultActiveIndex={this.validDefaultActive(categ)}
        type="icon"
        className="container__first-child"
        titleDescript={"Pontuação por categoria"}
        description={
          "São quatro classificações que podem ser atingidas de acordo com a pontuação acumulada em cada indicador:"
        }
      >
        {benefitsList.map((category, i) => {
          const currentyCategory = categ.categoria === category.categoria;
          let currentyCategoryBenefitsList = { beneList: [], reedem: true };
          currentyCategoryBenefitsList.reedem =
            currentyCategory && periodoResgateAtivo && rescue;
          currentyCategoryBenefitsList.beneList =
            currentyCategoryBenefitsList.reedem
              ? beneficiosResgate
              : category.listaBeneficios;

          return (
            <TabIcon
              currentyCategory={currentyCategory}
              key={i}
              title={category.categoria}
              icon={`category-${
                category.categoria && category.categoria.toLowerCase()
              }`}
              points={category.pontos}
            >
              {this.renderBenefitsListGeneric(
                currentyCategoryBenefitsList,
                currentyCategory,
                rescue
              )}
            </TabIcon>
          );
        })}
      </Tabs>
    ) : (
      <div className="loader__background v-align">
        <div className="loader" />
      </div>
    );
  }

  trataCategAtual(categ) {
    return Array.isArray(categ) ? categ[0] : categ;
  }

  render() {
    const { categoriaAtual } = this.context.state;

    const categ = this.trataCategAtual(categoriaAtual);

    return (
      <>
        <PageTitle>Benefícios</PageTitle>
        <div className="benefits__container">
          {categ ? (
            <div className="container__first-child">
              {this.renderBenefits()}
            </div>
          ) : (
            <div className="loader__background v-align">
              <div className="loader" />
            </div>
          )}
          <div className="container__second-child">
            <div
              className={`${
                this.props.platinum.status === true
                  ? "rescue__current-category rescue__current-category--platinum"
                  : "rescue__current-category"
              }`}
            >
              {categ && (
                <Card>
                  <CategoryIcon
                    category={categ.categoria}
                    text="CATEGORIA ATUAL"
                    lateral={true}
                    size="big"
                    active={true}
                  />
                  <ScoreBar
                    currentPoints={categ.pontuacao}
                    maxPoints={categ.pontuacaoMaxima}
                  />
                  {categ.categoria !== "Platinum" ? (
                    <div className="current-category__message">
                      Faltam{" "}
                      <span>
                        {categ.pontuacaoMaxima - categ.pontuacao + 1}{" "}
                      </span>
                      pontos para subir pra categoria{" "}
                      <span>{verifyNextCategory(categ.pontuacao)}</span>
                    </div>
                  ) : (
                    <div className="current-category__message">
                      <span>Parabéns! Você atingiu a categoria máxima.</span>
                    </div>
                  )}
                </Card>
              )}
            </div>
          </div>
          {this.renderBenefitsModal()}
        </div>
      </>
    );
  }
}

Benefits.propTypes = {
  dispatch: PropTypes.func,
  children: PropTypes.array,
  location: PropTypes.any
};

Benefits.contextType = MainContext;

function mapStateToProps(state) {
  return {
    sendMessageAlertAction: state.sendMessageAlert,
    platinum: state.bePlatinum
  };
}

export default hot(module)(connect(mapStateToProps)(Benefits));
