import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import SVG from "webmotors-svg";
import { connect } from "react-redux";
import {
  decodedToken,
  verifyToken,
  clearDb,
  removeToken
} from "../utils/utils";
import { setCancelToken } from "../requests/autenticacao";
import { MainContext } from "../App/context";
import moment from "moment";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.getCompanyName();
  }

  handlerLogout() {
    const token = localStorage.getItem("UserToken");
    token &&
      setCancelToken(token)
        .then((response) => {
          console.log(response);
          if (response.data.sucesso) {
            removeToken();
            clearDb();
            return (location.href = "/login");
          } else {
            this.props.dispatch({
              type: "ALERT_HASH",
              payload: {
                showAlert: true,
                type: "warning",
                title: "Erro",
                message: "Falha ao realizar a ação. Tente novamente mais tarde"
              }
            });
          }
        })
        .catch((err) =>
          this.props.dispatch({
            type: "ALERT_HASH",
            payload: {
              showAlert: true,
              type: "warning",
              title: "Erro",
              message: "Falha ao realizar a ação. Tente novamente mais tarde"
            }
          })
        );
  }

  getCompanyName() {
    const result = decodedToken(verifyToken());
    result &&
      this.setState({
        companyName: result.NomeEmpresa,
        desRatingLoja: result.DescRatingLoja
      });
  }

  render() {
    const { dataUltimaAtualizacao } = this.context.state;
    return (
      <div className="header">
        <div className="header__container">
          <div className="header__left">
            <div className="header__santander">
              <SVG src={require("../../src/img/svg/str-santander.svg")} />
            </div>
            {dataUltimaAtualizacao && (
              <div className="header__updateLastDate">
                <span className="header__updateLastDate--bold">
                  Última Atualização:{" "}
                </span>
                {dataUltimaAtualizacao === "0001-01-01T00:00:00" ||
                dataUltimaAtualizacao === undefined
                  ? "--"
                  : moment(dataUltimaAtualizacao).format("DD/MM/YYYY")}
              </div>
            )}
          </div>
          <div className="header__center">
            <SVG
              className="header__logo"
              src={require("../../src/img/svg/str-icon-+fidelidade.svg")}
            />
          </div>
          <div className="header__right">
            <div
              className="header__companyName"
              onClick={() => this.handlerLogout()}
            >
              {this.state.companyName}
              <SVG
                className="header__logout"
                src={require("../../src/img/svg/str-icon-logout.svg")}
              />
            </div>
            {this.state.desRatingLoja && (
              <div className="header__rating-dealer">
                Rating Dealer:
                <span className="header__rating-dealer--bold">
                  {this.state.desRatingLoja}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  children: PropTypes.array,
  location: PropTypes.object,
  updateLastDate: PropTypes.any,
  dispatch: PropTypes.func
};

Header.contextType = MainContext;

function mapStateToProps(state) {
  return {
    sendMessageAlertAction: state.sendMessageAlert
  };
}

export default hot(module)(connect(mapStateToProps)(Header));
