import { BE_PLATINUM } from '../constants/actionTypes';

export const fetchBePlatinumAction = (status) => {
  return (dispatch) => (
    dispatch({
      type: BE_PLATINUM,
      payload: status
    })
  )
}
