import styled from "styled-components";

export const ContainerBar = styled.div`
  width: 100%;
  background-color: #2e2d37;
  border-radius: 0.8rem;

  margin-top: ${({ marginTop }) => marginTop};

  color: #fff;
  padding: 1.5rem;

  margin-bottom: ${({ marginBottom }) => marginBottom || "2.8rem"};
  transition: max-height 0.15s ease-out;

  p {
    margin-left: 1.4rem;
  }

  button {
    padding: 0;
    color: #fff;
    font-family: Poppins, sans-serif;
    font-size: 1.4rem;

    min-width: 100px;

    a {
      padding: 1.1rem 2rem;
    }

    .image-know-more {
      transform: ${({ open }) => open && "rotate(180deg)"};
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  justify-content: ${({ justifyContent }) => justifyContent};

  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
`;

export const Label = styled.p`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.2rem")};
  font-weight: ${({ fontWeight }) => fontWeight};

  margin-left: 0 !important;
  margin: ${({ margin }) => margin} !important;
`;

export const Divider = styled.hr`
  width: 0.5px;
  color: #fff;
  height: 46px;
`;

export const WrapperInformations = styled.div`
  max-width: 170px;
`;
