import styled from "styled-components";

export const CategoryContainer = styled.div`
  width: ${({ width }) => width || "20px"} !important;
  height: ${({ width }) => width || "20px"} !important;
  max-height: ${({ width }) => width || "20px"} !important;
  max-width: ${({ width }) => width || "20px"} !important;
  min-width: ${({ width }) => width || "20px"} !important;
  min-height: ${({ width }) => width || "20px"} !important;
  border-radius: 50%;
  background-color: ${({ color }) => color || "#D3D3D3"};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    ${({ color, before }) =>
      color &&
      before &&
      `
      content: '';
      display: inline-block;
      left: 50%;
      position: absolute;
      width: 50%;
      height: 100%;
      border-bottom-right-radius: 160px;
      border-top-right-radius: 160px;
      background-color: rgba(0, 0, 0, 0.1);
    `}
  }

  img {
    width: ${({ width }) => (width ? `calc(${width} - 40px)` : "10px")};
  }
`;

export const ImageCorrect = styled.img`
  position: absolute;

  bottom: -15px;
  right: -10px;
  width: 40px !important;
`;
