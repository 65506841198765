import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Card from '../../components/Card';
import { fetchUploadFileAction } from '../../actions/fetchUploadFile';

class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: []
        };
    }

    onChangeHandler = event => {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }

    onClickHandler() {
        this.setState({
            messagesError: null,
            messagesRight: null
        })
        this.props.dispatch(
            fetchUploadFileAction(this.state.selectedFile))
            .then(e => {
                if (e.type === 'UPLOAD_FILE_HASH_FAILURE') {
                    this.setState({
                        messagesRight: e.payload.response.data
                    })
                } else {
                    this.setState({
                        messagesError: [e.payload.mensagem]
                    })
                }
            });
    }

    render() {
        return (
            <div>
                <Card>
                    <div className='card__body'>
                        <div className='upload-file'>
                            <h3 className='card__title'>Escolha o arquivo para fazer o upload</h3>
                            <h6 className='card__subtitle'>Basta clicar no ícone a baixo e selecionar o arquivo na pasta desejada</h6>
                            <input type='file' name='file' id='file' onChange={this.onChangeHandler} className='inputfile' accept='csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' />
                            <label htmlFor='file'>Buscar</label>
                            <div className='upload-file__name'>{this.state.selectedFile && <span>{this.state.selectedFile.name}</span>}</div>
                            <button className='btn btn__rounded btn__red' onClick={() => this.onClickHandler()}>Enviar arquivo</button>
                        </div>
                        <ul>
                            <li className='upload-file__right'>
                                {this.state.messagesError && this.state.messagesError.length > 0 && this.state.messagesError.map((message, index) => { return message })}
                            </li>
                            {this.state.messagesRight && this.state.messagesRight.map((message, index) => { return (<li key={index} className='upload-file__error'>{message}</li>) })}
                        </ul>
                    </div>
                </Card>
            </div>
        )
    }
}

UploadFiles.propTypes = {
    children: PropTypes.element,
    dispatch: PropTypes.func,
    store: PropTypes.object
};

export default hot(module)(connect((store) => {
    return {
        UploadFile: store.UploadFile,
    };
})(UploadFiles));
