import axios from 'axios';
import { config } from '../config/config';
import { authHeader } from '../helpers/authHeader';
import { APP_REDEEM_BENEFITS_HASH, APP_REDEEM_BENEFITS_HASH_FAILURE } from '../constants/actionTypes';

export const fetchRedeemBenefitsAction = ({ beneficios, campanhas, idEmpresa}) => {
    return (dispatch) => (
      axios.post(`${config.urlApi}/ResgateBeneficios`, { idEmpresa, beneficios, campanhas }, { headers: authHeader() })
        .then(res => (
            dispatch({
                type: APP_REDEEM_BENEFITS_HASH,
                payload: res.data
            })
        ))
        .catch(err => (
            dispatch({
                type: APP_REDEEM_BENEFITS_HASH_FAILURE,
                payload: err
            })
        ))
    )
}
