import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({percente, display=false}) => ( 
   <div className="progressbar">
      <div className="progress">
         <div className="progressbar--pointer" style={{left:`calc(${percente}% + 6px)` }}></div>
         <div className="bar" style={{width:`${percente}%`}}>
               {display && <div className="percent">{percente}%</div> }
            </div>        
      </div>
    </div>
)

ProgressBar.propTypes = {
   percente: PropTypes.any, 
   display: PropTypes.any
}

export default ProgressBar
