import styled from "styled-components";

export const Container = styled.div`
  /* margin: -3rem 0 1.25rem 0; */
  margin-left: 1rem;
  display: flex;
  align-items: center;
  /* height: 60px; */
  font-weight: 900;
  font-size: 1.1rem;
  .tooltip {
    margin: 0;
  }
`;

export const Bullet = styled.span`
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  display: inline-block;
  margin: 0 0.5rem 0 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: solid 1px
    ${(props) => {
      switch (props.farol) {
        case "R":
          return "rgba(236,8,8,1)";
        case "Y":
          return "rgba(236,187,8,1)";
        case "G":
          return "rgba(17,188,80,1)";
        default:
          break;
      }
    }};

  background: ${(props) => {
    switch (props.farol) {
      case "R":
        return "linear-gradient(218deg, rgba(255,72,5,1) 0%, rgba(236,8,8,1) 100%)";
      case "Y":
        return "linear-gradient(218deg, rgba(255,248,5,1) 0%, rgba(236,187,8,1) 100%)";
      case "G":
        return "linear-gradient(218deg, rgba(45,253,212,1) 0%, rgba(17,188,80,1) 100%)";
      default:
        break;
    }
  }};
`;
