import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Slider from '../../components/Slider';
import SVG from 'webmotors-svg';
import { FirstStep, SecondStep, ThirdStep } from '../../components/ComplementInformationForm';
import { fetchSendComplementAction } from '../../actions/fetchComplementAction'
import { formatPhone, formatCep, formatMoney } from '../../utils/regex';
import Alert from '../../components/Alert';
import Card from '../../components/Card';
import { setAcesso } from '../../utils/utils';


class ComplementInformation extends Component {
    constructor(props) {
        super(props); 
        this.slider = React.createRef();
        this.first = React.createRef();
        this.state = {
            complement: {
                result:{
                    comoQuerSerChamado:'',
                    celular:'',
                    cep:'',
                    logradouro:'',
                    numero:'',
                    bairro:'',
                    cidade:'',
                    uf:'',
                    faturamento: '0',
                    quantidadeVendedores: 0,
                    nome: '',
                    email: ''
                }
            }
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    showAlert(message, type = 'error', title = 'DESCULPE', url = '', button = undefined) {
        this.props.dispatch({
            type: 'ALERT_HASH',
            payload: { message: message, showAlert: true, type: type, title, url, button }
        });
    }

    onChange(e, id){
        e.preventDefault();
        const { complement } = this.state;

        if(e.target.id === "celular")
            complement.result[e.target.id] = formatPhone(e.target.value);
        else if(e.target.id === "cep")
            complement.result[e.target.id] = formatCep(e.target.value);        
        else if(e.target.id === "faturamento") {
            complement.result[e.target.id] = formatMoney(e.target.value);        
        }
        else
            complement.result[e.target.id] = e.target.value;

        this.setState({complement});
        setTimeout(() => document.getElementById(id).focus());  
    }

    validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    onSubmit(e){
        e.preventDefault();
        const { complement } = this.state;

        if( e.target.id === "first"){
            if(complement.result.comoQuerSerChamado === ''){
                this.showAlert('Campo "Como Quer Ser Chamado" é obrigatório');
                setTimeout(() => document.getElementById("comoQuerSerChamado").focus());
                return;
            }else if(complement.result.celular === '' || complement.result.celular.length !== 15){
                this.showAlert('Campo "Seu Celular" é obrigatório');
                setTimeout(() => document.getElementById("celular").focus());
                return;
            } else if(complement.result.nome === ''){
                this.showAlert('Campo "Seu Nome" é obrigatório');
                setTimeout(() => document.getElementById("nome").focus());
                return;
            } else if(complement.result.email === '' || !this.validateEmail(complement.result.email)){
                this.showAlert('Campo "Seu E-mail" é obrigatório');
                setTimeout(() => document.getElementById("email").focus());
                return;
            }
        }else if( e.target.id === "second"){
            if(complement.result.cep === ''){
                this.showAlert('Campo "Cep" é obrigatório');
                setTimeout(() => document.getElementById("cep").focus());
                return;
            }else if(complement.result.logradouro === ''){
                this.showAlert('Campo "Logradouro" é obrigatório');
                setTimeout(() => document.getElementById("logradouro").focus());
                return;
            }else if(complement.result.numero === ''){
                this.showAlert('Campo "Número" é obrigatório');
                setTimeout(() => document.getElementById("numero").focus());
                return;
            }else if(complement.result.bairro === ''){
                this.showAlert('Campo "Bairro" é obrigatório');
                setTimeout(() => document.getElementById("bairro").focus());
                return;
            }else if(complement.result.cidade === ''){
                this.showAlert('Campo "Cidade" é obrigatório');
                setTimeout(() => document.getElementById("cidade").focus());
                return;
            }else if(complement.result.uf === ''){
                this.showAlert('Campo "Estado" é obrigatório');
                setTimeout(() => document.getElementById("uf").focus());
                return;
            }            
        }else if( e.target.id === "finalizar"){
            if(complement.result.faturamento <= 0){
                this.showAlert('Campo "Qual seu faturamento mensal" é obrigatório');
                setTimeout(() => document.getElementById("faturamento").focus());
                return;
            }else if(complement.result.quantidadeVendedores === '' || complement.result.quantidadeVendedores === undefined){
                this.showAlert('Campo "Quantos vendedores sua loja possui" é obrigatório');
                setTimeout(() => document.getElementById("quantidadeVendedores").focus());
                return;
            }
                       
            this.props.dispatch(fetchSendComplementAction(complement.result)).then(()=>{
                setAcesso(false)

                this.showAlert('Cadastro efetuado com sucesso! Conheça o programa, se fidelize conosco e garanta os melhores benefícios!', 'success', 'Seja Bem Vindo', '/home', 'CONTINUAR');
            })
        }
        
        this.slider.handleSliderNext();        
    }

    render() {
        const { complement } = this.state;
        const validData = complement.result;

        return (
            <div className='container-complement'>
                <Card>
                    <div className="block-svg">
                        <SVG className='card_svg' src='../../../img/svg/str-colored-fidelidade-white.svg' />
                    </div>
                    <Slider onRef={(ref)=>this.slider=ref} forceBack={true} stepsPosition={'bottom'} clickable={false} hideArrows={true}>
                        <div><FirstStep complement={complement.result && complement.result} onSubmit={this.onSubmit} onChange={this.onChange}/></div>
                        <div><SecondStep complement={complement.result && complement.result} onSubmit={this.onSubmit} onChange={this.onChange}/></div>
                        <div><ThirdStep complement={complement.result && complement.result} onSubmit={this.onSubmit} onChange={this.onChange}/></div>
                    </Slider>
                </Card>
                <div className="blokc-svg-santander-logo">
                    <SVG className='santander-logo' src='../../../img/svg/Logo_Santander-financiamentos.svg' />
                </div>
            </div>
        )
    }
}

ComplementInformation.propTypes = {
    children: PropTypes.element
};

function mapStateToProps(state) {
    return {
        complement: state.fetchComplementReducer
    }
}
export default hot(module)(connect(mapStateToProps)(ComplementInformation));