import React from "react";
import ModalBackground from "../ModalBackground";
import { BackgroundImage } from "./styled";

const LendingPopup = ({ open, onClickClose }) => {
  return (
    <ModalBackground
      open={open}
      onClickClose={onClickClose}
      padding="0"
      iconCloseUrl="../../../assets/icons/icon-cross-circle-white.svg"
    >
      <BackgroundImage src="../../img/popup_emprestimo_sim.png" />
    </ModalBackground>
  );
};

export default LendingPopup;
