import axios from "axios";
import { config } from "../config/config";
import {
  APP_VALIDATION_HASH,
  APP_VALIDATION_HASH_FAILURE
} from "../constants/actionTypes";

export const fetchCreateUserValidationAction = (token) => {
  return (dispatch) =>
    axios
      .get(`${config.urlApi}/Autenticacao/validar?token=${token}`)
      .then((res) =>
        dispatch({
          type: APP_VALIDATION_HASH,
          payload: res.data
        })
      )
      .catch((err) =>
        dispatch({
          type: APP_VALIDATION_HASH_FAILURE,
          payload: err
        })
      );
};

export const fetchRegisterUser = (token, cnpj, senha) => {
  return axios.post(`${config.urlApi}/Autenticacao/finalizarregistrar`, {
    Token: token,
    Cnpj: cnpj.replace(/\D/g, ""),
    Senha: senha
  });
};
