import React from "react";
import { IconError, IconMaisFidelidade, IconSuccess } from "../../assets";
import Button from "../Button";
import ModalBackground from "../ModalBackground";
import { Label, WrapperStatusModal } from "./styled";

const StatusModal = ({ status, open, onClickClose }) => {
  const getInformations = () => {
    switch (status) {
      case "error":
        return {
          image: IconError,
          title: "Não autorizado!",
          description:
            "Você não autorizou a participação dos seus <br/>vendedores na campanha."
        };
      default:
        return {
          image: IconSuccess,
          title: "Autorizado!",
          description:
            "Seus vendedores poderão participar da campanha através da plataforma <a target='_blank' href='https://www.maisfidelidadevendedorbs.com.br'>+Fidelidade Vendedores</a>."
        };
    }
  };

  return (
    <ModalBackground open={open} onClickClose={onClickClose}>
      <WrapperStatusModal>
        <img src={IconMaisFidelidade} alt="+Fidelidade" />

        <img
          className="status-modal-icon"
          src={getInformations().image}
          alt="Sucesso"
        />

        <Label fontSize="1.4rem" fontWeight="500">
          {getInformations().title}
        </Label>
        <Label
          fontSize="1.4rem"
          margin="0 0 25px"
          dangerouslySetInnerHTML={{ __html: getInformations().description }}
        />

        <Button margin="auto" theme="border" onClick={onClickClose}>
          Sair
        </Button>
      </WrapperStatusModal>
    </ModalBackground>
  );
};

export default StatusModal;
