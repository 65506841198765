import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { MainContext } from "../../App/context";
import Button from "../Button";

import CategoryInformation from "../CategoryInformation";
import Label from "../Label";
import { Container, Wrapper } from "./styled";
import Tooltip from "../Tooltip";

const PointingCategory = ({ categories, showButton }) => {
  const context = useContext(MainContext);
  const { categoriaAtual } = context.state;

  const dispatch = useDispatch();

  const changeRoute = (route) => {
    dispatch({
      type: "CHANGE_ROUTE",
      payload: { route }
    });
  };

  return (
    <Container>
      <h3>
        Pontuação por classificação
        <Tooltip
          size="14"
          marginLeft="5px"
          data="Acompanhe sua performance do mês"
        />
      </h3>
      {categoriaAtual && (
        <div>
          <Label fontSize="1.2rem" color="#4D4C59">
            São quatro classificações que podem ser atingidas de acordo com a
            pontuação mensal.
          </Label>
          <div className="pointing-category__categories">
            {categories.map((category, index) => {
              return (
                <>
                  <CategoryInformation
                    width="60px"
                    key={index}
                    category={category.nomeCategoria}
                    minPoints={category.pontuacaoMinima}
                    maxPoints={category.pontuacaoMaxima}
                    size="small"
                    active={categoriaAtual.categoria === category.nomeCategoria}
                  />
                </>
              );
            })}
          </div>

          <Wrapper>
            {showButton && (
              <Link
                to={"beneficios?filter=posicaoAtual"}
                onClick={() => changeRoute("/beneficios")}
              >
                <Button>Conheça os benefícios</Button>
              </Link>
            )}
          </Wrapper>
        </div>
      )}
    </Container>
  );
};

export default PointingCategory;
