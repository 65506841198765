export function validateEmail(email) {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  let strongPassword = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );
  return strongPassword.test(password);
}

export function validateEqualValues(value1, value2) {
  return value1 === value2;
}

export function validatePhone(tel) {
  var re = /\(\d{2}\)\s\d{4,5}-?\d{4}/g;
  re.test(tel); //true
}

export function formatCep(cep) {
  cep = cep.replace(/\D/g, ""); //Remove tudo o que não é dígito
  cep = cep.replace(/(\d{5})(\d)/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
  return cep;
}

export function formatPhone(tel) {
  tel = tel.replace(/\D/g, "");
  tel = tel.replace(/^(\d\d)(\d)/g, "($1) $2");
  tel = tel.replace(/(\d{5})(\d)/, "$1-$2");
  return tel;
}

export function formatMoney2(int) {
  var tmp = int + "";
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if (tmp.length > 6) tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  return tmp;
}

export function formatMoney(value = 0) {
  value = value.replace(/\D/g, "");
  var valor = parseFloat(value);
  return valor.toLocaleString("pt-BR", { minimumFractionDigits: 0 });

  // value=value.replace(/\D/g,"");
  // value=value.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  // return value;
}
