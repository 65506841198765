const initialState = {
  totalContratosAtrasados: 0
};

const oportunityPanelReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EXPIRED_CONTRACTS":
      return {
        ...state,
        totalContratosAtrasados: action.payload
      };
  }

  return state;
};

export default oportunityPanelReducer;
