import axios from 'axios';
import { config } from '../config/config';
import { authHeader } from '../helpers/authHeader';
import { APP_USER_HASH, APP_USER_HASH_FAILURE } from '../constants/actionTypes';

export const fetchTokenAction = () => {
    return (dispatch) => (
        axios.get(`${config.urlApi}/pontuacao`, { headers: authHeader() })
            .then(res => (
                dispatch({
                    type: APP_USER_HASH,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: APP_USER_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}