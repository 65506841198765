import axios from 'axios';
import { config } from '../config/config';
import { APP_HELP_HASH, APP_HELP_HASH_FAILURE } from '../constants/actionTypes';

export const fetchHelpAction = () => {
    return (dispatch) => (
        axios.get(`${config.urlApi}/ComoFunciona`)
            .then(res => (
                dispatch({
                    type: APP_HELP_HASH,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: APP_HELP_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}