import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { fetchBePlatinumAction } from '../../actions/fetchBePlatinumAction';
import { fetchTokenAction } from '../../actions/fetchTokenAction';



const BePlatinum = ({ dispatch, ...props }) => {
  const [check, setCheck] = useState(false)

  useEffect(() => {
    if(props.platinum.status || localStorage.getItem('BE_PLATINUM') === "true") {
      setCheck(true)
    } else {
      setCheck(false)
    }

  },[])

  useEffect(() => {
    if(check || localStorage.getItem('BE_PLATINUM') === "true") {
      dispatch(fetchBePlatinumAction(true))
    } else {
      dispatch(fetchBePlatinumAction(false))
    }
  }, [check]);


  const onHandleClick = () => {
    setCheck(!check)
    localStorage.setItem('BE_PLATINUM', !check)
    getAllData()
  }

  const getAllData = () => {
    dispatch(fetchTokenAction())
      .then((e) => {
          const objectEntries = Object.entries(e.payload);
          this.context.state.periodoResgateAtivo = e.payload.periodoResgateAtivo
          objectEntries.map(obj => {
              this.context.state[obj[0]] = obj[1];
          })
      });
   }

  return (
    <div className="be-platinum">
      <div className="be-platinum__description">
        Teste agora a experiência de ser: <strong>Platinum</strong>
      </div>
      <div className="be-platinum__action">
        {check ? <p>Desabilitar Platinum</p> : <p>Habilitar Platinum</p>}

        <div className={`${check ? 'be-platinum__button' : 'be-platinum__button be-platinum__button--disabled'}`} onClick={() => onHandleClick()} >
          <input type="checkbox" checked={check}/>
          <span className="knobs"></span>
          <span className="layer"></span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  platinum: state.bePlatinum
});

export default connect(mapStateToProps, null)(BePlatinum)
