const initialState = {
  clubePlatinumHistory: [],
  clubePlatinumLinks: null,
  clubePlatinumPoints: null,
  clubePlatinumRecurrence: null
};

const clubePlatinumReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLUBE_PLATINUM_HISTORY":
      return {
        ...state,
        clubePlatinumHistory: action.payload
      };
    case "GET_CLUBE_PLATINUM_LINKS":
      return {
        ...state,
        clubePlatinumLinks: action.payload
      };
    case "GET_CLUBE_PLATINUM_POINTS":
      return {
        ...state,
        clubePlatinumPoints: action.payload.pontosIncentivale
      };
    case "GET_CLUBE_PLATINUM":
      return {
        ...state,
        clubePlatinumRecurrence: action.payload
      };
    default:
      return state;
  }
};

export default clubePlatinumReducer;
