import styled from "styled-components";

export const ContainerSidebar = styled.div`
  width: 8vw;
  padding: 2rem 0 0;

  .sidebar-container {
    position: sticky;

    top: 10rem;
    width: 100px;
    border-radius: 0.8rem;
    background-color: #fff;
    padding: 3rem 0.8rem;
    text-align: center;

    a {
      text-decoration: none;
      color: inherit;

      &.active-router {
        p {
          color: rgb(243, 18, 60);
          font-weight: 600;
        }
      }
    }

    .oportunity-news {
      position: absolute;
      top: -15px;
      left: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;

      span {
        color: #fff;
        font-size: 9px;
        font-weight: bold;
        background-color: #1cb0b9;
        width: 25px;
        height: 25px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Poppins, sans-serif;
      }
    }
  }
`;

export const ContainerItemMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  margin-bottom: 1.5rem;

  img {
    width: 20px;
    height: 20px;
  }

  p {
    font-size: 12px;
    text-align: center;
    margin: 0 !important;
  }
`;
