import React from "react";

import {
  PaginationTable,
  PaginationButtonArrow,
  PaginationButton,
  TableContainer,
  PaginationContainer
} from "./styled";

import { decodedToken, verifyToken } from "../../utils/utils";

import { IconArrowLeft, IconWhatsapp } from "../../assets";

import { replaceValue } from "../../utils/helper";

const selectTypeColumn = (item, type) => {
  switch (type) {
    case "whatsapp":
      return item ? (
        <a
          href={`https://api.whatsapp.com/send/?phone=${item}`}
          target="_blank"
        >
          <img src={IconWhatsapp} alt="Whatsapp" />
        </a>
      ) : (
        "-"
      );
    case "tooltip":
      return <img src="../../assets/icons/icon-details.svg" />;
    case "name":
      return replaceValue(item.toLowerCase());
    case "value":
      return new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL"
      }).format(item);
    default:
      return item;
  }
};

const Table = ({
  columns,
  data,
  options,
  pagesTotal,
  currentPage,
  setCurrentPage
}) => {
  const token = decodedToken(verifyToken());

  const getPaginationGroup = () => {
    const start = Math.floor((currentPage - 1) / 5) * 5;

    if (start + 5 > pagesTotal) {
      return new Array(pagesTotal - start)
        .fill()
        .map((_, idx) => start + idx + 1);
    }

    return new Array(5).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div>
      <TableContainer>
        <thead>
          <tr>
            {columns &&
              columns.map((item) => <th key={item.title}>{item.title}</th>)}
            {options && options.map((opt) => <th>{opt.title}</th>)}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((item, index) => (
              <tr key={index}>
                {columns &&
                  columns.map((column, idx) => (
                    <td
                      key={idx}
                      className={
                        typeof column.className === "function"
                          ? column.className(item)
                          : null
                      }
                    >
                      {selectTypeColumn(item[column.key], column.type)}
                    </td>
                  ))}
                {options &&
                  options.map((opt) => (
                    <td
                      style={{ cursor: "pointer", fontWeight: "bold" }}
                      {...opt.props}
                      onClick={() => opt.props.onClick(item)}
                    >
                      {opt.content(item)}
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </TableContainer>
      {pagesTotal && pagesTotal > 0 ? (
        <PaginationContainer>
          <tr>
            <td colSpan={data.length}>
              <PaginationTable>
                <PaginationButtonArrow
                  onClick={
                    currentPage > 1
                      ? () => setCurrentPage(currentPage - 1)
                      : () => {}
                  }
                >
                  <img src={IconArrowLeft} alt="Voltar" />
                </PaginationButtonArrow>
                {currentPage > 5 ? (
                  <>
                    <PaginationButton
                      active={Number(1) === Number(currentPage)}
                      onClick={() => setCurrentPage(1)}
                    >
                      1
                    </PaginationButton>
                    <span>...</span>
                  </>
                ) : null}

                {getPaginationGroup().map((item) => (
                  <PaginationButton
                    active={Number(item) === Number(currentPage)}
                    onClick={() => setCurrentPage(item)}
                  >
                    {item}
                  </PaginationButton>
                ))}
                {!getPaginationGroup().includes(pagesTotal) ? (
                  <>
                    <span>...</span>
                    <PaginationButton
                      active={Number(pagesTotal) === Number(currentPage)}
                      onClick={() => setCurrentPage(pagesTotal)}
                    >
                      {pagesTotal}
                    </PaginationButton>
                  </>
                ) : null}
                <PaginationButtonArrow
                  className="arrow-right"
                  onClick={
                    currentPage < pagesTotal
                      ? () => setCurrentPage(currentPage + 1)
                      : () => {}
                  }
                >
                  <img src={IconArrowLeft} alt="Ir em frente" />
                </PaginationButtonArrow>
              </PaginationTable>
            </td>
          </tr>
        </PaginationContainer>
      ) : null}
    </div>
  );
};

export default Table;
