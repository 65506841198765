import axios from 'axios';
import { config } from '../config/config';
import { APP_LOGIN_HASH, APP_LOGIN_HASH_FAILURE } from '../constants/actionTypes';

export const fetchLoginAction = (user, password, idEmpresa) => {
    let cnpj;
    let email;
    if (!isNaN(user)) {
        cnpj = user.padStart(14, 0);
    }
    else { email = user }
    return (dispatch) => (
        axios.post(`${config.urlApi}/Autenticacao/autenticar`, { cnpj, email, senha: password, idEmpresa: idEmpresa })
            .then(res => (
                dispatch({
                    type: APP_LOGIN_HASH,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: APP_LOGIN_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}