import React, { Component, cloneElement } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import SVG from "webmotors-svg";
import { IconSantanderFlame } from "../assets";

export const Tab = (props) => {
  const { className, label, isActive, type, onClick } = props;
  const tabClass = `tabs__tab ${className}`;
  let linkClass;

  if (type === "header") {
    linkClass = isActive
      ? "tabs__tab-link-header--active"
      : "tabs__tab-link-header";
  } else {
    linkClass = isActive ? "tabs__tab-link--active" : "tabs__tab-link";
  }

  return (
    <li className={tabClass}>
      <a className={linkClass} onClick={onClick}>
        {label}
      </a>
    </li>
  );
};

export const TabIcon = (props) => {
  const { title, points, isActive, onClick, icon, currentyCategory } = props;
  const tabClass = `tabs__tab`;
  const linkClass = isActive
    ? `tabs__tab-link-icon--active`
    : "tabs__tab-link-icon";

  return (
    <li className={`${tabClass} ${linkClass}`}>
      <a onClick={onClick}>
        <div className={`tabs__tab-icon ${icon}`}>
          <SVG src={IconSantanderFlame} />
          {currentyCategory && (
            <span className={`tabs__tab-icon__currenty-category`} />
          )}
        </div>
        <p>{title}</p>
        <span>
          {points && points.includes("-999999")
            ? `+ ${points.split("-999999").join("")}`
            : points}
        </span>
      </a>
    </li>
  );
};

export class Tabs extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: props.defaultActiveIndex || 0 };
  }

  handleTabClick = (tabIndex) => {
    if (tabIndex !== this.state.activeIndex) {
      this.setState({ activeIndex: tabIndex });
    }
  };

  cloneTabElement = (tab, index = 0) => {
    const { activeIndex } = this.state;

    return cloneElement(tab, {
      onClick: () => this.handleTabClick(index),
      tabIndex: index,
      isActive: index === activeIndex
    });
  };
  renderChildrenTabs = () => {
    const { children } = this.props;

    if (!Array.isArray(children)) {
      return this.cloneTabElement(children);
    }

    return children.map(this.cloneTabElement);
  };
  renderActiveTabContent() {
    const { children } = this.props;
    const { activeIndex } = this.state;

    if (children[activeIndex]) {
      return children[activeIndex].props.children;
    }

    return children.props && children.props.children;
  }
  render() {
    return (
      <>
        <ul className={`tabs__list ${this.props.type}`}>
          {this.props.titleDescript && this.props.description && (
            <div className="tabs__description">
              {this.props.titleDescript && <h3>{this.props.titleDescript}</h3>}
              {this.props.description && (
                <p className="description-p">{this.props.description}</p>
              )}
            </div>
          )}
          {this.renderChildrenTabs()}
        </ul>
        <div className="tabs__content">{this.renderActiveTabContent()}</div>
      </>
    );
  }
}

Tabs.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  type: PropTypes.string,
  defaultActiveIndex: PropTypes.string,
  titleDescript: PropTypes.string,
  description: PropTypes.string
};

Tab.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

TabIcon.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  points: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  isActive: PropTypes.bool,
  currentyCategory: PropTypes.bool,
  onClick: PropTypes.func
};

hot(module)(Tabs, Tab, TabIcon);
