import React from "react";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import { MainContext } from "../../App/context";
import { fetchRedeemBenefitsAction } from "../../actions/fetchRedeemBenefitsAction";
import { fetchTokenAction } from "../../actions/fetchTokenAction";
import { verifyNextCategory } from "../../utils/helper";
import { decodedToken, verifyToken } from "../../utils/utils";
import {
  CurrentCategory,
  CategoryRescue,
  RescueListItem,
  RescueListItemCampaign,
  BePlatinum,
  Card,
  CategoryIcon,
  ScoreBar
} from "../../components";
import PropTypes from "prop-types";
import moment from "moment";
import PageTitle from "../../components/PageTitle";

class Rescue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      benefitsToRescueReset: false,
      campaingSelected: { campanhas: [] },
      benefitsToRescueSelected: { beneficios: [] },
      refactory: { campanhas: [] },
      rescueItems: { beneficios: [] },
      rescueButton: false,
      rescueButtonCampaign: false
    };
    this.onHandleSelectCampaign = this.onHandleSelectCampaign.bind(this);
    this.handleRedeemBenefits = this.handleRedeemBenefits.bind(this);
  }

  // getAllData() {
  //   console.log('this.context.state before:', this.context.state)
  //  this.props.dispatch(fetchTokenAction())
  //     .then((e) => {
  //         const objectEntries = Object.entries(e.payload);
  //         this.context.state.periodoResgateAtivo = e.payload.periodoResgateAtivo
  //         objectEntries.map(obj => {
  //             this.context.state[obj[0]] = obj[1];
  //         })

  //         console.log('this.context.state after:', this.context.state)
  //     });
  // }

  showAlert(message, type = "error", title = "DESCULPE", url = "") {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { message: message, showAlert: true, type: type, title, url }
    });
  }

  // Function that captures user interaction in benefits listing and updates status
  onChangeItem = (e, benefits) => {
    let currentBenefits;
    let refactoryBenefits = [];

    const { beneficios } = this.state.rescueItems;

    currentBenefits = beneficios.filter(
      (element) => element.idBeneficio === benefits.idBeneficio
    );
    if ((!currentBenefits || currentBenefits.length === 0) && e) {
      if (!benefits) benefits = [];
      beneficios.push(benefits);
    } else {
      let index = beneficios.indexOf(benefits);
      if (index > -1) beneficios.splice(index, 1);
    }

    beneficios.map((item) => refactoryBenefits.push(item));

    if (refactoryBenefits.length > 0) this.setState({ rescueButton: true });
    else this.setState({ rescueButton: false });

    this.setState({
      rescueItems: {
        beneficios: refactoryBenefits
      }
    });
  };

  // Function that captures user interaction in campaign listing and updates status
  onHandleSelectCampaign(e, campaing) {
    let currentCampaign;
    let refactoryCampaing = [];
    const { campanhas } = this.state.campaingSelected;

    currentCampaign = campanhas.filter(
      (element) => element.idCampanha === campaing.idCampanha
    );
    if ((!currentCampaign || currentCampaign.length === 0) && e) {
      if (!campaing) campaing = [];
      campanhas.push(campaing);
    } else {
      let index = campanhas.indexOf(campaing);
      if (index > -1) {
        campanhas.splice(index, 1);
      }
    }

    campanhas.map((item) => {
      refactoryCampaing.push({
        idCampanha: item.idCampanha,
        idApuracao: item.idApuracao
      });
    });

    if (refactoryCampaing.length > 0)
      this.setState({ rescueButtonCampaign: true });
    else this.setState({ rescueButtonCampaign: false });

    this.setState({
      refactory: {
        campanhas: refactoryCampaing
      }
    });
  }

  // Role responsible for sending bene fit and campaign redemptions
  handleRedeemBenefits() {
    let itensToRescue = [];
    let itensBenefitsFiltered = [];
    const { refactory, rescueItems } = this.state;
    const { dispatch } = this.props;

    const result = decodedToken(verifyToken());

    rescueItems.beneficios.map((item) =>
      itensBenefitsFiltered.push(item.idBeneficio)
    );
    itensToRescue.push({
      idEmpresa: result.IdEmpresa,
      campanhas: refactory.campanhas,
      beneficios: itensBenefitsFiltered
    });

    dispatch(fetchRedeemBenefitsAction(itensToRescue[0]))
      .then((e) => {
        if (!e.type.includes("FAILURE")) {
          if (
            rescueItems.beneficios &&
            rescueItems.beneficios.find(
              (beneficio) =>
                beneficio.idBeneficio === 5 &&
                beneficio.valor !== undefined &&
                beneficio.valor !== "0.00"
            )
          ) {
            this.showAlert(
              `Seus benefícios foram resgatados com Sucesso! <br><br> Você receberá aproximadamente uma comissão de R$ ${
                rescueItems.beneficios.find(
                  (beneficio) => beneficio.idBeneficio === 5
                ).valor
              }`,
              "success",
              "PARABÉNS!",
              "/historico"
            );
          } else {
            this.showAlert(
              "Seus benefícios foram resgatados com Sucesso!",
              "success",
              "PARABÉNS!",
              "/historico"
            );
            this.setState({
              rescueButton: false,
              rescueButtonCampaign: false,
              refactory: { campanhas: [] },
              rescueItems: { beneficios: [] }
            });
            fetchTokenAction();
          }
        } else {
          this.showAlert(
            "Erro ao resgatar! Tente novamente ou consulte seu GR."
          );
        }
        dispatch();
      })
      .catch((error) =>
        console.error("TCL: Benefits -> handlerRedeemBenefits -> error", error)
      );
  }

  onRangeDateReference(current) {
    const startOfMonth = moment()
      .subtract("1", "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment()
      .subtract("1", "months")
      .endOf("month")
      .format("YYYY-MM-DD");

    if (moment(current).isBetween(startOfMonth, endOfMonth, null, "(]"))
      return true;
    else return false;
  }

  render() {
    const {
      categoriaAtual,
      classificacoesAnteriores,
      listaCriterios,
      beneficiosResgate,
      periodoResgateAtivo,
      campanhas
    } = this.context.state;
    const lastClassification =
      classificacoesAnteriores && classificacoesAnteriores.length
        ? classificacoesAnteriores[0]
        : {};
    const { rescueButton, rescueButtonCampaign } = this.state;
    const criterios = listaCriterios ? listaCriterios[0].criterios : [];

    return (
      <>
        <PageTitle>Resgate</PageTitle>
        <div className="wrapper__container template-grid-2-1 rescue">
          {/* Paineis */}
          {decodedToken(verifyToken()).AcaoPlatinum === "true" &&
          categoriaAtual &&
          categoriaAtual.idCategoria !== 4 ? (
            <div className="rescue__bePlatinum">
              <BePlatinum />
              <CategoryRescue
                criteria={listaCriterios}
                rescueCategory={lastClassification}
                metaDesafio={categoriaAtual && categoriaAtual.metaDesafio}
                showLinks={true}
              />
            </div>
          ) : (
            <CategoryRescue
              criteria={listaCriterios}
              rescueCategory={lastClassification}
              metaDesafio={categoriaAtual && categoriaAtual.metaDesafio}
              showLinks={true}
            />
          )}

          <div
            className={`container__second-child ${
              this.props.platinum.status === true
                ? "rescue__current-category rescue__current-category--platinum"
                : "rescue__current-category"
            }`}
          >
            {categoriaAtual && (
              <Card>
                <CategoryIcon
                  category={categoriaAtual.categoria}
                  text="CATEGORIA ATUAL"
                  lateral={true}
                  size="big"
                  active={true}
                />
                <ScoreBar
                  currentPoints={categoriaAtual.pontuacao}
                  maxPoints={categoriaAtual.pontuacaoMaxima}
                />
                {categoriaAtual.categoria !== "Platinum" ? (
                  <div className="current-category__message">
                    Faltam{" "}
                    <span>
                      {categoriaAtual.pontuacaoMaxima -
                        categoriaAtual.pontuacao +
                        1}{" "}
                    </span>
                    pontos para subir pra categoria{" "}
                    <span>{verifyNextCategory(categoriaAtual.pontuacao)}</span>
                  </div>
                ) : (
                  <div className="current-category__message">
                    <span>Parabéns! Você atingiu a categoria máxima.</span>
                  </div>
                )}
              </Card>
            )}
          </div>
        </div>

        <div className="template-grid-rescue">
          {/* Exibe os beneficios que não foram resgatado */}
          {this.props.platinum.status === false ? (
            <>
              {periodoResgateAtivo &&
                beneficiosResgate &&
                beneficiosResgate.filter(
                  (x) =>
                    x.resgatado === false &&
                    x.acaoPlatinum === false &&
                    this.onRangeDateReference(
                      moment(x.dataReferencia).format("YYYY-MM-DD")
                    )
                ).length > 0 && (
                  <>
                    <div className="rescue__title">
                      Benefícios disponível para resgate:
                    </div>
                    <RescueListItem
                      data={
                        periodoResgateAtivo &&
                        beneficiosResgate &&
                        beneficiosResgate.filter(
                          (x) =>
                            x.resgatado === false &&
                            x.acaoPlatinum === false &&
                            this.onRangeDateReference(
                              moment(x.dataReferencia).format("YYYY-MM-DD")
                            )
                        )
                      }
                      resgateEnabled={periodoResgateAtivo}
                      checked={false}
                      disabled={false}
                      readOnly={false}
                      handleClick={this.onChangeItem}
                    />
                  </>
                )}
            </>
          ) : (
            <>
              {periodoResgateAtivo &&
                beneficiosResgate &&
                beneficiosResgate.filter(
                  (x) =>
                    x.resgatado === false &&
                    this.onRangeDateReference(
                      moment(x.dataReferencia).format("YYYY-MM-DD") === true
                    ).length > 0
                ) && (
                  <>
                    <div className="rescue__title">
                      Benefícios disponível para resgate:
                    </div>
                    <RescueListItem
                      data={
                        periodoResgateAtivo &&
                        beneficiosResgate &&
                        beneficiosResgate.filter(
                          (x) =>
                            x.resgatado == false &&
                            this.onRangeDateReference(
                              moment(x.dataReferencia).format("YYYY-MM-DD")
                            ) === true
                        )
                      }
                      resgateEnabled={periodoResgateAtivo}
                      checked={false}
                      disabled={false}
                      readOnly={false}
                      handleClick={this.onChangeItem}
                    />
                  </>
                )}
            </>
          )}

          {/* Exibe os campanhas que não foram resgatados */}
          {campanhas &&
            campanhas.filter(
              (x) =>
                x.atingiu === true &&
                x.resgatado === false &&
                x.tipoExibicao !== 3 &&
                x.tipo !== 1
            ).length > 0 && (
              <>
                <div className="rescue__title">Benefícios de campanhas:</div>
                <RescueListItemCampaign
                  data={campanhas.filter(
                    (x) =>
                      x.atingiu === true &&
                      x.resgatado === false &&
                      x.tipoExibicao !== 3 &&
                      x.tipo !== 1
                  )}
                  checked={false}
                  disabled={false}
                  readOnly={false}
                  handleClick={this.onHandleSelectCampaign}
                />
              </>
            )}

          {(rescueButton || rescueButtonCampaign) && (
            <button
              onClick={this.handleRedeemBenefits}
              className="btn btn__rounded btn__red"
            >
              Resgatar Benefícios
            </button>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    platinum: state.bePlatinum
  };
};

Rescue.propTypes = {
  dispatch: PropTypes.func
};

Rescue.contextType = MainContext;

export default hot(module)(connect(mapStateToProps)(Rescue));
