import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import addDBList from "./setDbReducer";
import sendMessageAlert from "./sendMessageAlertReducer";
import fetchfetchUploadFile from "./fetchUploadFIleReducer";
import fetchRedeemBenefits from "./fetchRedeemBenefitsReducers";
import fetchLogin from "./fetchLoginReducer";
import fetchCreateAccount from "./fetchCreateAccountReducer";
import fetchHelp from "./fetchHelpReducer";
import fetchSendEmailToGRReducer from "./fetchSendEmailToGRReducer";
import changeRoutesReducer from "./changeRoutesReducer";
import fetchNewsReducer from "./fetchNewsReducer";
import fetchComplementReducer from "./fetchComplementReducer";
import bePlatinum from "./bePlatinumReducer";
import fetchPopupReducer from "./fetchPopupReducer";
import oportunityPanelReducer from "./oportunityPanelReducer";
import clubePlatinumReducer from "./clubePlatinumReducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    addDBList: addDBList,
    sendMessageAlert: sendMessageAlert,
    fetchfetchUploadFile: fetchfetchUploadFile,
    fetchRedeemBenefits: fetchRedeemBenefits,
    fetchLogin: fetchLogin,
    fetchCreateAccount: fetchCreateAccount,
    fetchHelp: fetchHelp,
    fetchSendEmailToGRReducer: fetchSendEmailToGRReducer,
    changeRoutesReducer: changeRoutesReducer,
    fetchNewsReducer: fetchNewsReducer,
    fetchComplementReducer: fetchComplementReducer,
    bePlatinum,
    popUps: fetchPopupReducer,
    oportunityPanel: oportunityPanelReducer,
    clubePlatinumReducer
  });

export default rootReducer;
