import Immutable from 'immutee';
import { INITIAL_STATE } from '../utils/utils';
import { GET_INFO_COMPLEMENT, 
         GET_INFO_COMPLEMENT_FAILURE, 
         SEND_COMPLEMENT_INFO, 
         SEND_COMPLEMENT_INFO_FAILURE,
         UPDATE_COMPLEMENT_INFO } from '../constants/actionTypes';

export default function fetchComplementReducer(state = INITIAL_STATE, action){
    const immutable = Immutable(state);
    switch (action.type) {
        case UPDATE_COMPLEMENT_INFO:
                return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .set('error', null)
                .done();
        case GET_INFO_COMPLEMENT: {
            return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .set('error', null)
                .done();
        }
        case GET_INFO_COMPLEMENT_FAILURE: {
            return immutable
                .set('error', action.payload)
                .set('result', [])
                .set('fetched', false)
                .done();
        }
        case SEND_COMPLEMENT_INFO: {
            return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .set('error', null)
                .done();
        }
        case SEND_COMPLEMENT_INFO_FAILURE: {
            return immutable
                .set('error', action.payload)
                .set('result', [])
                .set('fetched', false)
                .done();
        }
        default: {
            return state;
        }
    }
}