import React, { useState, useEffect, useContext } from 'react'
import Voucher from '../BirthDay/voucher'
import { MainContext } from '../../App/context';
import { connect } from 'react-redux';
import { fetchRedeemBenefitsAction } from '../../actions/fetchRedeemBenefitsAction';
import { fetchTokenAction } from '../../actions/fetchTokenAction';
import { decodedToken, verifyToken } from '../../utils/utils';
import PropTypes from 'prop-types'

const VoucherManager = ({dispatch}) => {

  const [isVoucherChile, setVoucherChile] = useState(false);
  const [campanhaSelected, setCampanhaSelected] = useState({})
  const context = useContext(MainContext)

  const showAlert = (message, type = 'error', title = 'DESCULPE', url = '') => {
    dispatch({
       type: 'ALERT_HASH',
       payload: { message: message, showAlert: true, type: type, title, url }
    });
  }

  const handleVoucherDictiory = {
    "chile": (isVisible)=> setVoucherChile(isVisible)
  }

  const getAllData = () => {
    this.props.dispatch(fetchTokenAction())
      .then((e) => {
          const objectEntries = Object.entries(e.payload);
          context.state.periodoResgateAtivo = e.payload.periodoResgateAtivo
          objectEntries.map(obj => {
              context.state[obj[0]] = obj[1];
          })
      });
    }

  const showVoucher = () => {
  const { campanhas } = context.state

      if(campanhas) {
         campanhas.map( item => {
            if(item.idCampanha === 1 && item.atingiu && !item.resgatado){
              setCampanhaSelected(item)
              handleVoucherDictiory['chile'](true)
            }
        })
      }
  };

  const handleClose = (item, tipo, isVisible) => {
    handleVoucherDictiory[tipo](isVisible);
    const result = decodedToken(verifyToken());
    const campanhas = [{idCampanha: item.idCampanha, idApuracao: item.idApuracao}];

    dispatch(fetchRedeemBenefitsAction({idEmpresa: result.IdEmpresa, beneficios:[], campanhas: campanhas}))
    .then(e => {
        if (!e.type.includes('FAILURE')) {
             getAllData();
             showAlert('Seu Voucher da libertadores foi resgatado com sucesso, logo entraremos em contato!!!', 'success', 'PARABÉNS!')
             dispatch(fetchTokenAction());
        }
    })
    .catch((error) => console.error("Resgate do Voucher", error))
  }

  useEffect(()=> {
    showVoucher()
  },[])

return (
      <>
        {isVoucherChile && <Voucher isVisible={isVoucherChile}
                                    urlImage="../../img/banner/voucher_chile.png"
                                    handleClick={()=> handleClose(campanhaSelected, "chile", false)} />}
      </>
  )
}

VoucherManager.propTypes = {
  dispatch: PropTypes.any
}

const mapStateToProps = (state) => {
  return { sendMessageAlertAction: state.sendMessageAlert }
}

export default connect(mapStateToProps)(VoucherManager)
