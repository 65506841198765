import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { isArray } from 'util';
const SLIDER_TIME = 1000000;

class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: this.props.index || 0,
            inter: 0,
        };
        this.id = 0;
        this.slideLength = this.props.children.length;
    }

    componentDidMount() {
        if (typeof this.props.onRef === 'function') {
            this.props.onRef(this)
        }
        // this.state.index === this.slideLength - 1 ? this.setState({ index: 0 }) : this.setState({
        //     index: ++this.state.index
        // });
    }

    componentWillUnmount() {
        clearInterval(this.state.inter);
    }

    handlePaginate(index) {
        this.setState({ index });
    }

    handleSliderNext = () => {
        let { index } = this.state;
        this.setState({ index: ++index });
    }

    handleSliderPrev() {
        let { index } = this.state;

        if (index > 0) {
            this.setState({ index: --index });
        }
    }

    verifyDisabledRules(index) {
        if (this.props.pagination === 'square') {
            return !(this.props.clickable && index <= this.state.index)
        } else { return this.props.clickable ? this.props.clickable : false }
    }

    render() {
        const { children } = this.props;
        if (!isArray(children)) {
            return <div>{children}</div>
        }
        const sliderItem = children.map(item => <div key={`item_${++this.id}`} className="slider__item" style={{ width: `${100 / this.slideLength}%` }}>{item.props.children}</div>);
        const sliderPages = children.map((item, i) =>
            <button
                key={`page_${++this.id}`}
                disabled={this.verifyDisabledRules(i)}
                className={`slider__page slider__page--${this.props.pagination}  ${
                    this.props.pagination
                        ? (i <= this.state.index ? 'slider__page--active' : '')
                        : (i === this.state.index ? 'slider__page--active' : '')
                    }`}
                onClick={() => this.handlePaginate(i)} />);
        return (
            <div className={`slider`}>
                <div
                    className="slider__content"
                    style={{
                        width: `${this.slideLength * 100}% `,
                        transform: `translateX(-${(100 / this.slideLength) * this.state.index}%)`,
                    }}
                >
                    {sliderItem}
                </div>
                {
                    !this.props.hideArrows &&
                    <div className="slider__nav" >
                        <button type="button" className={`slider__arrow slider__arrow--prev ${this.state.index === 0 ? 'slider__arrow--hide' : ''} `} onClick={() => this.handleSliderPrev()}>
                            &lt;
                    </button>
                        <button type="button" className={`slider__arrow slider__arrow--next ${this.state.index === this.slideLength - 1 ? 'slider__arrow--hide' : ''} `} onClick={() => this.handleSliderNext()}>
                            &gt;
                    </button>
                    </div>
                }
                { this.props.forceBack ? (
                    <div onClick={() => this.handleSliderPrev()} className={`slider__pagination ${this.props.stepsPosition ? this.props.stepsPosition : ''} `}>
                        <div style={{'pointer-events': 'none'}}>{sliderPages}</div>
                    </div>) : 
                    (<div className={`slider__pagination ${this.props.stepsPosition ? this.props.stepsPosition : ''} `}>
                        {sliderPages}
                    </div>)
                }
            </div>
        );
    }
}


Slider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]),
    stepsPosition: PropTypes.string,
    index: PropTypes.number,
    slideLength: PropTypes.number,
    hideArrows: PropTypes.bool,
    pagination: PropTypes.string,
    clickable: PropTypes.bool,
    onRef: PropTypes.func,
    forceBack: PropTypes.bool
};

export default hot(module)(Slider);
