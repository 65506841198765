import config from "../config/config";
import axios from "axios";

const api = axios.create({
  baseURL: config.urlApi,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("UserToken")}`,
    Pragma: "no-cache"
  }
});

export default api;
