import React, { Component } from 'react'
import { connect } from 'react-redux';
import { hot } from 'react-hot-loader';
import { Link } from 'react-router-dom';
import { MainContext } from '../App/context';
import { decodedToken, verifyToken } from '../utils/utils';
import PropTypes from 'prop-types';
import Card from './Card';
import CategoryIcon from './CategoryIcon';
import SVG from 'webmotors-svg';
import moment from 'moment'

class LastRating extends Component {
  changeRoute(route) {
    this.props.dispatch({
      type: 'CHANGE_ROUTE',
      payload: { route }
    })
  }

  onRangeDateReference(current) {
    const startOfMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().subtract(1, 'months').endOf('months').format('YYYY-MM-DD');

    if (moment(current).isBetween(startOfMonth, endOfMonth, null, '(]')) return true
    else return false
  }

  render() {
    const { EhLojista } = decodedToken(verifyToken());

    const { category, position, activeRedemptionPeriod, platinum, atual } = this.props;
    const { beneficiosResgate, campanhas, todosBeneficiosResgatados } = this.context.state;
    let benenfits = []
    let campaign = []

    if (platinum.status) {
      benenfits = beneficiosResgate ? beneficiosResgate.filter(item => !item.resgatado && this.onRangeDateReference(moment(item.dataReferencia).format('YYYY-MM-DD')) === true) : []
      campaign = campanhas ? campanhas.filter(item => !item.resgatado && item.atingiu === true && item.tipoExibicao !== 3 && item.tipo !== 1) : []
    } else {
      //      console.log('nao plat')
      benenfits = beneficiosResgate ? beneficiosResgate.filter(item => !item.resgatado && this.onRangeDateReference(moment(item.dataReferencia).format('YYYY-MM-DD')) === true && item.acaoPlatinum === false) : []
      campaign = campanhas ? campanhas.filter(item => !item.resgatado && item.atingiu === true && item.tipoExibicao !== 3 && item.tipo !== 1) : []
    }

    //    console.log('asdasd', benenfits)

    return (
      <div className='last-rating'>
        <Card>
          <div className='last-rating__icon'>
            {platinum.status === true ?
              <>
                <span className="last-rating__icon--opacity"><CategoryIcon category={category} size='small' active={false} /></span>
                <span className="last-rating__icon--active"><CategoryIcon category={'platinum'} size='small' active={false} /></span>
              </>
              :
              <CategoryIcon category={category} size='small' active={false} />
            }

            <div className='last-rating__wrapper'>
              <div className={`${platinum.status === true ? 'last-rating__message last-rating__message--platinum' : 'last-rating__message'}`}>
                {!atual ? (`SUA ÚLTIMA CLASSIFICAÇÃO - ${position} -`) : (`SUA CLASSIFICAÇÃO ATUAL -`)}
                {platinum.status === true ? (
                  <div className='last-rating__message--text-platinum'>
                    <span className='last-rating__message--bold last-rating__message--bold-tashed'>
                      <span className="last-rating__message--bold-opacity">
                        {category}
                      </span>
                    </span>
                    <span className='last-rating__message--bold'>PLATINUM</span>
                  </div>
                ) : (
                    <div className='last-rating__message--bold'>
                      {category}
                    </div>
                  )}

              </div>

              {
                EhLojista === "true" &&
                  activeRedemptionPeriod &&
                  campaign &&
                  benenfits &&
                  (benenfits.length > 0 || campaign.length > 0) === true &&
                  !todosBeneficiosResgatados ?
                  <Link to={'/resgate'} className='last-rating__link' disabled={!activeRedemptionPeriod} onClick={() => this.changeRoute('/resgate')}>
                    Resgate disponível <SVG disabled={!activeRedemptionPeriod} src={`../../../img/arrow_right.svg`} />
                  </Link>
                  : (
                    EhLojista === "true" &&
                      activeRedemptionPeriod &&
                      campaign &&
                      benenfits &&
                      todosBeneficiosResgatados ?
                      <div className="last-rating__unavailable">
                        Resgate já realizado
                      </div> :
                      <div className="last-rating__unavailable">
                        Resgate indisponível
                      </div>
                  )
              }
            </div>
          </div>
        </Card>
      </div >
    )
  }
}

LastRating.propTypes = {
  category: PropTypes.any,
  position: PropTypes.any,
  activeRedemptionPeriod: PropTypes.bool,
  dispatch: PropTypes.func
};

LastRating.contextType = MainContext;

function mapStateToProps(state) {
  return {
    changeRoutesReducer: state.changeRoutesReducer,
    platinum: state.bePlatinum
  }
}

export default hot(module)(connect(mapStateToProps)(LastRating));
