import axios from 'axios';
import { config } from '../config/config';
import { authHeader } from '../helpers/authHeader';
import { SEND_EMAIL_TO_GR, SEND_EMAIL_TO_GR_FAILURE } from '../constants/actionTypes';

export const fetchSendEmailToGRAction = (message) => {
    return (dispatch) => (
        axios.post(`${config.urlApi}/mensagemgr`, {
            idGerenteRelacionamento: message.idGerenteRelacionamento,
            emailDestinatario:message.emailDestinatario,
            assunto: message.assunto,
            corpo: message.corpo
        },  { headers: authHeader() })
            .then(res => (
                dispatch({
                    type: SEND_EMAIL_TO_GR,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: SEND_EMAIL_TO_GR_FAILURE,
                    payload: err
                })
            ))
    )
}