import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from "../../components/Alert";
import InputForm from "../../components/InputForm";
import { fetchNewPasswordAction } from "../../actions/fetchNewPassword";
import { setUpdateTokenPass } from "../../utils/utils";

class NewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { disableButton: true, enableForm: true };
  }

  componentDidMount() {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: {
        showAlert: true,
        type: "warning",
        title: "Alterar sua senha",
        message: "Você resetou sua senha, cadastre uma nova senha:"
      }
    });
  }

  changePass() {
    this.props
      .dispatch(
        fetchNewPasswordAction(
          document.getElementById("input__new-password").value
        )
      )
      .then(async (resp) => {
        if (resp.payload.sucesso) {
          await setUpdateTokenPass();
          await this.setState({ enableForm: false });
          this.handlerAlert(
            true,
            "Sua senha foi alterada com sucesso.",
            "success",
            "Perfeito!",
            "/home"
          );
        } else {
          this.handlerAlert(
            true,
            resp.payload.response.data.mensagem,
            "error",
            "Desculpe"
          );
        }
      })
      .catch((err) => {
        console.err(err);
      });
  }

  handlerAlert(showAlert, message, type, title, url) {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { showAlert, type, title, message, url }
    });
  }

  verifyForm() {
    let strongPassword = new RegExp(
      "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
    );

    const newPassword = document.getElementById("input__new-password").value;
    const confirmPassword = document.getElementById(
      "input__confirm-password"
    ).value;
    if (strongPassword.test(newPassword) && newPassword === confirmPassword) {
      this.setState({ disableButton: false });
    } else {
      this.setState({ disableButton: true });
    }
  }

  render() {
    return (
      this.state.enableForm && (
        <Alert>
          <form>
            <InputForm
              id="input__new-password"
              type="password"
              change={() => this.verifyForm()}
              placeholder="NOVA SENHA"
            />
            <InputForm
              id="input__confirm-password"
              type="password"
              change={() => this.verifyForm()}
              placeholder="CONFIRME SUA SENHA"
            />
            <button
              type="button"
              onClick={() => this.changePass()}
              className="btn btn__rounded btn__red"
              disabled={this.state.disableButton}
            >
              Alterar
            </button>
          </form>
          <div className="info__password">
            <h1>
              <b>A senha deve possuir os seguintes requisitos</b>
            </h1>
            <p>Pelo menos uma letra minúscula</p>
            <p>Pelo menos uma letra maiúscula</p>
            <p>Pelo menos um caractere especial</p>
            <p>Pelo menos um número</p>
            <p>Pelo menos 8 caracteres</p>
          </div>
        </Alert>
      )
    );
  }
}

NewPassword.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    sendMessageAlertAction: state.sendMessageAlert
  };
}

export default hot(module)(connect(mapStateToProps)(NewPassword));
