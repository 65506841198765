import styled from "styled-components";

export const WrapperSellersModal = styled.div`
  max-width: 430px;

  h1 {
    color: #2e2d37;
    font-size: 2.4rem;
    font-weight: 500;

    margin: 1.8rem 0;
  }
`;

export const WrapperStatusModal = styled.div`
  max-width: 430px;

  text-align: center;

  img {
    display: block;

    margin: auto;
  }

  .status-modal-icon {
    margin: 25px auto;
  }
`;

export const Label = styled.p`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};

  color: #2e2d37;

  margin: ${({ margin }) => margin};

  a{
    text-decoration: underline;
  }
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: center;

  margin: 14px 0;

  a {
    color: #2e2d37;
    text-decoration: underline;
  }

  img {
    margin-right: 5px;
  }
`;
