import axios from 'axios';
import { config } from '../config/config';
import { APP_NEW_PASSWORD_HASH, APP_NEW_PASSWORD_HASH_FAILURE } from '../constants/actionTypes';
import { authHeaderPassword } from '../helpers/authHeaderPassword';

export const fetchNewPasswordAction = (novaSenha) => {
    return (dispatch) => (
        axios.post(`${config.urlApi}/Autenticacao/trocarsenha`, {novaSenha}, {headers: authHeaderPassword()})
            .then(res => (
                dispatch({
                    type: APP_NEW_PASSWORD_HASH,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: APP_NEW_PASSWORD_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}