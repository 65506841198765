import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { MainContext } from "../../App/context";
import {
  setToken,
  AdobeLaunch,
  setAcesso,
  setTokenPassword
} from "../../utils/utils";
import { fetchLoginAction } from "../../actions/fetchLoginAction";
import SVG from "webmotors-svg";

import Card from "../../components/Card";
import NewPassword from "./NewPassword";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableButton: true,
      enableNextButton: true,
      enablePasswordButton: false,
      passwordInputType: "password",
      login: "",
      password: "",
      showNewPassword: false,
      maxLength: 100,
      idEmpresa: 0
    };
  }

  componentDidMount() {
    /* Limpa o registro dos alertar para exibi-los novamente */
    // localStorage.setItem('popUpBonusShow', true);
    if (this.props.location.search.includes("error")) {
      this.showAlert(
        "Favor entre em contato com seu GR e tente novamente mais tarde.",
        "Error",
        "Erro interno!"
      );
    }
  }

  handleLogin(e) {
    e.preventDefault();
    AdobeLaunch("Evento disparado quando o usuário realiza login");
    this.props
      .dispatch(
        fetchLoginAction(
          this.state.login,
          this.state.password,
          this.state.idEmpresa
        )
      )
      .then((resp) => {
        if (resp.payload.objeto.trocarSenha) {
          setTokenPassword(resp.payload.objeto.authToken);

          console.log("PASSOU");
          return this.renderNewPassword();
        }
        if (resp.payload.sucesso) {
          setToken(resp.payload.objeto.authToken);
          setAcesso(resp.payload.objeto.primeiroAcesso);

          console.log(resp.payload);
          if (resp.payload.objeto.primeiroAcesso) {
            return (location.href = "/informacoes-complementares");
          }
          return (location.href = "/home");
        } else {
          this.showAlert(
            "Seu email/cnpj ou senha estão incorretos.",
            "error",
            "Desculpe"
          );
        }
      })
      .catch((err) => {
        this.showAlert(
          "Seu email/cnpj ou senha estão incorretos.",
          "error",
          "Desculpe"
        );
      });
  }

  renderNewPassword() {
    this.setState({ showNewPassword: true });
  }

  showAlert(message, type, title) {
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { message: message, showAlert: true, type, title }
    });
  }

  verifyForm(isInputValid) {
    if (isInputValid[1]) {
      return this.setState({ enableNextButton: false });
    }
    if (isInputValid.includes("success")) {
      this.setState({ enableButton: false });
    } else {
      this.setState({ enableButton: true });
    }
  }

  validateInput(e, input, button) {
    let showButton;
    if (e.target.value !== "") {
      showButton = false;
    } else {
      showButton = true;
    }
    if (input === "login") {
      if (isNaN(e.target.value)) {
        this.setState({ maxLength: 100 });
      } else {
        this.setState({ maxLength: 14 });
      }
    }
    this.setState({ [input]: e.target.value, [button]: showButton });
  }

  changeInputType() {
    this.state.passwordInputType === "password"
      ? this.setState({ passwordInputType: "text" })
      : this.setState({ passwordInputType: "password" });
  }

  nextStep(event, child) {
    event.preventDefault();
    if (document.getElementById("input__cnpj").value === "") {
      return this.showAlert(
        "Favor inserir seu cnpj ou email.",
        "warning",
        "Ops!"
      );
    }
    setTimeout(() => document.getElementById("input__password").focus());
    child.handleSliderNext();
  }

  render() {
    const { width } = this.context.state;
    return (
      <div>
        <div className="container bg__full-page v-align login">
          <div className="login__card">
            <Card color={"clean"} className="unlogged">
              <div className="login__container">
                <div className="responsive__form v-align">
                  <div className="login__form">
                    <div className="login__form__body">
                      <SVG
                        className="login__svg"
                        src="../../../img/svg/str-colored-fidelidade-white.svg"
                      />
                      <form onSubmit={(e) => this.handleLogin(e)}>
                        <div className="input">
                          <label className={`form-control`}>
                            <input
                              key={"input__cnpj"}
                              id={"input__cnpj"}
                              type={"text"}
                              value={this.state.login}
                              placeholder={"Insira seu E-mail ou CNPJ"}
                              maxLength={this.state.maxLength}
                              onChange={(e) =>
                                this.validateInput(
                                  e,
                                  "login",
                                  "enableNextButton",
                                  "input__cnpj"
                                )
                              }
                            />
                            <span className={`border`} />
                          </label>
                        </div>
                        <div className="input">
                          <label className={`form-control `}>
                            <input
                              key={"input__password"}
                              id={"input__password"}
                              value={this.state.password}
                              type={this.state.passwordInputType}
                              placeholder={"Insira sua senha"}
                              maxLength={100}
                              onChange={(e) =>
                                this.validateInput(
                                  e,
                                  "password",
                                  "enablePasswordButton",
                                  "input__password"
                                )
                              }
                            />
                            <span className={`border`} />
                            <span
                              onClick={() => this.changeInputType()}
                              className={`input__icon input__icon__eye ${this.state.passwordInputType}`}
                            >
                              <SVG
                                src={require("../../../src/img/svg/str-colored-icon-eye.svg")}
                              />
                            </span>
                          </label>
                        </div>
                        <button
                          disabled={this.state.enablePasswordButton}
                          className="btn btn__red btn__rounded"
                          type="submit"
                        >
                          Entrar
                        </button>
                      </form>
                      <span className="login__recovery-password">
                        <Link to={"recuperar-senha"}>Esqueci Minha Senha</Link>
                      </span>
                      <span className="login__link">
                        Você ainda não possui login? <br /> Faça já seu{" "}
                        <Link
                          to={`${
                            width <= 768 ? "criar-conta/criar" : "criar-conta"
                          }`}
                        >
                          CADASTRO!
                          <SVG
                            src={require("../../../src/img/svg/str-white-arrow-right.svg")}
                          />
                        </Link>
                      </span>
                      {width <= 768 && (
                        <SVG
                          className="login__santander-logo"
                          src={require("../../../src/img/svg/Logo_Santander-financiamentos.svg")}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {width >= 768 && (
          <SVG
            className="login__santander-logo"
            src={require("../../../src/img/svg/Logo_Santander-financiamentos.svg")}
          />
        )}
        {this.state.showNewPassword && <NewPassword />}
      </div>
    );
  }
}

Login.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func,
  navigation: PropTypes.object
};

function mapStateToProps(state) {
  return {
    sendMessageAlertAction: state.sendMessageAlert
  };
}

Login.contextType = MainContext;

export default connect(mapStateToProps)(Login);
