import Immutable from 'immutee';
import { INITIAL_STATE } from '../utils/utils';
import { APP_LOGIN_HASH, APP_LOGIN_HASH_FAILURE } from '../constants/actionTypes';

export default function fetchLogin(state = INITIAL_STATE, action){
    const immutable = Immutable(state);
    switch (action.type) {
        case APP_LOGIN_HASH: {
            return immutable
                .set('result', action.payload)
                .set('fetched', true)
                .set('error', null)
                .done();
        }
        case APP_LOGIN_HASH_FAILURE: {
            return immutable
                .set('error', action.payload)
                .set('result', [])
                .set('fetched', false)
                .done();
        }
        default: {
            return state;
        }
    }
}