export { default as IconArrowBack } from "./icons/icon-arrow-back.svg";
export { default as IconArrowLeft } from "./icons/icon-arrow-left.svg";
export { default as IconWhatsapp } from "./icons/icon-whatsapp.svg";
export { default as IconDetails } from "./icons/icon-details.svg";
export { default as IconCorrect } from "./icons/icon-correct.svg";
export { default as IconSantanderFlame } from "./icons/icon-santander-flame.svg";

export { default as IconChevronLeft } from "./icons/icon-chevron-left.svg";
export { default as IconChevronRight } from "./icons/icon-chevron-right.svg";
export { default as IconSellers } from "./icons/icon-sellers.svg";
export { default as IconMaisFidelidade } from "./icons/icon-mais-fidelidade.svg";
export { default as IconPaperClip } from "./icons/icon-paper-clip.svg";

export { default as IconSuccess } from "./icons/icon-success.svg";
export { default as IconError } from "./icons/icon-error.svg";
export { default as IconCrossCircle } from "./icons/icon-cross-circle.svg";
export { default as IconArrow } from "./icons/icon-arrow-back.svg";

export { default as LogoClubePlatinum } from "./images/logo-clube-platinum.png";
export { default as LogoClubePlatinumWhite } from "./images/logo-clube-platinum-white.png";

export { default as IconPlatinum } from "./icons/icon-platinum.png";
export { default as IconQuestion } from "./icons/icon-question.svg";
export { default as IconArrowDown } from "./icons/icon-arrow-down.svg";
