
import React from 'react'
import PropTypes from 'prop-types'

const Voucher = ({isVisible, handleClick, urlImage}) => (
      <div className={`popup-voucher ${!isVisible ? 'popup-voucher--close' : ''}`}  onClick={handleClick}>
        <div className="popup-voucher__bg">
          <div className="popup-voucher__box">
            <div className="popup-voucher__content">
              <img src={urlImage} alt="" />
            </div> 
          </div> 
        </div> 
      </div>      
  )

  Voucher.propTypes = {
    isVisible: PropTypes.bool,
    handleClick: PropTypes.func,
    urlImage: PropTypes.string
  }

export default Voucher