export function verifyNextCategory(currentCategoryPoints) {
  if (currentCategoryPoints <= 299) {
    return "Silver";
  } else if (currentCategoryPoints <= 749) {
    return "Gold";
  } else if (currentCategoryPoints <= 899) {
    return "Platinum";
  } else return "";
}

export function replaceValue(string, type) {
  const value = [...string]
    .filter((_, i) => i < 2 || i > string.length - 3)
    .join("");

  return type === "cpf"
    ? `${value.substring(0, 2)}*.***.***-${value.substring(2, value.length)}`
    : `${value.substring(0, 2)}*********`;
}
