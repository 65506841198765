import styled, { keyframes } from "styled-components";

const themes = (theme) => {
  switch (theme) {
    case "border":
      return {
        backgroundColor: "transparent",
        border: "1px solid #F3123C",
        color: "#F3123C",
        backgroundHover: "#C1062B"
      };
    case "disabled":
      return {
        backgroundColor: "#C9C9CF",
        backgroundHover: "#C9C9CF"
      };
    case "green":
      return {
        backgroundColor: "#1CB0B9",
        backgroundHover: "#18A2AF",
        color: "#FFF"
      };
    default:
      return {
        backgroundColor: "#F3123C",
        border: "1px solid #F3123C",
        color: "#FFF",
        backgroundHover: "#C1062B"
      };
  }
};

export const ButtonContainer = styled.button`
  padding: 1.1rem 2rem;

  font-family: Poppins, sans-serif;

  background-color: ${({ theme }) => themes(theme).backgroundColor};
  border-radius: 8px;
  color: ${({ theme }) => themes(theme).color};
  border: ${({ theme }) => themes(theme).border};

  transition: 0.2s;

  max-height: 44px;
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};

  margin: ${({ margin }) => margin};

  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: ${themes("disabled").backgroundColor} !important;
    cursor: not-allowed;
    color: #fff;
    border-color: ${themes("disabled").backgroundColor} !important;

    &:hover {
      background-color: ${themes("disabled").backgroundHover} !important;
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      themes(theme).backgroundHover} !important;
    border-color: ${({ theme }) => themes(theme).backgroundHover} !important;
    color: #fff;
  }

  & + button {
    margin-left: 24px;
  }

  a {
    text-decoration: none;
    color: inherit;
    height: 44px;
    width: 100%;

    display: flex;
    align-items: center;
  }
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerContainer = styled.div`
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid ${({ theme }) => themes(theme).backgroundColor};
  width: 20px;
  height: 20px;
  -webkit-animation: ${spin} 2s linear infinite;
  animation: ${spin} 2s linear infinite;
`;
