import React, { useEffect, useState } from "react";
import { TermsPopUp } from "..";
import SellersModal from "../SellersModal";
import LavagemPopUp from "../LavagemPopUp";

import { setPopupsVisualized } from "../../requests/popups";

import Terms from "../../img/banner_codigo_de_conduta-v2.png";
import Plus from "../../img/bg-mais-detalhe.png";
import Lavagem from "../../img/banner/popup_lavagem.png";
import Oportunidade from "../../img/popup_painel_oportunidade.png";
import ClubePlatinumModal from "../ClubePlatinumModal";
import LendingPopup from "../LendingPopup";

const HandlePopups = ({ listPopups }) => {
  const [selected, setSelected] = useState({});
  const [readPopups, setReadPopups] = useState([]);

  const [loading, setLoading] = useState(false);
  const [buttonClose, setButtonClose] = useState(false);

  const getSelectedPopup = () => {
    if (listPopups) {
      const arr = listPopups.filter(
        (item) => !readPopups.includes(item.idPopup)
      );

      if (arr && arr.length > 0) {
        setSelected(arr[0]);
      } else {
        setSelected({});
      }
    }

    setButtonClose(false);
  };

  const onClickClose = () => {
    setButtonClose(true);
    setReadPopups([...readPopups, selected.idPopup]);
  };

  const sendPopupVisualized = (accept) => {
    setLoading(true);

    setPopupsVisualized(selected.idPopup, accept).then(() => {
      setLoading(false);

      setReadPopups([...readPopups, selected.idPopup]);
    });
  };

  useEffect(() => {
    if (selected.idPopup !== 9 || buttonClose) getSelectedPopup();
  }, [readPopups]);

  useEffect(() => {
    getSelectedPopup();
  }, [listPopups]);

  const createMarkup = (element) => {
    return { __html: element };
  };

  const getPopupsType = () => {
    switch (selected.idPopup) {
      case 9:
        return (
          <SellersModal
            loading={loading}
            onClickResponse={sendPopupVisualized}
            onClickExit={getSelectedPopup}
            onClickClose={onClickClose}
            open={true}
          />
        );
      case 10:
        return (
          <ClubePlatinumModal
            open
            onClickStart={() => sendPopupVisualized(true)}
          />
        );
      case 8:
        return (
          <LavagemPopUp onClose={() => sendPopupVisualized(selected.idPopup)}>
            <div>
              <img className="modal__bgDetalhe" src={Plus} alt="+ Fidelidade" />
              <img
                src={Lavagem}
                style={{ width: "400px", height: "auto" }}
                alt="Lavagem"
              />
            </div>
          </LavagemPopUp>
        );
      case 11:
        return (
          <LavagemPopUp onClose={() => sendPopupVisualized(selected.idPopup)}>
            <div>
              <img
                src={Oportunidade}
                className="modal__oportunidade"
                alt="oportunidade"
              />
            </div>
          </LavagemPopUp>
        );
      case 7:
        return (
          <TermsPopUp>
            <div>
              <a
                href={selected.linkArquivo}
                target="_BLANK"
                rel="noopener noreferrer"
                onClick={() => sendPopupVisualized(selected.idPopup)}
              >
                <img
                  className="modal__bgDetalhe"
                  src={Plus}
                  alt={"+ Fidelidade"}
                />
                <img
                  src={Terms}
                  style={{ width: "960px", height: "auto" }}
                  alt={selected.nome}
                />
              </a>
              <div
                className="modal__subTitle"
                dangerouslySetInnerHTML={createMarkup(selected.descricao)}
              />
            </div>
          </TermsPopUp>
        );
      case 12:
        return (
          <LendingPopup
            onClickClose={() => sendPopupVisualized(selected.idPopup)}
            open={true}
          />
        );

      default:
        return null;
    }
  };

  return <div>{getPopupsType()}</div>;
};

export default HandlePopups;
