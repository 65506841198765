import React from 'react'
import { connect } from 'react-redux'
import Card from '../Card'
import Checkbox from '../Checkbox'
import moment from 'moment'
import PropTypes from 'prop-types'

const RescueListItem = ({data, handleClick,  rescueEnabled, readOnly, ...props}) => {
  return (
    <>
      {data && data.map(item => (
        <div className={`rescue-item ${(item.acaoPlatinum === true && props.platinum.status === true) ? 'rescue-item__platinum' : ''}`} key={item.idBeneficio}>
          <Card  className="rescue-item__title" title={item.nome ? item.nome : ''}>
              {item.acaoPlatinum === true && props.platinum.status === true && (
                <div className="rescue-item__platinum-identify">
                  Exclusivo Platinum
                </div>
              )}
              <div className="rescue-item__body">{item.descricao ? item.descricao : ''}</div>
              <div className="rescue-item__option">
                  {readOnly == false && <Checkbox
                      id={item.nome ? item.nome : ''}
                      disabled={false}
                      checked={item.resgatado || (rescueEnabled && item.length > 0 && item.filter(x => x.idBeneficioResgate === item.idBeneficioResgate).length !== 0)}
                      change={(...e) => handleClick(...e, item)} />}
              </div>
              {item.resgatado && (
                  <>
                    {item.link ? (
                      <div className="rescue-item__download">
                        <a className="btn btn__red btn__rounded" href={item.link ? item.link : '#'} download rel="noopener noreferrer" target="_blank">download</a>
                      </div>
                    ) : (
                        <div className="rescue-item__data">Resgatado em: <strong>{moment(item.dataResgate).format('DD/MM/YYYY')}</strong></div>
                    )}
                  </>
                )
              }
          </Card>
      </div>
      ))}
    </>
  )
}

RescueListItem.propTypes = {
  data: PropTypes.array,
  handleClick: PropTypes.func,
  rescueEnabled: PropTypes.bool,
  readOnly: PropTypes.bool,
}

const mapStateToProps = state => ({
  platinum: state.bePlatinum
});

export default connect(mapStateToProps, null)(RescueListItem)
