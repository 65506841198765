import axios from "axios";
import { config } from "../config/config";
import { authHeader } from "../helpers/authHeader";

export const fetchPopupAction = () => {
  return (dispatch) =>
    axios
      .get(`${config.urlApi}/Popups`, { headers: authHeader() })
      .then((res) =>
        dispatch({
          type: "@POPUPS/FetchPopUps",
          payload: res.data
        })
      )
      .catch((err) =>
        dispatch({
          type: "@POPUPS/FetchPopUpsError",
          payload: err
        })
      );
};
