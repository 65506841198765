import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';

class Tipfy extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {
        const _tag = this._reactInternalFiber.child.stateNode;
        _tag.addEventListener('mouseover', e => {
            this.tag = e.target;
            this.remove();
            this.build();
        });
        _tag.addEventListener('mouseout', e => {
            this.remove();
        });
    }
    remove() {
        try {
            document.body.removeChild(document.querySelector('.tipfy'))
            document.querySelector('.tipfy').remove();
        } catch (e) { }
    }
    build() {
        const _tip = this.props.text;
        let _html;
        try {
            _html = this.tag ? _tip : document.querySelector(_tip).outerHTML;
        } catch (e) {
            _html = _tip;
        }
        _html = _html.replace(/<script/g, '&lt;script').replace(/<\/script/g, '&lt;\/script');
        document.body.insertAdjacentHTML('beforeend', `<div class="tipfy" role="tooltip">${_html}</div>`);
        this.side();
    }
    side() {
        const _tipfy = document.querySelector('.tipfy'),
            _rect = this.tag.getBoundingClientRect(),
            _top = window.pageYOffset,
            _left = window.pageXOffset,
            _side = this.props.side;
        if (!_side) {
            const _xLeft = _rect.left < window.outerWidth / 2,
                _yTop = _rect.top < window.outerHeight / 2;
            if (window.innerWidth < 1024) {
                _tipfy.classList.add(`tipfy--${_yTop ? 'top' : 'bottom'}`);
                _tipfy.classList.add('tipfy--side-auto');
                _tipfy.style.left = (_rect.left + (_rect.width - _tipfy.clientWidth) / 2 + _left) + 'px';
                _tipfy.style.top = ((_yTop ? _rect.top + _rect.height : _rect.top - _tipfy.clientHeight) + _top) + 'px';
            } else {
                _tipfy.classList.add(`tipfy--${_xLeft ? 'left' : 'right'}`);
                _tipfy.classList.add(`tipfy--${_yTop ? 'top' : 'bottom'}`);
                _tipfy.style.left = (_xLeft ? _rect.left : _rect.left + _rect.width - _tipfy.clientWidth) + _left + 'px';
                _tipfy.style.top = (_yTop ? _rect.top + _rect.height : _rect.top - _tipfy.clientHeight) + _top + 'px';
            }
        } else {
            const position = {
                left: () => _tipfy.setAttribute('style', `left: ${_rect.left - _tipfy.clientWidth + _left}px;top: ${_rect.top + (_rect.height - _tipfy.clientHeight) / 2 + _top}px;`),
                right: () => _tipfy.setAttribute('style', `left: ${_rect.left + _rect.width + _left}px;top: ${_rect.top + (_rect.height - _tipfy.clientHeight) / 2 + _top}px;`),
                top: () => _tipfy.setAttribute('style', `left: ${_rect.left + (_rect.width - _tipfy.clientWidth) / 2 + _left}px;top: ${_rect.top - _tipfy.clientHeight + _top}px;`),
                bottom: () => _tipfy.setAttribute('style', `left: ${_rect.left + (_rect.width - _tipfy.clientWidth) / 2 + _left}px;top: ${_rect.top + _rect.height + _top}px;`)
            }
            position[_side]();
            _tipfy.classList.add(`tipfy--side-${_side}`);
        }
        if (this.props.className) {
            this.props.className.split(' ').map(item => _tipfy.classList.add(item));
        }
        if (this.props.style) {
            Object.entries(this.props.style).map(item => _tipfy.style[item[0]] = item[1]);
        }
    }
    render() {
        return this.props.children;
    }
}

Tipfy.propTypes = {
    children: PropTypes.element,
    className: PropTypes.string,
    text: PropTypes.string,
    side: PropTypes.string,
    style: PropTypes.string

};

export default hot(module)(Tipfy);