import React, { useEffect, useState } from "react";
import { IconArrow, IconWhatsapp } from "../../assets";
import { getDetailsOportunity } from "../../requests/oportunityPanel";
import { replaceValue } from "../../utils/helper";
import { decodedToken, verifyToken } from "../../utils/utils";
import {
  ContainerBackground,
  ContainerInformations,
  WrapperInformations
} from "./styled";

const EnumPriority = {
  1: "Alta",
  2: "Média",
  3: "Baixa"
};

const OportunityPainelDetails = ({ id, onClickBack }) => {
  const [item, setItem] = useState(null);

  const lojista = decodedToken(verifyToken()).EhLojista;

  useEffect(() => {
    if (id)
      getDetailsOportunity(id).then((response) => {
        const { detalhe } = response.data;

        setItem(detalhe);
      });
  }, [id]);

  const formatValues = (value) =>
    new Intl.NumberFormat("pt-br", {
      style: "currency",
      currency: "BRL"
    }).format(value);

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date);

      return new Intl.DateTimeFormat("pt-BR").format(newDate);
    }

    return date;
  };

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const regex = new RegExp(/(\d{2})(\d{5})(\d{4})/);

    const match = cleaned.match(regex);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return phoneNumberString;
  }

  return (
    <ContainerBackground>
      {item ? (
        <>
          <ContainerInformations>
            <img src={IconArrow} alt="ícone de voltar" onClick={onClickBack} />
            <h1>Mais detalhes</h1>
          </ContainerInformations>

          <ContainerInformations>
            <WrapperInformations>
              <b>Nome do cliente</b>
              <p style={{ textTransform: "capitalize" }}>
                {replaceValue(item.nomeCliente.toLowerCase())}
              </p>
            </WrapperInformations>
            <WrapperInformations>
              <b>CPF</b>
              <p>{item.cpfCliente}</p>
            </WrapperInformations>
            {lojista === "true" ? (
              <WrapperInformations>
                <b>Telefone do cliente</b>
                <p>
                  {item.telefoneCliente
                    ? formatPhoneNumber(item.telefoneCliente)
                    : "-"}
                  {item.telefoneCliente ? (
                    <a
                      href={`https://api.whatsapp.com/send/?phone=+55${item.telefoneCliente}`}
                      target="_blank"
                    >
                      <img src={IconWhatsapp} alt="Whatsapp" />
                    </a>
                  ) : null}
                </p>
              </WrapperInformations>
            ) : null}
          </ContainerInformations>
          <ContainerInformations>
            <WrapperInformations>
              <b>Cód. de aprovação da proposta</b>
              <p>{item.codProposta}</p>
            </WrapperInformations>
            <WrapperInformations>
              <b>Dt. de inclusão da proposta</b>
              <p>{formatDate(item.dataProposta)}</p>
            </WrapperInformations>
          </ContainerInformations>
          <ContainerInformations>
            <WrapperInformations>
              <b>Número do contrato</b>
              <p>{item.numeroContrato}</p>
            </WrapperInformations>
            <WrapperInformations>
              <b>Dt. de emissão do contrato</b>
              <p>{formatDate(item.dataContrato)}</p>
            </WrapperInformations>
            <WrapperInformations>
              <b>Valor do contrato</b>
              <p>{formatValues(item.valorContrato)}</p>
            </WrapperInformations>
          </ContainerInformations>
          <ContainerInformations>
            <WrapperInformations>
              <b>Quantidade de parcelas</b>
              <p>{item.qtdParcelas}</p>
            </WrapperInformations>
            <WrapperInformations>
              <b>Valor das parcelas</b>
              <p>{formatValues(item.valorParcelas)}</p>
            </WrapperInformations>
          </ContainerInformations>
          <ContainerInformations>
            <WrapperInformations>
              <b>Quantidade de dias em atraso</b>
              <p className={EnumPriority[item.idPrioridade]}>
                {item.qtdDiasAtraso}
              </p>
            </WrapperInformations>
            <WrapperInformations>
              <b>Valor total em atraso</b>
              <p>{formatValues(item.valorTotalAtraso)}</p>
            </WrapperInformations>
          </ContainerInformations>
          <ContainerInformations>
            <WrapperInformations>
              <b>Nome consultor</b>
              <p style={{ textTransform: "capitalize" }}>
                {item.nomeConsultor.toLowerCase()}
              </p>
            </WrapperInformations>
            <WrapperInformations>
              <b>E-mail consultor</b>
              <p>{item.emailConsultor.toLowerCase()}</p>
            </WrapperInformations>
          </ContainerInformations>
        </>
      ) : null}
    </ContainerBackground>
  );
};

export default OportunityPainelDetails;
