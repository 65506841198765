import axios from 'axios';
import { config } from '../config/config';
import { APP_RECOVERY_PASSWORD_HASH, APP_RECOVERY_PASSWORD_HASH_FAILURE } from '../constants/actionTypes';

export const fetchResetPasswordAction = (recoveryPassword) => {
    let recovery;
    if (!isNaN(recoveryPassword)) { recovery = { cnpj: recoveryPassword } }
    else { recovery = { email: recoveryPassword } }
    return (dispatch) => (
        axios.post(`${config.urlApi}/Autenticacao/recuperarsenha`, recovery)
            .then(res => (
                dispatch({
                    type: APP_RECOVERY_PASSWORD_HASH,
                    payload: res.data
                })
            ))
            .catch(err => (
                dispatch({
                    type: APP_RECOVERY_PASSWORD_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}