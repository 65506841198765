import axios from 'axios';
import { config } from '../config/config';
import { authHeader } from '../helpers/authHeader';
import { APP_NEWS_HASH, APP_NEWS_HASH_FAILURE } from '../constants/actionTypes';

export const fetchNewsUpdateReadAction = (idNovidade) => {
    return (dispatch) => (
        axios.put(`${config.urlApi}/novidades`, { idNovidade }, { headers: authHeader() })
            .then(res => (
                dispatch({
                    type: APP_NEWS_HASH,
                    payload: res.data
                })))
            .catch(err => (
                dispatch({
                    type: APP_NEWS_HASH_FAILURE,
                    payload: err
                })
            ))
    )
}