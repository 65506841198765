import React, { useState } from "react";
import {
  IconArrowDown,
  IconQuestion,
  LogoClubePlatinumWhite
} from "../../assets";
import Button from "../Button";
import Category from "../Category";
import {
  ContainerBar,
  Divider,
  Label,
  Wrapper,
  WrapperBar,
  WrapperInformations
} from "./styled";

import "../../img/pdf/Material_de_Vendas_Clube_Platinum_B&S_1911.pdf";

const ClubePlatinumBar = ({
  marginTop,
  marginBottom,
  points,
  link,
  recurrence
}) => {
  const [open, setOpen] = useState(true);

  return (
    <ContainerBar open={open} marginTop={marginTop} marginBottom={marginBottom}>
      <Wrapper justifyContent="space-between">
        <Wrapper>
          <img src={LogoClubePlatinumWhite} alt="Logo clube platinum" />
          <p
            dangerouslySetInnerHTML={{
              __html: recurrence && recurrence.indicador
            }}
          />
        </Wrapper>

        <button onClick={() => setOpen(!open)}>
          Saber mais{" "}
          <img src={IconArrowDown} alt="Flecha" className="image-know-more" />
        </button>
      </Wrapper>

      {open ? (
        <>
          <Wrapper
            padding="3.8rem 3.8rem"
            justifyContent="space-between"
            width="100%"
          >
            <WrapperInformations>
              <Wrapper margin="0 0 8px">
                <Category
                  before
                  category={recurrence && recurrence.categoria}
                />
                <Label fontSize="2.4rem" fontWeight="600" margin="0 0 0 14px">
                  {recurrence && recurrence.recorrenciaPlatinum
                    ? recurrence.recorrenciaPlatinum
                    : "-"}
                </Label>
              </Wrapper>
              <Label
                fontSize="1.2rem"
                dangerouslySetInnerHTML={{
                  __html: recurrence && recurrence.frase
                }}
              ></Label>
            </WrapperInformations>
            <Divider />

            <WrapperInformations>
              <Label>Você no Clube Platinum</Label>
              <Label fontSize="2.4rem" fontWeight="600" margin="8px 0">
                {points && "totalDiponivelResgate" in points
                  ? points.totalDiponivelResgate
                  : 0}
              </Label>
              <Label>
                Pontos disponíveis <br />
                <b>para resgatar no Clube Platinum</b>
              </Label>
            </WrapperInformations>
            <Divider />

            <WrapperInformations>
              <Label fontWeight="600" fontSize="2.4rem" margin="0 0 8px">
                {points && "totalResgatado" in points
                  ? points.totalResgatado
                  : 0}
              </Label>
              <Label>
                Pontos resgatados <b>no Clube Platinum</b>
              </Label>
            </WrapperInformations>
            <Divider />

            <WrapperInformations>
              <Label fontWeight="600" fontSize="2.4rem" margin="0 0 8px">
                {points && "totalAdquirido" in points
                  ? points.totalAdquirido
                  : 0}
              </Label>
              <Label>
                Pontos acumulados <b>no Clube Platinum</b>
              </Label>
            </WrapperInformations>
          </Wrapper>
          <Wrapper justifyContent="flex-end" width="100%">
            <Button theme="green">
              <a
                href="/Material_de_Vendas_Clube_Platinum_B&S_1911.pdf"
                target="_blank"
              >
                Conheça o Clube Platinum{" "}
                <img
                  style={{ marginLeft: "5px" }}
                  src={IconQuestion}
                  alt="Interrogação"
                />
              </a>
            </Button>
            {link && link.resgatarPontos && link.acessoIncentivale ? (
              <Button>
                <a href={link.linkRedirecionamento} target="_blank">
                  Resgatar Prêmios
                </a>
              </Button>
            ) : null}
          </Wrapper>
        </>
      ) : null}
    </ContainerBar>
  );
};

export default ClubePlatinumBar;
