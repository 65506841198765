import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import CategoryIcon from './CategoryIcon';
import ScoreBar from './ScoreBar';
import ScoreBarHistory from './ScoreBarHistory';
import Card from './Card';
import SVG from 'webmotors-svg';
import ProgressBar from '../components/ProgressBar'
import Tipfy from '../components/Tipfy'

import moment from 'moment';

const CategoryRescue = ({ rescueCategory, criteria, metaDesafio, ...props }) => {
  const [toggleCard, setToggleCard] = useState(false);

  const handleToggleCard = () => {
    if (toggleCard) {
      document.getElementsByClassName(`arrow`)[0].style.transform = 'rotate(90deg)';
    } else {
      document.getElementsByClassName(`arrow`)[0].style.transform = 'rotate(-90deg)';
    }
    setToggleCard(!toggleCard)
  }

  return (
    <div className="category-rescue">
      <Card>
        {rescueCategory.categoria ? (
          <>
            <div className="category-rescue__header">
              <div className="category-rescue__icon">
                {rescueCategory.categoria &&
                  <CategoryIcon category={rescueCategory.categoria} activePlatinum={true} text='Sua Última Classificação' lateral={true} size='big' active={true} />
                }
              </div>
              <div className="category-rescue__data">
                <p>Mês: <strong>{rescueCategory.mesRef}</strong></p>
                <p>Fechamento: {rescueCategory.dataReferencia ? moment(rescueCategory.dataReferencia).format('DD/MM/YYYY') : ''}</p>
              </div>
            </div>

            <div className="category-rescue__wrapper">
              <div className="category-rescue__current-point">
                <ScoreBar
                  currentPoints={props.platinum && props.platinum.status ? rescueCategory.pontuacaoMaxima : rescueCategory.pontuacao}
                  maxPoints={rescueCategory.pontuacaoMaxima}
                />
              </div>

              <button id="btn-ver-mais" className='category-rescue__wrapper-collapsible'
                onClick={() => handleToggleCard()}> Minha Pontuação
                      <SVG className={`arrow`} src={`../../../img/svg/str-icon-arrow.svg`} /></button>
            </div>
          </>
        ) : (
            <div className="category-rescue__empty">
              Não existe classificação.
            </div>
          )}

        <div className={`category-rescue__content ${toggleCard ? 'category-rescue__content--show' : 'category-rescue__content--hidden'}`}>
          {criteria && criteria.length > 0 ? (
            <>
              <div id="panel" className="category-rescue__content-info">Seus Pontos do Mês</div>
              <ScoreBarHistory points={criteria[0].criterios[0]} />
              {/* {criteria[0].criterios[0].map(item => {
                const percentual = item.PontuacaoAtual === 0 ? 2 : (parseInt(item.PontuacaoAtual) / parseInt(item.PontuacaoMaxima) * 100).toFixed(0);
                const showMetaDesafio = metaDesafio && item.Criterio && (item.Criterio.IdCriterio === 1);
                console.log({ item })

                return (
                  // <div className="criteria-item" key={item.Criterio && item.Criterio.IdCriterio}>
                  //   <div>
                  //     <span>
                  //       {item.Criterio && item.Criterio.Nome}
                  //       {item.Criterio.MetaDesafio && <strong>- {`R$ ${metaDesafio}`}</strong>}
                  //     </span>
                  //     {item.Criterio && item.Criterio.ToolTip && <Tipfy side="right" className="scorebar__tipfy" text={item.Criterio.ToolTip}><div className="tooltip"></div></Tipfy>}
                  //   </div>
                  //   <div><ProgressBar percente={percentual} /></div>
                  //   <div className="category-rescue__indicators">
                  //     <strong className="category-rescue__indicators--current">{item.PontuacaoAtual}</strong>
                  //     <span className="category-rescue__indicators--max">| {item.PontuacaoMaxima}</span>
                  //   </div>
                  // </div>
                  <ScoreBar points={item} />
                )
              })} */}
            </>
          ) : (
              <div className="category-rescue__empty">
                Não existe histórico de pontuação referente esse mês
              </div>
            )}
        </div>
      </Card>
    </div>
  )
}

const mapStateToProps = state => ({
  platinum: state.bePlatinum
});

CategoryRescue.propTypes = {
  rescueCategory: PropTypes.any,
  metaDesafio: PropTypes.any,
  criteria: PropTypes.any,
  dispatch: PropTypes.func,
  showLinks: PropTypes.bool,
};

export default hot(module)(connect(mapStateToProps)(CategoryRescue));
