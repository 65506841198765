import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'webmotors-svg';
import { clearDb, removeToken } from '../utils/utils';


export const FirstStep = (props) => {
    const { complement, onChange } = props;

    const logout = () => {
        removeToken();
        clearDb();
        return location.href = '/login';
    }



    const validEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    return (
        <div className='complement'>
            <div className='row' className='complement__logout'>
                <button onClick={() => logout()}>Sair</button>
            </div>
            <h5>Sobre Você</h5>
            {/* <form onSubmit={(e) => this.nextStep(e, this.child)}> */}
            <form id='first' onSubmit={(e) => props.onSubmit(e)}>
                <div className="row">
                    <div className="col">
                        <div className='complement'>
                            <label className='complement__label'>Nome</label>
                            <input key='2' className='complement__input' value={complement && complement.nome} id='nome' onChange={(e) => props.onChange(e, e.target.id)} />
                        </div>
                    </div>
                    <div className="col">
                        <div className='complement'>
                            <label className='complement__label'>Como quer ser chamado</label>
                            <input key='2' className='complement__input' value={complement && complement.comoQuerSerChamado} id='comoQuerSerChamado' onChange={(e) => props.onChange(e, e.target.id)} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className='complement'>
                            <label className='complement__label'>Seu Celular</label>
                            <input key='3' className={complement.celular.length === 15 ? 'complement__input complement__check' : 'complement__input'} maxLength={15} value={complement && complement.celular} id='celular' onChange={(e) => props.onChange(e, e.target.id)} />
                        </div>
                    </div>
                    <div className="col">
                        <div className='complement'>
                            <label className='complement__label'>Seu E-mail</label>
                            <div className={'complement__div__input'}>
                                <input key='3' type="email" value={complement && complement.email} id='email' onChange={(e) => props.onChange(e, e.target.id)} />
                                <div className={complement.email.length > 0 && validEmail(complement.email) ? 'div__check' : ''} />
                            </div>
                        </div>
                    </div>
                </div>
                <button type='submit' className='btn btn__red btn__rounded complement__btn'>Salvar e continuar <SVG className="svg" src={require('../img/svg/str-white-arrow-right.svg')} /></button>
            </form>
        </div>
    )
}

export const SecondStep = (props) => {
    const logout = () => {
        removeToken();
        clearDb();
        return location.href = '/login';
    }
    const { complement } = props;
    return (
        <div className='complement'>
            <div className='row' className='complement__logout'>
                <button onClick={() => logout()}>Sair</button>
            </div>
            <h5>Seu Endereço</h5>
            <form id='second' onSubmit={(e) => props.onSubmit(e)}>
                <div className="row">
                    <div className="col-2">
                        <div className='complement'>
                            <label className='complement__label'>CEP</label>
                            <input className={complement.cep.length === 9 ? 'complement__input complement__check' : 'complement__input'} maxLength={9} value={complement && complement.cep} id='cep' onChange={(e) => props.onChange(e, e.target.id)} />
                        </div>
                    </div>
                    <div className="col-left">
                        <label className='complement__label'>Bairro</label>
                        <input className='complement__input' value={complement && complement.bairro} id='bairro' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                    <div className="col-right">
                        <label className='complement__label'>Número</label>
                        <input className='complement__input' value={complement && complement.numero} id='numero' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2">
                        <label className='complement__label'>Logradouro</label>
                        <input className='complement__input' value={complement && complement.logradouro} id='logradouro' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                    <div className="col-left">
                        <label className='complement__label'>Cidade</label>
                        <input className='complement__input' value={complement && complement.cidade} id='cidade' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                    <div className="col-right">
                        <label className='complement__label'>Estado</label>
                        <select className='complement__input' value={complement && complement.uf} id='uf' onChange={(e) => props.onChange(e, e.target.id)}>
                            <option value="">UF</option>
                            <option value="AC">AC</option>
                            <option value="AL">AL</option>
                            <option value="AP">AP</option>
                            <option value="AM">AM</option>
                            <option value="BA">BA</option>
                            <option value="CE">CE</option>
                            <option value="DF">DF</option>
                            <option value="ES">ES</option>
                            <option value="GO">GO</option>
                            <option value="MA">MA</option>
                            <option value="MT">MT</option>
                            <option value="MS">MS</option>
                            <option value="MG">MG</option>
                            <option value="PA">PA</option>
                            <option value="PB">PB</option>
                            <option value="PR">PR</option>
                            <option value="PE">PE</option>
                            <option value="PI">PI</option>
                            <option value="RJ">RJ</option>
                            <option value="RN">RN</option>
                            <option value="RS">RS</option>
                            <option value="RO">RO</option>
                            <option value="RR">RR</option>
                            <option value="SC">SC</option>
                            <option value="SP">SP</option>
                            <option value="SE">SE</option>
                            <option value="TO">TO</option>
                        </select>
                    </div>
                </div>
                <button type='submit' className='btn btn__red btn__rounded complement__btn'>Salvar e continuar <SVG className="svg" src={require('../img/svg/str-white-arrow-right.svg')} /></button>
            </form>
        </div>
    )
}

export const ThirdStep = (props) => {
    const logout = () => {
        removeToken();
        clearDb();
        return location.href = '/login';
    }
    const { complement, onSubmit } = props;
    return (
        <div className='complement'>
            <div className='row' className='complement__logout'>
                <button onClick={() => logout()}>Sair</button>
            </div>
            <h5>Sua Empresa</h5>
            <form id='finalizar' onSubmit={onSubmit}>
                <div className="row">
                    <div className="col">
                        <label className='complement__label'>Qual seu faturamento mensal?</label>
                        <input type="text" className='complement__input' value={complement && (complement.faturamento.includes('N') ? 'R$ 0' : `R$ ${complement.faturamento}`)} id='faturamento' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <label className='complement__label'>Quantos vendedores sua loja possui?</label>
                        <input type="number" min="0" className='complement__input' value={complement && complement.quantidadeVendedores} id='quantidadeVendedores' onChange={(e) => props.onChange(e, e.target.id)} />
                    </div>
                </div>
                <button type='submit' className='btn btn__red btn__rounded complement__btn'>Finalizar <SVG className="svg" src={require('../img/svg/str-white-arrow-right.svg')} /></button>
            </form>
        </div>
    )
}
