import styled from "styled-components";

export const Label = styled.p`
  font-size: ${({ fontSize }) => fontSize || "1.4rem"};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: #4d4c59;

  margin: ${({ margin }) => margin};
  line-height: 20px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;
