import React, { useContext, useEffect, useState } from "react";

import {
  CurrentCategory,
  PointingCategory,
  LastRating,
  Campaign,
  Card,
  BePlatinum
} from "../../../components";
import Messages from "../Messages";
import { MainContext } from "../../../App/context";
import { decodedToken, verifyToken } from "../../../utils/utils";
import VoucherManager from "../../../components/BirthDay/VoucherManager";
import SVG from "webmotors-svg";
import moment from "moment";
import HandlePopups from "../../../components/HandlePopups";
import { getPopups } from "../../../requests/popups";

import ClubePlatinumBar from "../../../components/ClubePlatinumBar";
import { useSelector } from "react-redux";

moment.locale("pt-br");

const Home = () => {
  const context = useContext(MainContext);

  const {
    categoriaAtual,
    classificacoesAnteriores,
    categorias,
    width,
    periodoResgateAtivo,
    beneficiosResgate,
    campanhas
  } = context.state;

  const { clubePlatinumLinks, clubePlatinumPoints, clubePlatinumRecurrence } =
    useSelector((state) => state.clubePlatinumReducer);

  const [companyName, setCompanyName] = useState("");
  const [actionPlatinum, setActionPlatinum] = useState("");

  const [popupsList, setPopupsList] = useState([]);

  let currentCategory = false;

  function getCompanyName() {
    const result = decodedToken(verifyToken());

    setCompanyName(result.NomeEmpresa);
    setActionPlatinum(result.AcaoPlatinum);
  }

  useEffect(() => {
    getCompanyName();

    getPopups().then((response) => {
      setPopupsList(response.data);
    });
  }, []);

  return (
    <>
      <HandlePopups listPopups={popupsList} />
      {categoriaAtual && companyName ? (
        <>
          <h2 className="page__title__message">
            Olá <b> {companyName}</b>, seja bem-vindo!
          </h2>
          <div className="wrapper__container home__currencyCategory template-grid-2-1">
            {actionPlatinum === "true" && categoriaAtual.idCategoria !== 4 ? (
              <div className="home__currencyCategory--be-platinum">
                <BePlatinum />
                <CurrentCategory
                  currentCategory={categoriaAtual}
                  activePlatinum={true}
                  showLinks={true}
                />
              </div>
            ) : (
              <CurrentCategory
                currentCategory={categoriaAtual}
                showLinks={true}
              />
            )}
            <PointingCategory
              categories={categorias}
              activeCateg={currentCategory}
              showButton={true}
            />
          </div>
          {clubePlatinumPoints &&
          clubePlatinumLinks &&
          clubePlatinumRecurrence ? (
            <ClubePlatinumBar
              points={clubePlatinumPoints}
              link={clubePlatinumLinks}
              recurrence={clubePlatinumRecurrence}
            />
          ) : null}

          {classificacoesAnteriores && classificacoesAnteriores.length > 0 ? (
            <div className="wrapper__container">
              <LastRating
                atual={false}
                category={classificacoesAnteriores[0].categoria}
                position={classificacoesAnteriores[0].mesRef}
                activeRedemptionPeriod={periodoResgateAtivo}
                ransomBenefit={beneficiosResgate}
                campaign={campanhas}
              />
            </div>
          ) : (
            <div className="wrapper__container">
              <LastRating
                atual={true}
                category={categoriaAtual.categoria}
                position={moment()
                  .subtract(1, "months")
                  .format("MMMM")
                  .toUpperCase()}
                activeRedemptionPeriod={periodoResgateAtivo}
                ransomBenefit={[]}
                campaign={[]}
              />
            </div>
          )}

          <div className="wrapper__container">
            <Campaign />
          </div>

          {width > 994 && (
            <>
              <div className="wrapper__container">
                <div className="card__title">
                  <SVG
                    className="card__title__icon"
                    src="../../../img/svg/str-icon-message.svg"
                  />{" "}
                  MENSAGEM
                </div>
              </div>
              <div className="wrapper__container message">
                <Messages hideMessages={true} colorTitle="dark"></Messages>
              </div>
            </>
          )}

          {<VoucherManager />}
        </>
      ) : (
        <div className="loader__background v-align">
          <div className="loader" />
        </div>
      )}
    </>
  );
};

export default Home;
