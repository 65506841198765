import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>NotFound</div>
        )
    }
}

NotFound.propTypes = {
    children: PropTypes.element
};

export default hot(module)(NotFound);