import React from "react";
import { useState } from "react";
import { ButtonEyes, Fieldset, InputCustom, Legend } from "./style";
import EyeOpen from "../../../../img/svg/icon-eyes-open.svg";
import EyeClose from "../../../../img/svg/icon-eyes-close.svg";

// import { Container } from './styles';

function Input({ label, type, id, name, value, change, marginBottom, mask }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Fieldset>
      <Legend>{label}</Legend>
      <div style={{ position: "relative", marginBottom: marginBottom }}>
        <InputCustom
          type={type === "text" ? "text" : showPassword ? "text" : "password"}
          id={id}
          name={name}
          mask={mask}
          value={value}
          onChange={(e) => {
            change(e.target.value);
          }}
        />
        {type === "password" && (
          <ButtonEyes
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <img src={EyeOpen} /> : <img src={EyeClose} />}
          </ButtonEyes>
        )}
      </div>
    </Fieldset>
  );
}

export default Input;
