import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import configureStore, { history } from "../store/configureStore";
import { UserCookieConsent } from "./../components/UserConsent";
import Layout from "./layout";
import { hot } from "react-hot-loader";
import { Provider } from "react-redux";
const store = configureStore();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router history={history}>
          <Layout/>
        </Router>
        <UserCookieConsent/>
      </Provider>
    );
  }
}

export default hot(module)(App);
