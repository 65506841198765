import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import "./styles/styles.scss";
import App from "./App/App";

render(
  <AppContainer>
    <App />
  </AppContainer>,
  document.getElementById("app")
);
