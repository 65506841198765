import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { IconChevronLeft, IconChevronRight } from "../../assets";
moment.locale("pt-br");

const FilterMonth = ({
  valueSelected,
  listaCriterios,
  activeSelect,
  rating,
  months,
  ptMove,
  move
}) => {
  const month = listaCriterios.map((m, i) => {
    return {
      index: i,
      name: moment(m.dataReferencia).format("MMMM"),
      id: moment(m.dataReferencia).format("M"),
      first: moment(m.dataReferencia).format("YYYY-MM-01"),
      last: moment(m.dataReferencia).format("YYYY-MM-DD")
    };
  });

  const onStatusCheck = (
    categoria,
    pontuacao,
    pontuacaoMaxima,
    idCategoria
  ) => {
    switch (idCategoria) {
      case 1:
        if (pontuacao === pontuacaoMaxima || pontuacao > pontuacaoMaxima)
          return `Parabêns nesse mês você atingiu pontuação máxima de: <strong>${pontuacaoMaxima}</strong> na categoria: <strong>${categoria}</strong>`;
        else if (pontuacao < pontuacaoMaxima) {
          let subtract = pontuacaoMaxima - pontuacao + 1;
          return `Faltou <strong>${subtract}</strong> pontos para subir pra categoria <strong>SILVER</strong>`;
        }
        break;
      case 2:
        if (pontuacao === pontuacaoMaxima || pontuacao > pontuacaoMaxima)
          return `Parabêns nesse mês você atingiu pontuação máxima de: <strong>${pontuacaoMaxima}</strong> na categoria: <strong>${categoria}</strong>`;
        else if (pontuacao < pontuacaoMaxima) {
          let subtract = pontuacaoMaxima - pontuacao + 1;
          return `Faltou <strong>${subtract}</strong> pontos para subir pra categoria <strong>GOLD</strong>`;
        }
        break;
      case 3:
        if (pontuacao === pontuacaoMaxima || pontuacao > pontuacaoMaxima)
          return `Parabêns nesse mês você atingiu pontuação máxima de: <strong>${pontuacaoMaxima}</strong> na categoria: <strong>${categoria}</strong>`;
        else if (pontuacao < pontuacaoMaxima) {
          let subtract = pontuacaoMaxima - pontuacao + 1;
          return `Faltou <strong>${subtract}</strong> pontos para subir pra categoria <strong>PLATINUM</strong>`;
        }
        break;
      case 4:
        if (pontuacao === pontuacaoMaxima || pontuacao > pontuacaoMaxima)
          return `Parabêns nesse mês você atingiu pontuação máxima de: <strong>${pontuacaoMaxima}</strong> na categoria: <strong>${categoria}</strong>`;
        else if (pontuacao < pontuacaoMaxima) {
          let subtract = pontuacaoMaxima - pontuacao + 1;
          return `Faltou <strong>${subtract}</strong> pontos para subir pra categoria <strong>${categoria}</strong>`;
        }
        break;
      default:
        break;
    }
  };

  const getPosition = (index) => {
    if (index === activeSelect) {
      return 0;
    }

    if (index < activeSelect) {
      return `-${activeSelect * 10}`;
    }
  };

  const getWidth = () => {
    const element = document.getElementById("month");
    return element.offsetWidth / 3;
  };

  return (
    <div className="filter-month">
      <div className="filter-month__contains">
        <span
          style={{
            display: activeSelect === months.length - 1 ? "none" : "initial"
          }}
          onClick={() => move(activeSelect + 1)}
          className="right"
        >
          <img src={IconChevronRight} />
        </span>
        <span
          style={{ display: activeSelect === 0 ? "none" : "initial" }}
          onClick={() => move(activeSelect - 1)}
          className="left"
        >
          <img src={IconChevronLeft} />
        </span>
        <div className="list-month" id="month">
          {months &&
            months.map((item, i) => (
              <a
                href="javascript:void(0)"
                id={`m_${i}`}
                style={{ minWidth: `${getWidth()}px` }}
                className={
                  "item-month filter-month__list--item " +
                  (activeSelect === i ? "active-month" : "")
                }
                onClick={() => move(i)}
                key={i}
              >
                {item.dateDesc.toUpperCase()}
              </a>
            ))}
        </div>
        <div className="filter-rating">
          {months &&
            months.length &&
            rating &&
            rating
              .filter((x) => x.mesRef === months[activeSelect].mesRef)
              .map((item) => {
                return (
                  <div className="filter-rating__content" key={item.mesRef}>
                    <div className="triangle"></div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: onStatusCheck(
                          item.categoria,
                          item.pontuacao,
                          item.pontuacaoMaxima,
                          item.idCategoria
                        )
                      }}
                    ></span>
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

FilterMonth.propTypes = {
  valueSelected: PropTypes.func,
  listaCriterios: PropTypes.array,
  activeSelect: PropTypes.number,
  ptMove: PropTypes.number,
  rating: PropTypes.array,
  months: PropTypes.array,
  move: PropTypes.func
};

export default FilterMonth;
