import React from "react";
import { IconCrossCircle } from "../../assets";
import {
  ButtonClose,
  ModalBackgroundContainer,
  ModalContainer
} from "./styled";

const ModalBackground = ({
  open,
  children,
  onClickClose,
  padding,
  iconCloseUrl
}) => {
  return (
    <ModalBackgroundContainer open={open}>
      <ModalContainer padding={padding}>
        {onClickClose ? (
          <ButtonClose onClick={onClickClose}>
            <img
              src={iconCloseUrl ? iconCloseUrl : IconCrossCircle}
              alt="Icone de fechar"
            />
          </ButtonClose>
        ) : null}
        {children}
      </ModalContainer>
    </ModalBackgroundContainer>
  );
};

export default ModalBackground;
