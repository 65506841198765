import React from "react";
import { ButtonContainer, SpinnerContainer } from "./styled";

const Button = ({
  theme,
  margin,
  width,
  maxWidth,
  isLoading,
  disabled,
  onClick,
  children,
  className
}) => {
  return (
    <ButtonContainer
      theme={theme}
      onClick={onClick}
      margin={margin}
      disabled={disabled}
      maxWidth={maxWidth}
      width={width}
      className={className}
    >
      {isLoading ? <SpinnerContainer theme={theme} /> : children}
    </ButtonContainer>
  );
};

export default Button;
