import React from "react";
import { Container, Information } from "./styled";

const colors = {
  dark: {
    background: "#2E2D37",
    color: "#fff"
  }
};

const Tooltip = ({ color, data, size, ...props }) => (
  <Container
    backgroundColor={colors.dark.background}
    size={size || null}
    {...props}
  >
    <Information
      size={size || null}
      data-tooltip={data || "Sem descrição"}
      color={colors.dark.color}
    >
      {"i"}
    </Information>
  </Container>
);

export default Tooltip;
