import React, { Component } from "react";
import { hot } from "react-hot-loader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SVG from "webmotors-svg";

class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = { showAlert: false };
  }

  componentWillUnmount() {
    this.props = {};
  }

  handlerClose() {
    const { url } = this.props.sendMessageAlert.result;
    this.props.dispatch({
      type: "ALERT_HASH",
      payload: { showAlert: false }
    });
    if (url) {
      return (location.href = url);
    }
  }

  render() {
    const { showAlert, type, message, title, button } =
      this.props.sendMessageAlert.result;
    return (
      <div>
        {showAlert && (
          <div>
            <div className="alert">
              <div className="alert__container">
                <span>
                  <span className={`alert__status alert__status__background`} />
                  <span className={`alert__status alert__status__${type}`}>
                    <SVG
                      src={require(`../../src/img/svg/str-icon-alert-${type}.svg`)}
                    />
                  </span>
                </span>
                <h3 className={`alert__title__${type}`}>{title}</h3>
                <div
                  className="alert__body"
                  dangerouslySetInnerHTML={{ __html: message }}
                ></div>
                <div className="alert__body">{this.props.children}</div>
                {!this.props.children && (
                  <button
                    className="btn btn__rounded btn__red btn__center"
                    onClick={() => this.handlerClose()}
                  >
                    {button !== undefined ? button : "VOLTAR"}
                  </button>
                )}
              </div>
            </div>
            <div className="alert__overlay" />
          </div>
        )}
      </div>
    );
  }
}

Alert.propTypes = {
  children: PropTypes.element,
  sendMessageAlert: PropTypes.object,
  dispatch: PropTypes.func
};

function mapStateToProps(state) {
  return {
    sendMessageAlert: state.sendMessageAlert
  };
}

export default hot(module)(connect(mapStateToProps)(Alert));
