export function authHeaderPassword() {
    const token = (localStorage.getItem('UserTokenPassword'));
    if (token) {
        return {
            'Authorization': 'Bearer ' + token,
            'Pragma': 'no-cache'
        };
    }
    console.error('Access Token Inválido');
    return {};
}