import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MainContext } from "../../App/context";
import { decodedToken, verifyToken } from "../../utils/utils";
import { ContainerItemMenu, ContainerSidebar } from "./styled";

const SidebarMenu = ({ location }) => {
  let notRead = 0;
  const news = useSelector((state) => state.fetchNewsReducer);
  const constractsTotal = useSelector(
    (state) => state.oportunityPanel.totalContratosAtrasados
  );
  const [newsMenu, setNewsMenu] = useState([]);

  const context = useContext(MainContext);

  const lojista =
    decodedToken(verifyToken()) &&
    decodedToken(verifyToken()).EhLojista &&
    decodedToken(verifyToken()).EhLojista === "true";

  const { classificacoesAnteriores, campanhas, beneficiosResgate, novidades } =
    context.state;

  useEffect(() => {
    if (news && news.result && news.result.length !== 0) {
      setNewsMenu(news.result.novidades);
    } else {
      setNewsMenu(novidades);
    }
  }, [news]);

  useEffect(() => {
    if (newsMenu && newsMenu.length) {
      newsMenu.map((item) => {
        !item.lida && notRead++;
      });
    }
  }, [newsMenu]);

  const menus = [
    {
      id: 1,
      path: "/home",
      icon: "icon-home",
      name: "Home",
      pathname: "/home"
    },
    {
      id: 8,
      path: "/painel-oportunidades",
      icon: "icon-phone",
      name: "Painel de oportunidades",
      pathname: "/painel-oportunidades"
    },
    {
      id: 2,
      path: "/beneficios?filter=posicaoAtual",
      icon: "icon-santander",
      name: "Benefícios",
      pathname: "/beneficios"
    },
    {
      id: 3,
      path: "/pontos",
      icon: "icon-points",
      name: "Pontos",
      pathname: "/pontos"
    },
    /*{
      id: 7,
      path: "/simulador",
      icon: "icon-simulator",
      name: "Simulador",
      pathname: "/simulador"
    },*/
    {
      id: 4,
      path: "/mensagens",
      icon: "icon-message",
      name: "Mensagem",
      pathname: "/mensagens"
    },
    {
      id: 5,
      path: "/historico",
      icon: "icon-historic",
      name: "Histórico",
      pathname: "/historico"
    },
    {
      id: 6,
      path: "/ajuda",
      icon: "icon-help",
      name: "Ajuda",
      pathname: "/ajuda"
    }
  ];

  return (
    <ContainerSidebar>
      <div className="sidebar-container">
        {menus &&
          menus.map((item) => {
            if (
              (item.id === 5 &&
                classificacoesAnteriores &&
                classificacoesAnteriores.length === 0 &&
                campanhas &&
                campanhas.filter(
                  (c) => c.resgatado && c.dtReferencia !== "0001-01-01T00:00:00"
                ).length === 0 &&
                beneficiosResgate &&
                beneficiosResgate.filter((b) => b.resgatado).length === 0) ||
              (item.id === 4 && !lojista)
            ) {
              return (
                <ContainerItemMenu key={item.path}>
                  <Link
                    to={item.path}
                    className={
                      location.pathname === item.pathname ? "active-router" : ""
                    }
                  >
                    {location.pathname !== item.pathname ? (
                      <img
                        src={require(`../../../src/assets/icons/${item.icon}.svg`)}
                        alt="Icon menu"
                      />
                    ) : (
                      <img
                        src={require(`../../../src/assets/icons/${item.icon}-red.svg`)}
                        alt="Icon menu"
                      />
                    )}
                    <p>{item.name}</p>
                    {item.id === 4 && notRead > 0 && (
                      <span className="news-page__badge_lateral">
                        <span className="news-page__badge">{notRead}</span>
                      </span>
                    )}
                    {item.id === 8 && (
                      <span className="oportunity-news">
                        <span className="oportunity-news-badge">
                          {constractsTotal}
                        </span>
                      </span>
                    )}
                  </Link>
                </ContainerItemMenu>
              );
            }

            return (
              <ContainerItemMenu key={item.path}>
                <Link
                  to={item.path}
                  className={
                    location.pathname === item.pathname ? "active-router" : ""
                  }
                >
                  {location.pathname !== item.pathname ? (
                    <img
                      src={require(`../../../src/assets/icons/${item.icon}.svg`)}
                      alt="Icon menu"
                    />
                  ) : (
                    <img
                      src={require(`../../../src/assets/icons/${item.icon}-red.svg`)}
                      alt="Icon menu"
                    />
                  )}
                  <p>{item.name}</p>
                  {item.id === 4 && notRead > 0 && (
                    <span className="news-page__badge_lateral">
                      <span className="news-page__badge">{notRead}</span>
                    </span>
                  )}
                  {item.id === 8 && (
                    <span className="oportunity-news">
                      <span className="oportunity-news-badge">
                        {constractsTotal}
                      </span>
                    </span>
                  )}
                </Link>
              </ContainerItemMenu>
            );
          })}
      </div>
    </ContainerSidebar>
  );
};

export default SidebarMenu;
