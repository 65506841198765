import styled from "styled-components";
import InputMask from "react-input-mask";

export const Fieldset = styled.fieldset`
  border: none;
`;

export const Legend = styled.legend`
  font-size: 14px;
  color: #4d4c59;
`;

export const ButtonEyes = styled.button`
  background-color: transparent;
  position: absolute;
  right: 15px;
  bottom: 19px;
  display: flex;

  svg {
    width: 16px;
    height: 100%;
  }
`;

export const InputCustom = styled(InputMask)`
  background: #f7f5f5 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  width: 100%;
  height: 60px;
  opacity: 1;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
`;
