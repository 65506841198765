import CreateAccount from "../pages/publicPages/CreateAccount";
import RecoveryPassword from "../pages/privatePages/RecoveryPassword";
import UploadFiles from "../pages/privatePages/UploadFiles";
import Home from "../pages/privatePages/Home/index.js";
import Benefits from "../pages/privatePages/Benefits";
import Rescue from "../pages/privatePages/Rescue";
import Points from "../pages/privatePages/Points";
import Messages from "../pages/privatePages/Messages";
import Help from "../pages/privatePages/Help";
import Login from "../pages/publicPages/Login";
import ComplementInformation from "../pages/publicPages/ComplementInformation";
import History from "../pages/privatePages/History/index.js";
import OportunityPainel from "../pages/privatePages/OportunityPainel";
import CreateAccountComplete from "../pages/publicPages/CreateAccountComplete";

export const routes = [
  {
    path: "/upload",
    exact: true,
    component: UploadFiles
  },
  {
    path: "/",
    exact: true,
    component: Home
  },
  {
    path: "/home",
    exact: true,
    component: Home
  },
  {
    path: "/home",
    exact: true,
    component: Home
  },
  {
    path: "/beneficios",
    exact: true,
    component: Benefits
  },
  {
    path: "/resgate",
    exact: true,
    component: Rescue
  },
  {
    path: "/pontos",
    exact: true,
    component: Points
  },
  /*
  {
    path: "/simulador",
    exact: true,
    component: Simulator
  },*/

  {
    path: "/mensagens",
    exact: true,
    component: Messages
  },
  {
    path: "/historico",
    exact: true,
    component: History
  },
  {
    path: "/ajuda",
    exact: true,
    component: Help
  },
  {
    path: "/informacoes-complementares",
    exact: true,
    component: ComplementInformation
  },
  {
    path: "/painel-oportunidades",
    exact: true,
    component: OportunityPainel
  }
];

export const publicRoutes = [
  {
    path: "/criar-conta",
    exact: true,
    component: CreateAccount
  },
  {
    path: "/boas-vindas/:token",
    exact: true,
    component: CreateAccountComplete
  },
  {
    path: "/criar-conta/:criacao",
    exact: true,
    component: CreateAccount
  },
  {
    path: "/",
    exact: true,
    component: CreateAccount
  },
  {
    path: "/login",
    exact: true,
    component: Login
  },
  {
    path: "/recuperar-senha",
    exact: true,
    component: RecoveryPassword
  }
];
