const configs = () => {
  let _c = {};
  switch (process.env.NODE_ENV) {
    case "development": {
      _c.urlApi =
        "http://bensservicoswebapilb-988066703.us-east-1.elb.amazonaws.com/v1/api";
      _c.UploadFile =
        "http://bensservicoswebapilb-988066703.us-east-1.elb.amazonaws.com/v1/api/Importacao";
      break;
    }
    case "homologation": {
      _c.urlApi =
        "https://hapi-maisfidelidade-servicos.webmotors.com.br/v1/api";
      _c.UploadFile =
        "https://himportacao-maisfidelidade-servicos.webmotors.com.br/v1/api/Importacao";
      break;
    }
    case "production": {
      _c.urlApi = "https://api.santanderfinanc.com.br/v1/api";
      _c.UploadFile =
        "https://api-importacao.santanderfinanc.com.br/v1/api/Importacao";
      break;
    }
  }
  return _c;
};

const config = configs();
export { config };
export default config;
