import Dexie from 'dexie';

const db = new Dexie('bensServicos');
db.version(1).stores({ categoriaAtual: '++id' });
db.version(1).stores({ classificacoesAnteriores: '++id' });
db.version(1).stores({ categorias: '++id' });
db.version(1).stores({ criterios: '++id' });
db.version(1).stores({ campanhas: '++id' });
db.version(1).stores({ gerenteRelacionamento: '++id' });
db.version(1).stores({ beneficios: '++id' });
db.version(1).stores({ beneficiosResgataveis: '++id' });
db.version(1).stores({ beneficiosResgate: '++id' });
db.version(1).stores({ novidades: '++id' });
export default db;
