import Immutable from 'immutee';
import { BE_PLATINUM } from '../constants/actionTypes';
import { INITIAL_STATE } from '../utils/utils';

export default function bePlatinum(state = INITIAL_STATE, action ) {
  const immutable = Immutable(state);

  switch (action.type) {
    case BE_PLATINUM: {
      return immutable
        .set('status', action.payload)
        .set('result', [])
        .set('fetched', true)
        .set('error', null)
        .done();
    }
    default:
      return state
  }
}
