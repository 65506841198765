import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchCreateUserValidationAction,
  fetchRegisterUser
} from "../../../actions/fetchCreateUserValidationToken";
import {
  Container,
  Card,
  WrapperCard,
  Title,
  Description,
  Logo,
  Form,
  Columns,
  WrapItens,
  List,
  ButtonText,
  Button
} from "./style";
import Input from "./Input";

function CreateAccountComplete(props) {
  const dispatch = useDispatch();
  const { token } = props.match.params;
  const [isValidCNPJ, setIsValidCNPJ] = useState(false);
  const [form, setForm] = useState({
    cnpj: "",
    pass: "",
    passConfirm: ""
  });

  useEffect(() => {
    validateToken();
  }, []);

  function showAlert(
    message,
    type = "error",
    title = "DESCULPE",
    url = "",
    button = undefined
  ) {
    dispatch({
      type: "ALERT_HASH",
      payload: {
        message: message,
        showAlert: true,
        type: type,
        title,
        url,
        button
      }
    });
  }

  function validateToken() {
    dispatch(fetchCreateUserValidationAction(token))
      .then((resp) => {
        if (!resp.type.includes("FAILURE") && resp.payload.sucesso) {
          return;
        } else {
          showAlert(
            "",
            "warning",
            "Este link não pode mais ser usado. Você deve solicitar um novo Cadastro de usuário.",
            "/criar-conta",
            "Ok"
          );
        }
      })
      .catch(() => {});
  }

  function popupWarning() {
    showAlert(
      !validatePassword() || form.pass !== form.passConfirm
        ? "Senha no formato inválido."
        : "Verifique seus dados e tente novamente.",
      "warning",
      "Não foi possível finalizar seu cadastro.",
      "",
      "VERIFICAR DADOS"
    );
  }

  function registerUser() {
    if (!isValidCNPJ || !validatePassword() || form.pass !== form.passConfirm) {
      popupWarning();
      return;
    }

    fetchRegisterUser(token, form.cnpj, form.pass)
      .then((resp) => {
        if (resp.data.sucesso) {
          showAlert(
            "Seu cadastro foi concluído com sucesso!",
            "success",
            "Tudo certo!",
            "/login",
            "FAZER LOGIN"
          );
        } else {
          popupWarning();
        }
      })
      .catch(() => {
        popupWarning();
      });
  }

  function validateCnpj(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, "");
    if (cnpj == "") return false;
    if (cnpj.length != 14) return false;
    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj == "00000000000000" ||
      cnpj == "11111111111111" ||
      cnpj == "22222222222222" ||
      cnpj == "33333333333333" ||
      cnpj == "44444444444444" ||
      cnpj == "55555555555555" ||
      cnpj == "66666666666666" ||
      cnpj == "77777777777777" ||
      cnpj == "88888888888888" ||
      cnpj == "99999999999999"
    )
      return false;
    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(0)) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != digitos.charAt(1)) return false;
    return true;
  }

  function validatePassword() {
    //  min 6 characters and max 12, 1 uppercase, 1 lowercase, 1 number, 1 special character
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,12}$/;
    return regex.test(form.pass);
  }

  return (
    <div>
      <Container>
        <WrapperCard>
          <Card>
            <Logo
              src={require("../../../../src/img/svg/str-colored-fidelidade-white.svg")}
            />

            <Title>Boas vindas!</Title>
            <Description>
              Confirme seus dados abaixo para acessar o +Fidelidade.
            </Description>

            <Form>
              <Input
                label="CNPJ da loja"
                type="text"
                id="cnpj"
                mask="99.999.999/9999-99"
                value={form.cnpj}
                change={(e) => {
                  setForm({ ...form, cnpj: e });
                  setIsValidCNPJ(validateCnpj(e));
                }}
              />

              <Columns>
                <div>
                  <Input
                    label="Senha"
                    type="password"
                    id="pass"
                    value={form.pass}
                    change={(e) => {
                      setForm({ ...form, pass: e });
                    }}
                    marginBottom="17px"
                  />

                  <Input
                    label="Confirme sua senha"
                    type="password"
                    id="confirm-pass"
                    value={form.passConfirm}
                    change={(e) => {
                      setForm({ ...form, passConfirm: e });
                    }}
                  />
                </div>

                <WrapItens>
                  <List>
                    <li>Pelo menos uma letra minúscula</li>
                    <li>Pelo menos uma letra maiúscula</li>
                    <li>Pelo menos um caractere especial</li>
                    <li>Pelo menos um número </li>
                    <li>Possuir entre 6 e 12 caracteres</li>
                  </List>
                </WrapItens>
              </Columns>

              <Button
                type="button"
                marginTop="34px"
                onClick={() => {
                  registerUser();
                }}
              >
                <ButtonText>Salvar e continuar</ButtonText>
              </Button>
            </Form>
          </Card>
        </WrapperCard>
      </Container>
    </div>
  );
}

CreateAccountComplete.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    })
  })
};

export default CreateAccountComplete;
