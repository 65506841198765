import React, { Component } from "react";
import { Routes, PublicRoutes } from "./routers";
import Main from "../pages/privatePages/Main";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import {
  verifyToken,
  clearDb,
  verifyDataBase,
  removeToken,
  setToken
} from "../utils/utils";
import { fetchTokenAction } from "../actions/fetchTokenAction";
import { fetchPopupAction } from "../actions/fetchPopupAction";

import queryString from "query-string";
import { addDBList } from "../actions/setDbAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MainContext } from "./context";
import Alert from "../components/Alert";
import Loader from "../components/Loader";
import SplashScreen from "../components/SplashScreen";
import moment from "moment";
import crypto from "crypto";
import { getContractsOportunity } from "../requests/oportunityPanel";
import {
  getClubePlatinumLink,
  getClubePlatinumPoints,
  getClubePlatinumRecurrence,
  getClubePlatinumRecurrenceHistory
} from "../requests/clubePlatinum";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tokenIsValid: false,
      showSplash: true,
      id_empresa: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    const { id_empresa } = this.props || 0;
    this.setState({ id_empresa: id_empresa });

    const script = document.createElement("script");
    switch (process.env.NODE_ENV) {
      case "development": {
        script.src =
          "//assets.adobedtm.com/launch-ENf42570f346ff490ea6a93e5f024fb337-development.min.js";
        break;
      }
      case "homologation": {
        script.src =
          "//assets.adobedtm.com/launch-EN4678d70c153a4bcc926f4611b0beeb0f-staging.min.js";
        break;
      }
      case "production": {
        script.src =
          "//assets.adobedtm.com/launch-EN9804d8acbf5a4327b30e139016cb79bf.min.js";
        break;
      }
      default:
        script.src =
          "//assets.adobedtm.com/launch-EN9804d8acbf5a4327b30e139016cb79bf.min.js";
        break;
    }
    script.async = true;
    document.body.appendChild(script);
  }

  setTokenToLocalStorage() {
    const token = verifyToken();
    if (token) {
      this.setState({ tokenIsValid: true });
    }
  }

  returnLogin() {
    removeToken();
    return (location.href = "/login?error=true");
  }

  getAllData() {
    let databaseExist;
    verifyDataBase("bensServicos", (exists) => {
      databaseExist = exists;
    });
    if (verifyToken()) {
      this.props
        .dispatch(fetchTokenAction())
        .then((e) => {
          if (
            e.type === "APP_USER_HASH_FAILURE" ||
            e.payload.categoriaAtual === undefined
          ) {
            this.returnLogin();
          }
          const objectEntries = Object.entries(e.payload);
          this.setState({ periodoResgateAtivo: e.payload.periodoResgateAtivo });
          objectEntries.map((obj) => {
            if (obj[0] !== "periodoResgateAtivo") {
              this.setState({ [`${obj[0]}`]: obj[1] });
            }
          });
        })
        .catch(() => {
          this.returnLogin();
        });

      getContractsOportunity().then((response) => {
        this.props.dispatch({
          type: "GET_EXPIRED_CONTRACTS",
          payload: response.data.totalContratosAtrasados
        });
      });

      getClubePlatinumLink().then((response) => {
        this.props.dispatch({
          type: "GET_CLUBE_PLATINUM_LINKS",
          payload: response.data
        });
      });
      getClubePlatinumPoints().then((response) => {
        this.props.dispatch({
          type: "GET_CLUBE_PLATINUM_POINTS",
          payload: response.data
        });
      });

      getClubePlatinumRecurrenceHistory().then((response) => {
        this.props.dispatch({
          type: "GET_CLUBE_PLATINUM_HISTORY",
          payload: response.data
        });
      });

      getClubePlatinumRecurrence().then((response) => {
        this.props.dispatch({
          type: "GET_CLUBE_PLATINUM",
          payload: response.data
        });
      });
    } else {
      removeToken();
      clearDb();
    }
  }

  componentWillMount() {
    const { token } = queryString.parse(window.location.search) || 0;
    if (token) {
      const alg = "aes-256-ctr";
      const pwd = moment()
        .set({ second: 0, millisecond: 0 })
        .add("day", 1)
        .valueOf()
        .toString();
      const decipher = crypto.createDecipher(alg, pwd);
      const decrypted = decipher.update(atob(token), "hex", "utf8");
      const regex = new RegExp(
        /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
      );
      if (regex.test(decrypted)) {
        setToken(decrypted);
      }
      location.href = "/home";
    }
    this.setTokenToLocalStorage();
  }

  componentDidMount() {
    this.getAllData();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    setTimeout(() => this.setState({ showSplash: false }), 500);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    return (
      <MainContext.Provider
        value={{ width: this.state.width, state: this.state }}
      >
        <Alert />
        <Loader />
        {this.state.showSplash && <SplashScreen />}
        {this.state.tokenIsValid ? (
          <Main>
            <Routes />
          </Main>
        ) : (
          <PublicRoutes />
        )}
      </MainContext.Provider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
  dispatch: PropTypes.func
};

export default hot(module)(connect(bindActionCreators)(App));
