import React, { Component } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader";
import { MainContext } from "../App/context";
import Tipfy from "../components/Tipfy";
import Tooltip from "./Tooltip";
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={this.props.className && this.props.className}>
        {this.props.title && this.context.state.width < 994 && (
          <h3 className="card__title">{this.props.title}</h3>
        )}
        <div
          className={`card bg__${
            this.props.color ? this.props.color : "clean"
          }`}
        >
          {this.props.title && this.context.state.width > 994 && (
            <h3 className="card__title">
              {this.props.title}
              <Tooltip side="right" data={"Tooltip do critério de resgate"} />
            </h3>
          )}
          {this.props.children}
        </div>
      </div>
    );
  }
}
Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  color: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  customClass: PropTypes.string
};

Card.contextType = MainContext;
export default hot(module)(Card);
