import axios from "axios";
import { config } from "../config/config";
import { APP_USER_HASH, APP_USER_HASH_FAILURE } from "../constants/actionTypes";

export const fetchCreateAccountAction = (userInformation) => {
  return (dispatch) =>
    axios
      .post(`${config.urlApi}/Autenticacao/registrar`, {
        Email: userInformation.email
      })
      .then((res) =>
        dispatch({
          type: APP_USER_HASH,
          payload: res.data
        })
      )
      .catch((err) =>
        dispatch({
          type: APP_USER_HASH_FAILURE,
          payload: err.response.data || err
        })
      );
};
