import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { MainContext } from "../../App/context";
import SVG from "webmotors-svg";
import { verifyNextCategory } from "../../utils/helper";
import { decodedToken, verifyToken } from "../../utils/utils";
import { BePlatinum, ScoreBar, Card, CategoryIcon } from "../../components";
import { IconSantanderFlame } from "../../assets";

import PageTitle from "../../components/PageTitle";

class Points extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { categoriaAtual, listaCriterios, width } = this.context.state;
    const criterios =
      listaCriterios && listaCriterios.length > 0
        ? listaCriterios[0].criterios
        : [];

    return (
      <>
        <PageTitle>Pontos</PageTitle>
        <div className="points__container">
          {categoriaAtual ? (
            <div className="container__first-child">
              <div>
                {width < 994 ? (
                  <Card className="mt-6">
                    <div className="points">
                      <div className="points__category">
                        <div className="points__category--icon">
                          <div
                            className={`category__icon category__icon--${categoriaAtual.categoria.toLowerCase()}`}
                          >
                            <SVG
                              className="category__img"
                              src={IconSantanderFlame}
                            />
                          </div>
                        </div>
                        <div className="points__informations">
                          <span>Categoria Atual</span>
                          <span className="points__category--points">
                            {categoriaAtual.categoria}
                          </span>
                        </div>
                      </div>
                      <div>
                        <ScoreBar
                          currentPoints={categoriaAtual.pontuacao}
                          maxPoints={categoriaAtual.pontuacaoMaxima}
                        />
                        {categoriaAtual.categoria.toLowerCase() !==
                        "platinum" ? (
                          <div className="current-category__message">
                            Faltam
                            <span>
                              {categoriaAtual.pontuacaoMaxima -
                                categoriaAtual.pontuacao +
                                1}
                            </span>
                            pontos para subir pra categoria
                            <span>
                              {verifyNextCategory(
                                categoriaAtual.pontuacao
                              ).toUpperCase()}
                            </span>
                          </div>
                        ) : (
                          <div className="current-category__message">
                            <span>
                              Parabéns! Você atingiu a categoria máxima.
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Card>
                ) : null}
                {decodedToken(verifyToken()).AcaoPlatinum === "true" &&
                categoriaAtual.idCategoria !== 4 ? (
                  <div className="points__be-platinum">
                    <BePlatinum />
                    <Card className="mt-4">
                      {criterios && criterios.length > 0 ? (
                        <>
                          {criterios &&
                            criterios.map((point, index) => (
                              <ScoreBar points={point} key={index} />
                            ))}
                        </>
                      ) : (
                        <div>Até este momento, não há pontuações apuradas.</div>
                      )}
                    </Card>
                  </div>
                ) : (
                  <Card>
                    {criterios && criterios.length > 0 ? (
                      <>
                        {criterios &&
                          criterios.map((point, index) => (
                            <ScoreBar points={point} key={index} />
                          ))}
                      </>
                    ) : (
                      <div>Até este momento, não há pontuações apuradas.</div>
                    )}
                  </Card>
                )}
              </div>
            </div>
          ) : (
            <div className="loader__background v-align">
              <div className="loader" />
            </div>
          )}
          <div className="container__second-child">
            <div
              className={`${
                this.props.platinum.status === true
                  ? "rescue__current-category rescue__current-category--platinum"
                  : "rescue__current-category"
              }`}
            >
              {categoriaAtual && (
                <Card>
                  <CategoryIcon
                    category={categoriaAtual.categoria}
                    text="CATEGORIA ATUAL"
                    lateral={true}
                    size="big"
                    active={true}
                  />
                  <ScoreBar
                    currentPoints={categoriaAtual.pontuacao}
                    maxPoints={categoriaAtual.pontuacaoMaxima}
                  />
                  {categoriaAtual.categoria !== "Platinum" ? (
                    <div className="current-category__message">
                      Faltam{" "}
                      <span>
                        {categoriaAtual.pontuacaoMaxima -
                          categoriaAtual.pontuacao +
                          1}{" "}
                      </span>
                      pontos para subir pra categoria{" "}
                      <span>
                        {verifyNextCategory(categoriaAtual.pontuacao)}
                      </span>
                    </div>
                  ) : (
                    <div className="current-category__message">
                      <span>Parabéns! Você atingiu a categoria máxima.</span>
                    </div>
                  )}
                </Card>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Points.contextType = MainContext;
Points.propTypes = {
  children: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    platinum: state.bePlatinum
  };
};

export default hot(module)(connect(mapStateToProps)(Points));
